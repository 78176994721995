import { DateTime } from 'luxon';
import {
  BillDate,
  InstallmentContract,
  Payment,
  PaymentContract,
  TablePaymentContracts,
} from '../models/PaymentContract';
import toPrettyDate from './toPrettyDate';
import toFloat from './toFloat';
import toPrettyUSD from './toPrettyUSD';

function determineDueDate(installmentContracts: InstallmentContract[]): BillDate {
  if (installmentContracts.length > 1) {
    const today = DateTime.now();
    const firstInstallmentDate = DateTime.fromISO(
      new Date(
        `${installmentContracts[0].dueDate.month}/${installmentContracts[0].dueDate.day}/${installmentContracts[0].dueDate.year}`
      ).toISOString()
    );
    if (today > firstInstallmentDate) {
      return installmentContracts[1].dueDate;
    }
  }

  return installmentContracts[0].dueDate;
}

export function mapPaymentContracts(
  paymentContracts: PaymentContract[],
  selectedInstallment?: number
): TablePaymentContracts[] {
  return paymentContracts.map((paymentContract: PaymentContract) => {
    const selectedInstallmentContract = selectedInstallment
      ? paymentContract?.installmentContracts?.find(
          (x: any) => x.installmentNumber === selectedInstallment
        )
      : ({} as InstallmentContract);
    const data: TablePaymentContracts = {
      ...paymentContract,
      amount: paymentContract.amount / 100,
      amountPaid: paymentContract.amountPaid / 100,
      dueDate: toPrettyDate(
        determineDueDate(paymentContract.installmentContracts),
        'default',
        true
      ),
      createdAt: paymentContract.createdAt,
      isEstimated: !!paymentContract.originalVoucherId || 'false',
      amountFirstInstallment: toFloat(paymentContract.installmentContracts[0]?.amount || 0),
      amountSecondInstallment: toFloat(paymentContract.installmentContracts[1]?.amount || 0),
      amountPaidFirstInstallment: toFloat(
        paymentContract.installmentContracts.find((pc) => pc.installmentNumber == 1)?.amountPaid ||
          0
      ),
      amountPaidSecondInstallment: toFloat(
        paymentContract.installmentContracts.find((pc) => pc.installmentNumber == 2)?.amountPaid ||
          0
      ),
      paymentType: paymentContract.transactionRatePct === 0 ? 'ACH' : 'CreditCard',
      installmentNumbers: paymentContract.installments
        .map((i) => `${i.installmentNumber}`)
        .join(' & '),

      taxesCollected: paymentContract.schedule.reduce(
        (a: number, s: Payment) => a + (s.status === 'SETTLED' ? s.subTotal : 0),
        0
      ),
      transactionFeeCollected: paymentContract.schedule.reduce(
        (a: number, s: Payment) => a + (s.status === 'SETTLED' ? s.transactionFee : 0),
        0
      ),
      disbursmentAmount: (paymentContract.disbursmentAmount || 0) / 100,

      selectedInstallmentContract,
      selectedInstallmentContractAmount: toPrettyUSD(selectedInstallmentContract?.amount || 0),
      selectedInstallmentContractAmountPaid: toPrettyUSD(
        selectedInstallmentContract?.amountPaid || 0
      ),
      selectedInstallmentContractIsPaid: !!selectedInstallmentContract?.isPaid,
      selectedInstallmentContractBatchPaymentTrackingId:
        selectedInstallmentContract?.batchPaymentTrackingId || '-',
      selectedInstallmentContractStatus: selectedInstallmentContract?.status || '-',
      selectedInstallmentContractDueDate: selectedInstallmentContract?.dueDate
        ? `${selectedInstallmentContract?.dueDate.month}/${selectedInstallmentContract?.dueDate.day}/${selectedInstallmentContract?.dueDate.year}`
        : '',
    };
    return data;
  });
}
