import { forwardRef, ForwardedRef } from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { PaymentContractField } from 'src/pages/paymentContracts/PaymentContractDetails/components/ContractDetails/ContractDetails';
import { ContractDetailField } from 'src/pages/customers/customer/components/models/ContractDetailProps';
import { DateTime } from 'luxon';
import { OrderDetailsProps } from '../models/OrderDetailsProps';

const styles = {
  root: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    maxHeight: '95vh',
    width: '408px',
    borderRadius: '4px 4px 0 0',
    backgroundColor: '#FFFFFF',
    boxShadow:
      '0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    padding: '16px 0 75px 0',
    overflowX: 'hidden' as 'hidden',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: '24px',
  },
  headerRow: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0 24px 0 16px',
    margin: '0 0 6px 0',
  },
  orderDetailText: {
    minHeight: '24px',
    minWidth: '139px',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    margin: 0,
  },
  chip: {
    height: '32px',
    width: '81px',
  },
  receiptIdDate: {
    width: '175px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    margin: 0,
    paddingLeft: '16px',
  },
  receiptDetails: {
    height: '60px',
    width: '360px',
    borderRadius: '4px 0 0 4px',
    backgroundColor: 'rgba(0,0,0,0.03)',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '16px',
    marginBottom: '24px',
  },
  receiptSection: {
    marginRight: '32px',
  },
  receiptSectionHeader: {
    height: '16px',
    width: '61px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: 500,
    letterSpacing: '1.5px',
    lineHeight: '16px',
    margin: 0,
  },
  receiptSectionContent: {
    height: '24px',
    width: '72px',
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '24px',
    margin: 0,
  },
  contractData: {
    paddingLeft: '48px',
  },
  buttonRow: {
    display: 'flex',
    marginTop: '16px',
    paddingLeft: '48px',
  },
  button: {
    border: '1px solid #E0E0E0',
    padding: '10px 14px',
    borderRadius: '4px',
    height: '36px',
    minWidth: '135px',
    color: '#6200EE',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
};
const OrderDetails = forwardRef<HTMLDivElement, OrderDetailsProps>(
  (
    { order, setSelectedOrder, onViewProfile, onViewOrders }: OrderDetailsProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    function formatPrice(amountInCents: number): string {
      const toFloat = amountInCents / 100;

      return toFloat.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    return (
      <div ref={ref}>
        <Paper elevation={4}>
          <div style={styles.root}>
            <div style={styles.paper}>
              <div style={styles.headerWrapper}>
                <ReceiptOutlinedIcon sx={{ color: '#000000' }} />
                <div>
                  <div style={styles.headerRow}>
                    <p style={styles.orderDetailText}>Order Details</p>
                    <Chip
                      sx={styles.chip}
                      label="Close"
                      variant="outlined"
                      size="small"
                      onClick={() => setSelectedOrder(null)}
                      onDelete={() => setSelectedOrder(null)}
                    />
                  </div>
                  <div>
                    <p style={styles.receiptIdDate}>{`Receipt ID: ${order?.receiptId}`}</p>
                    <p style={{ ...styles.receiptIdDate, marginBottom: '21px' }}>
                      {DateTime.fromISO(order?.purchaseDate || '').toLocaleString({
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </p>
                    <div style={styles.receiptDetails}>
                      <div style={styles.receiptSection}>
                        <p style={styles.receiptSectionHeader}>Subtotal</p>
                        <p style={styles.receiptSectionContent}>
                          {formatPrice(order?.subTotal || 0)}
                        </p>
                      </div>
                      <div style={styles.receiptSection}>
                        <p style={styles.receiptSectionHeader}>Fees</p>
                        <p style={styles.receiptSectionContent}>{formatPrice(order?.fees || 0)}</p>
                      </div>
                      <div style={styles.receiptSection}>
                        <p style={styles.receiptSectionHeader}>Total</p>
                        <p style={styles.receiptSectionContent}>{formatPrice(order?.total || 0)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider />
              <div style={styles.contractData}>
                {order?.contractData.map((contractField: ContractDetailField, index: number) => {
                  return (
                    <PaymentContractField
                      key={contractField.type}
                      index={index}
                      header={contractField.type}
                      secondaryHeader={
                        contractField.street || contractField.name || contractField.apn || ''
                      }
                      apn={contractField.apn}
                      localeEmailOrInstallment={
                        contractField.locale ||
                        contractField.email ||
                        contractField.installment ||
                        ''
                      }
                      countyOrPhoneNumber={contractField.county || contractField.phoneNumber || ''}
                      taxYear={contractField.taxYear}
                      billType={contractField.billType}
                      isOrderDetail
                      showDividerBeforeIndex={3}
                    />
                  );
                })}
              </div>
              <div style={styles.buttonRow}>
                <Button sx={{ ...styles.button, marginRight: '8px' }} onClick={onViewProfile}>
                  View profile
                </Button>
                <Button sx={styles.button} onClick={onViewOrders}>
                  View all orders
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
);

export default OrderDetails;
