import { ROUTES } from '../constants/routes';
import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';
import { NotificationType } from '../models/NotificationType';

const BASE_SERVICE_PATH = ROUTES.NOTIFICATION_TYPES;

export function getNotificationType(id: string): Promise<NotificationType | AxiosError> {
  return getAxios()
    .get<NotificationType | AxiosError>(`${BASE_SERVICE_PATH}/${id}`)
    .then((resp: AxiosResponse<NotificationType | AxiosError>) => resp.data);
}
