import React, { ChangeEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface AvailableExportColumn {
  field: string;
  headerName: string;
  exportable: boolean;
}

export interface ColumnChooserProps {
  isOpen: boolean;
  availableColumns: AvailableExportColumn[];
  onClose: () => void;
  onExport: (columns: AvailableExportColumn[]) => void;
  onColumnsChange: (columns: AvailableExportColumn[]) => void;
  exporting: boolean;
}

const ExportColumnChooser: FunctionComponent<ColumnChooserProps> = (props: ColumnChooserProps) => {
  const { isOpen, availableColumns, onClose, onExport, onColumnsChange, exporting } = props;
  const [cols, setCols] = useState<AvailableExportColumn[]>(availableColumns);

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleColumnsChange = () => {
    onColumnsChange(cols.filter((c) => c.exportable));
  };

  const handleClickExport = () => {
    onExport(cols.filter((c) => c.exportable));
  };

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>, col: AvailableExportColumn) => {
    setCols(
      cols.map((a) => {
        if (a.field === col.field) {
          a.exportable = event.target.checked;
        }
        return a;
      })
    );
    handleColumnsChange();
  };

  const handleSwitchAllColumnsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCols(
      cols.map((a) => {
        a.exportable = event.target.checked;
        return a;
      })
    );
    handleColumnsChange();
  };

  useEffect(() => {
    setCols(availableColumns);
  }, [availableColumns]);

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: '30%', maxHeight: 435 } }} open={isOpen}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        Select columns to export
        <div>
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => handleSwitchAllColumnsChange(e)}
                checked={cols.every((c) => c.exportable)}
              />
            }
            label={'All columns'}
          />
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          {cols.map((column) => (
            <FormControlLabel
              key={column.field}
              control={
                <Switch
                  name={column.field}
                  onChange={(e) => handleSwitchChange(e, column)}
                  checked={column.exportable}
                />
              }
              label={column.headerName}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {exporting ? (
          <CircularProgress size={'2.1rem'} />
        ) : (
          <Button onClick={handleClickExport}>Export</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExportColumnChooser;
