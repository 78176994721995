import { TablePaymentContracts } from '../models/PaymentContract';
import { Query } from '../models/Query';
import { orderBy } from 'lodash';

function sortContracts(
  paymentContracts: TablePaymentContracts[],
  query: Query
): TablePaymentContracts[] {
  if (!paymentContracts || paymentContracts.length === 0) {
    return [];
  }

  const column = query.sortColumn as string;
  const direction = query.sortDirection || 'ASC';
  const order = direction === 'ASC' ? 'asc' : 'desc';

  return orderBy(paymentContracts, [column], [order]);
}

export function trimContracts(
  contracts: TablePaymentContracts[],
  query: Query
): TablePaymentContracts[] {
  const start = query.page * query.pageSize;
  const end = start + query.pageSize;
  return contracts.slice(start, end);
}

export function filterContracts(
  contracts: TablePaymentContracts[],
  query: Query
): TablePaymentContracts[] {
  const props = [
    'status',
    'apn',
    'billNumber',
    'selectedInstallmentContractAmount',
    'selectedInstallmentContractAmountPaid',
    'selectedInstallmentContractBatchPaymentTrackingId',
    'selectedInstallmentContractIsPaid',
  ];
  const filtered = contracts.filter((contract) => {
    const term = (query.term || '').toLowerCase();

    const index = props.findIndex((prop) => {
      const value = contract[prop] ? contract[prop].toLowerCase() : '';
      return value.toLowerCase().indexOf(term) >= 0;
    });
    return index >= 0;
  });

  const queried = filtered.filter((contract) => {
    const q = query.query || {};
    const queryCols = Object.keys(q);
    if (queryCols.length) {
      const index = queryCols.findIndex((prop) => {
        const value =
          contract[prop] && typeof contract[prop] === 'string' ? contract[prop].toLowerCase() : '';

        if (Array.isArray(q[prop])) {
          return q[prop].some((x: string) => value.indexOf(x) >= 0);
        }
      });
      return index >= 0;
    }
    return true;
  });

  return sortContracts(queried, query);
}

export function filterAndTrimContracts(
  contracts: TablePaymentContracts[],
  query: Query
): TablePaymentContracts[] {
  return trimContracts(filterContracts(contracts, query), query);
}
