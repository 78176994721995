import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from 'src/global/components/Search/Search';
import { Query } from 'src/global/models/Query';
import { PageSize } from 'src/global/components/Tables/GenericTable';
import { getUrlQuery, updateUrlQuery } from '../../../global/helpers/queryHelper';
import { commonStyle } from '../../../global/constants/common.style';
import { usePaymentContracts } from '../../../global/contexts/paymentContractsContext';
import { RetryPaymentQueryResults } from '../../../global/models/PaymentContractQueryResults';
import RetryPaymentTable from '../../../global/components/Tables/RetryPaymentTable';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { ROUTES } from '../../../global/constants/routes';

const RetryList: FunctionComponent = () => {
  const context = usePaymentContracts();
  const [retryResults, setResults] = useState<RetryPaymentQueryResults>();
  const navigate = useNavigate();
  const [query, setQuery] = useState<Query>(() => getUrlQuery(undefined, undefined, '', {}));

  useEffect(() => {
    setResults(context.retryPaymentResults);
  }, [context.retryPaymentResults]);

  useEffect(() => {
    context.queryRetriesPayment(query);
    updateUrlQuery(query, navigate);
  }, [query, navigate]);

  const handleSearchTermChange = useCallback((term: string) => {
    setQuery({ ...query, ...{ page: 0 }, term });
  }, []);

  return (
    <div>
      <div style={commonStyle.search}>
        <Search onTermChange={handleSearchTermChange} term={query.term || ''} />
      </div>
      <RetryPaymentTable
        onCellClick={(cellParams: GridCellParams) =>
          navigate(`${ROUTES.RETRY_PAYMENTS}/${cellParams.row.voucherInstallmentId}`)
        }
        rows={retryResults?.rows}
        rowsPerPageOptions={[PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE]}
        query={query}
        updateQuery={setQuery}
        rowCount={context.retryPaymentResults?.total || 1}
        loading={context.retryLoading}
      />
    </div>
  );
};

export default RetryList;
