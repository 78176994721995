import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { createContext, useContext, useState } from 'react';

interface SnackbarMessageContextType {
  setMessage: (message: string, autoClose?: boolean) => void;
}

const SnackbarMessageContext = createContext<SnackbarMessageContextType>(null!);

export function SnackbarMessageProvider({ children }: { children: React.ReactNode }) {
  const [snackbarMessage, setSnackbarMessage] = useState<string>();

  const handleClose = () => {
    setSnackbarMessage('');
  };

  const setMessage = (message: string, autoClose: boolean = true) => {
    setSnackbarMessage(message);
    if (autoClose) {
      setTimeout(() => {
        handleClose();
      }, 3500);
    }
  };

  const value = {
    setMessage,
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <SnackbarMessageContext.Provider value={value}>
      {children}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={6000}
        onClose={handleClose}
        message={snackbarMessage}
        action={action}
      />
    </SnackbarMessageContext.Provider>
  );
}

export function useSnackbarMessage() {
  return useContext(SnackbarMessageContext);
}
