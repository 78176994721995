import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridStringOperators,
  getGridSingleSelectOperators,
  GridColDef,
  GridFilterItem,
} from '@mui/x-data-grid-pro';
import { random } from './random';

const dataGridValues = ['is', 'onOrAfter', 'onOrBefore'];
const stringGridValues = ['equals'];
const singleSelectOperators = ['is'];
const numberGridValues = ['=', '>=', '<='];

export function getSingleSelectOperators() {
  const filters = getGridSingleSelectOperators().filter((o) => {
    return singleSelectOperators.includes(o.value);
  });
  filters[0].value = 'equals';
  return filters;
}

export function getDataGridOperators() {
  return getGridDateOperators().filter((o) => {
    return dataGridValues.includes(o.value);
  });
}

export function getStringGridOperators() {
  return getGridStringOperators().filter((o) => {
    return stringGridValues.includes(o.value);
  });
}

export function getNumberGridOperators() {
  return getGridNumericOperators().filter((o) => {
    return numberGridValues.includes(o.value);
  });
}

export function prepareFilter(item: { [key: string]: any[] | any }) {
  const items: GridFilterItem[] = [];
  let operator: string;
  Object.keys(item).forEach((field) => {
    const values = item[field];
    if (field !== 'term') {
      if (Array.isArray(values)) {
        // string
        // singleSelect
        operator = 'equals';
        values.forEach((value) => {
          items.push({
            id: random(1, 999999),
            field,
            value,
            operator,
          });
        });
      } else if (['true', 'false'].includes(values)) {
        // boolean
        items.push({
          id: random(1, 999999),
          field,
          value: values,
          operator: 'is',
        });
      } else {
        Object.keys(values).forEach((key) => {
          let value = values[key];
          if (isNaN(value)) {
            // date
            switch (key) {
              case 'eq':
                operator = 'is';
                break;
              case 'min':
                operator = 'onOrAfter';
                break;
              case 'max':
                operator = 'onOrBefore';
                break;
            }
          } else {
            value = +value;
            switch (key) {
              case 'eq':
                operator = '=';
                break;
              case 'min':
                operator = '>=';
                break;
              case 'max':
                operator = '<=';
                break;
            }
          }

          items.push({
            id: random(1, 999999),
            field,
            value,
            operator,
          });
        });
      }
    }
  });
  return items;
}

export function prepareQuery(
  filter: { [key: string]: any[] },
  columns: GridColDef[]
): { [key: string]: any[] } {
  const newQuery: { [key: string]: string | string[] | any } = {};

  Object.keys(filter).forEach((key) => {
    const type = columns.find((c) => c.field === key)?.type;
    const values = filter[key];
    values.forEach((v) => {
      if (v.value !== undefined && v.value !== null) {
        switch (type) {
          case 'boolean':
            newQuery[key] = v.value === 'true';
            break;
          case 'string':
          case 'singleSelect':
            if (!newQuery[key]) {
              newQuery[key] = [];
            }
            newQuery[key].push(v.value);
            break;
          case 'number':
            if (!newQuery[key]) {
              newQuery[key] = {};
            }

            switch (v.operator) {
              case '=':
                Object.assign(newQuery[key], { eq: +v.value });
                break;
              case '>=':
                Object.assign(newQuery[key], { min: +v.value });
                break;
              case '<=':
                Object.assign(newQuery[key], { max: +v.value });
                break;
            }
            break;
          case 'date':
            if (!newQuery[key]) {
              newQuery[key] = {};
            }

            switch (v.operator) {
              case 'is':
                if (`${v.value}`.includes('T')) {
                  Object.assign(newQuery[key], {
                    min: new Date(`${v.value}`).toISOString(),
                    max: new Date(`${v.value}`).toISOString(),
                  });
                } else {
                  Object.assign(newQuery[key], {
                    min: new Date(`${v.value}T00:00:00`).toISOString(),
                    max: new Date(`${v.value}T23:59:59`).toISOString(),
                  });
                }
                break;
              case 'onOrAfter':
                if (`${v.value}`.includes('T')) {
                  Object.assign(newQuery[key], {
                    min: new Date(`${v.value}`).toISOString(),
                  });
                } else {
                  Object.assign(newQuery[key], {
                    min: new Date(`${v.value}T00:00:00`).toISOString(),
                  });
                }
                break;
              case 'onOrBefore':
                if (`${v.value}`.includes('T')) {
                  Object.assign(newQuery[key], {
                    max: new Date(`${v.value}`).toISOString(),
                  });
                } else {
                  Object.assign(newQuery[key], {
                    max: new Date(`${v.value}T23:59:59`).toISOString(),
                  });
                }
                break;
            }
            break;
          default:
            break;
        }
      }
    });
  });

  return newQuery;
}
