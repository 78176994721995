import { DefaultContext } from '../models/Context';
import { Query } from '../models/Query';
import { AxiosError } from 'axios';
import React from 'react';
import { useAuth } from './authContext';
import { PageSize } from '../components/Tables/GenericTable';
import { checkQuery } from '../helpers/queryHelper';
import {
  NotificationLog,
  NotificationLogQueryRequest,
  NotificationLogQueryResponse,
} from '../models/NotificationLog';
import { getNotificationLog, getNotificationLogs } from '../services/notificationLogs';

interface NotificationLogsContextType extends DefaultContext {
  notificationLogs?: NotificationLogQueryResponse;
  getList: (query?: Query) => void;
  getNotificationLogById: (id: string) => Promise<NotificationLog | AxiosError | unknown>;
  refreshList: () => void;
  clearList: () => void;
}

const NotificationLogsContext = React.createContext<NotificationLogsContextType>(null!);

export function NotificationLogsProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [notificationLogs, setNotificationLogs] = React.useState<NotificationLogQueryResponse>();
  const [lastQuery, setLastQuery] = React.useState<Query | undefined>();
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);
  const auth = useAuth();
  const defaultQuery: Query = {
    page: 0,
    pageSize: PageSize.MEDIUM,
    term: '',
    sortColumn: 'createdAt',
    sortDirection: 'DESC',
  };

  const checkRunAction = (query: Query): boolean => {
    return (
      lastQuery?.page !== query?.page ||
      lastQuery?.pageSize !== query?.pageSize ||
      lastQuery?.sortColumn !== query?.sortColumn ||
      lastQuery?.sortDirection !== query?.sortDirection ||
      lastQuery?.term !== query?.term ||
      checkQuery(query)
    );
  };

  const refreshList = () => {
    setRefreshQuery(true);
  };

  const getList = (q: Query = { ...defaultQuery }) => {
    if ((q && checkRunAction(q)) || refreshQuery) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);
      const query = { ...q.query, ...(q.term && { term: q.term }) };
      const request: NotificationLogQueryRequest = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
      };

      getNotificationLogs(request)
        .then((result: NotificationLogQueryResponse | AxiosError) => {
          const data: NotificationLogQueryResponse = result as NotificationLogQueryResponse;
          setHasError(false);
          setLoaded(true);
          setLoading(false);
          setNotificationLogs(data);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoaded(false);
          setLoading(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
          setRefreshQuery(false);
        });
    }
  };

  const getNotificationLogById = async (id: string) => {
    try {
      return await getNotificationLog(id);
    } catch (e) {
      setHasError(true);
    }
  };

  const clearList = () => {
    setNotificationLogs(undefined);
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    getList,
    getNotificationLogById,
    lastQuery,
    notificationLogs,
    refreshList,
    clearList,
  };

  return (
    <NotificationLogsContext.Provider value={value}>{children}</NotificationLogsContext.Provider>
  );
}

export function useNotificationLogs(): NotificationLogsContextType {
  return React.useContext(NotificationLogsContext);
}
