import React, { FunctionComponent } from 'react';
import Button from '@mui/material/Button';
import LogoIcon from '../icons/LogoIcon';
import { logout } from 'src/global/services/auth';
import IconButton from '@mui/material/IconButton';

import MenuIcon from '@mui/icons-material/Menu';

const styles = {
  header: {
    width: '100%',
    maxWidth: '100vw',
    height: '75px',
    backgroundColor: '#333333',
    // marginBottom: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Roboto',
    zIndex: 1201,
    position: 'fixed' as 'fixed',
  },
  wrapper: {
    width: '100%',
    maxWidth: '1110px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sidesWidth: {
    width: '103px',
  },
  signOutButton: {
    height: '36px',
    borderRadius: '4px',
    color: '#000000',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
    backgroundColor: '#fff',
  },
  logoArea: {
    minWidth: '120px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  adminText: {
    color: 'rgba(255,255,255,0.87)',
    fontFamily: 'Roboto',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    marginLeft: '16px',
  },
};

export interface HeaderProps {
  open: boolean;
  onDrawerOpen: () => void;
}

const Header: FunctionComponent<HeaderProps> = ({ open, onDrawerOpen }: HeaderProps) => {
  const buttonStyle = {
    ...styles.signOutButton,
    ...styles.sidesWidth,
  };

  function onSignOut() {
    logout()
      .then()
      .catch((err) => console.log(err));
  }

  return (
    <header style={{ ...styles.header, ...(open && { zIndex: 1190 }) }}>
      <IconButton
        onClick={onDrawerOpen}
        style={{ color: 'white' }}
        sx={{
          position: 'absolute',
          left: '1rem',
          marginRight: 0,
          marginLef: 0,
          ...(open && { display: 'none' }),
        }}
      >
        <MenuIcon />
      </IconButton>
      <div style={styles.wrapper}>
        <div style={styles.sidesWidth} />
        <div style={styles.logoArea}>
          <LogoIcon />
          <p style={styles.adminText}>Admin</p>
        </div>
        <Button sx={buttonStyle} onClick={onSignOut}>
          <p>Sign out</p>
        </Button>
      </div>
    </header>
  );
};

export default Header;
