import { FunctionComponent, useEffect, useState } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridCellParams,
  GridRowsProp,
  ValueOptions,
} from '@mui/x-data-grid-pro';
import Modal from '@mui/material/Modal';
import CheckIcon from '@mui/icons-material/Check';
import CanceledIcon from '../icons/CanceledIcon';
import FailedIcon from '../icons/FailedIcon';
import PendingIcon from '../icons/PendingIcon';
import InProgressIcon from '../icons/InProgressIcon';
import Table, { PageSize, formatDate, TableProps } from 'src/global/components/Tables/Table';
import OrderDetails from 'src/pages/customers/customer/components/OrderDetails/OrderDetails';
import { Order } from 'src/global/models/Order';
import formatTaxYear from 'src/global/helpers/formatTaxYear';
import { toPrettyUSDFromFloat } from 'src/global/helpers/toPrettyUSD';
import FormatLanguage from 'src/global/helpers/formatLanguage';
import {
  getDataGridOperators,
  getNumberGridOperators,
  getSingleSelectOperators,
  getStringGridOperators,
} from '../../helpers/filterOperators';
import { AvailableExportColumn } from './ExportColumnChooser';
import { usePaymentContracts } from '../../contexts/paymentContractsContext';
import { CircularProgress } from '@mui/material';
import { GridColDefWithExport } from '../../models/GridColDefWithExport';

interface ExtendedTableProps extends TableProps {
  hasAddress?: boolean;
}

const ContractTable: FunctionComponent<ExtendedTableProps> = ({
  rows,
  rowsPerPageOptions = [PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE],
  onCellClick,
  query,
  updateQuery,
  rowCount,
  loading = false,
  hasAddress = false,
  exportData,
  exporting,
  exported,
  filterVisible = true,
  exportVisible = true,
}: ExtendedTableProps) => {
  const paymentContractsContext = usePaymentContracts();
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [formattedRows, setFormattedRows] = useState<GridRowsProp>();
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [columnsToExport, setColumnsToExport] = useState<AvailableExportColumn[]>([]);

  useEffect(() => {
    let gridRowsProps: GridRowsProp = [];
    let cols: GridColDefWithExport[] = [];
    let colsToExport: AvailableExportColumn[] = [];

    if (rows) {
      gridRowsProps = rows?.map((row) => {
        const address = JSON.parse(row.address?.json || '{}');
        return {
          status: row.status,
          dueDate: row.dueDate,
          createdAt: row.createdAt,
          amount: toPrettyUSDFromFloat(row.amount),
          amountPaid: toPrettyUSDFromFloat(row.amountPaid),
          apn: row.apn,
          taxRollId: row.taxRollId,
          billNumber: row.billNumber,
          customerId: row.customerId,
          taxYear: formatTaxYear(row.taxYear),
          orderId: row.orderId,
          county:
            address.SitusCounty ||
            FormatLanguage.capitalizeFirstLetter(row.taxRollId?.split('-')[0] || ''),
          street: address.PropertyAddressFull,
          address:
            address.PropertyAddressFull &&
            address.PropertyAddressCity &&
            address.PropertyAddressState &&
            address.PropertyAddressZIP
              ? `${address.PropertyAddressFull}, ${address.PropertyAddressCity}, ${address.PropertyAddressState} ${address.PropertyAddressZIP}`
              : undefined,
          disbursmentAmount: toPrettyUSDFromFloat(row.disbursmentAmount || 0),
        };
      });

      cols = [
        {
          field: 'status',
          headerName: 'Status',
          flex: 100,
          align: 'center',
          headerAlign: 'left',
          renderCell: (params: GridRenderCellParams): any => {
            const { value } = params;
            switch (value) {
              case 'FAILURE':
                return <FailedIcon />;
              case 'PENDING':
                return <PendingIcon />;
              case 'IN_PROCESS':
                return <InProgressIcon />;
              case 'CANCELED':
                return <CanceledIcon />;
              default:
                return <CheckIcon sx={{ color: '#000000' }} />;
            }
          },
          type: 'singleSelect',
          exportable: true,
          filterable: filterVisible,
          filterOperators: getSingleSelectOperators(),
          getOptionLabel: (p: ValueOptions) => {
            return (
              p as {
                value: any;
                label: string;
              }
            ).label;
          },
          valueOptions: [
            {
              value: 'ACTIVE',
              label: 'Active',
            },
            {
              value: 'FAILURE',
              label: 'Failure',
            },
            {
              value: 'PENDING',
              label: 'Pending',
            },
            {
              value: 'IN_PROCESS',
              label: 'In progress',
            },
            {
              value: 'CANCELED',
              label: 'Canceled',
            },
          ],
        },
        {
          field: 'orderId',
          headerName: 'Order Id',
          type: 'string',
          exportable: true,
          filterable: filterVisible,
        },
        {
          field: 'receiptId',
          headerName: 'Receipt Id',
          type: 'string',
          exportable: true,
          filterOperators: getStringGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'createdAt',
          headerName: 'Order Date',
          flex: 100,
          align: 'left',
          valueFormatter: formatDate,
          type: 'date',
          exportable: true,
          filterOperators: getDataGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'amount',
          headerName: 'Amount',
          flex: 100,
          align: 'left',
          type: 'number',
          filterable: false,
        },
        {
          field: 'apn',
          headerName: 'APN',
          flex: 100,
          align: 'left',
          type: 'string',
          filterOperators: getStringGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'taxRollId',
          headerName: 'TaxRoll Id',
          flex: 100,
          align: 'left',
          type: 'string',
          filterOperators: getStringGridOperators(),
          exportable: true,
          filterable: filterVisible,
        },
        {
          field: 'billNumber',
          headerName: 'Bill Number',
          flex: 100,
          align: 'left',
          type: 'string',
          filterOperators: getStringGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'billType',
          headerName: 'Bill Type',
          type: 'number',
          filterOperators: getNumberGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'voucherId',
          headerName: 'Voucher Id',
          type: 'string',
          filterOperators: getStringGridOperators(),
          filterable: filterVisible,
        },
        {
          field: 'hasPaymentPlan',
          headerName: 'Has Payment Plan',
          type: 'boolean',
          filterable: filterVisible,
          // filterOperators: getBooleanGridOperators()
        },
        {
          field: 'estimateOptIn',
          headerName: 'Estimate Opt In',
          type: 'boolean',
          filterable: filterVisible,
          // filterOperators: getBooleanGridOperators()
        },
        {
          field: 'originalVoucherId',
          headerName: 'Original Voucher Id',
          type: 'string',
          filterable: filterVisible,
          filterOperators: getStringGridOperators(),
        },
        ...(!hasAddress
          ? [
              {
                field: 'customerId',
                headerName: 'Customer Id',
                flex: 100,
                align: 'left' as 'left',
                type: 'string',
                filterOperators: getStringGridOperators(),
                filterable: filterVisible,
              },
            ]
          : []),
        {
          field: 'taxYear',
          headerName: 'Tax Year',
          flex: 100,
          align: 'left',
          type: 'number',
          filterOperators: getNumberGridOperators(),
          filterable: filterVisible,
        },
        ...(rows && hasAddress
          ? [
              {
                field: 'county',
                headerName: 'County',
                flex: 100,
                align: 'left' as 'left',
                type: 'string',
                filterOperators: getStringGridOperators(),
                filterable: filterVisible,
              },
              {
                field: 'address',
                headerName: 'Address',
                flex: 100,
                align: 'left' as 'left',
                type: 'string',
                filterable: false,
              },
            ]
          : [
              {
                field: 'dueDate',
                headerName: 'Due Date',
                flex: 100,
                align: 'left' as 'left',
                hideSortIcons: true,
                sortable: false,
                filterable: false,
              },
            ]),
        {
          field: 'disbursmentAmount',
          headerName: 'Disbursement Amount',
          flex: 100,
          align: 'left',
          type: 'number',
          sortable: false,
          filterable: false,
        },
      ];

      colsToExport = [
        {
          field: 'isEstimated',
          headerName: 'Is Estimate',
          exportable: false,
        },
        {
          field: 'amountFirstInstallment',
          headerName: 'Amount First Installment',
          exportable: false,
        },
        {
          field: 'amountSecondInstallment',
          headerName: 'Amount Second Installment',
          exportable: false,
        },
        {
          field: 'amountPaidFirstInstallment',
          headerName: 'Amount Paid First Installment',
          exportable: false,
        },
        {
          field: 'amountPaidSecondInstallment',
          headerName: 'Amount Paid Second Installment',
          exportable: false,
        },
        {
          field: 'installmentNumbers',
          headerName: 'Installments',
          exportable: false,
        },
        {
          field: 'transactionFeeCollected',
          headerName: 'Transaction Fees Collected',
          exportable: false,
        },
        {
          field: 'taxesCollected',
          headerName: 'RE Taxes Collected',
          exportable: false,
        },
        {
          field: 'paymentType',
          headerName: 'Payment type',
          exportable: false,
        },
        {
          field: 'transactionRatePct',
          headerName: 'Transaction Rate Pct',
          exportable: false,
        },
      ];
    }
    setFormattedRows(gridRowsProps);
    setColumns(cols);
    setColumnsToExport(() => {
      const colsToExportInQuery = query.colsToExport?.split(',') || [];
      const newCols = [...cols, ...colsToExport] as AvailableExportColumn[];

      if (paymentContractsContext.columnsToExport.length) {
        newCols.map((x) => {
          x.exportable = !!paymentContractsContext.columnsToExport.find(
            (p: AvailableExportColumn) => p.field === x.field
          );
          return x;
        });
      }

      if (colsToExportInQuery.length) {
        newCols.map((x) => {
          x.exportable = colsToExportInQuery.includes(x.field);
          return x;
        });
      }

      return [...newCols] as AvailableExportColumn[];
    });
  }, [rows]);

  function handleOnCellClick(params: GridCellParams<any, any, any>): void {
    if (params.field === 'orderDetails' && Object.keys(params.row?.orderDetails || {}).length > 0) {
      setSelectedOrder(params.row.orderDetails as Order);
    } else {
      setSelectedOrder(null);
      if (onCellClick) {
        onCellClick(params);
      }
    }
  }

  return query && rows && columns.length ? (
    <>
      <div>
        <Modal
          open={!!selectedOrder}
          onClose={() => setSelectedOrder(null)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <OrderDetails
            order={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            onViewProfile={() => console.log('view profile')}
            onViewOrders={() => console.log('view orders')}
          />
        </Modal>
        <Table
          rows={formattedRows}
          columns={columns}
          rowsPerPageOptions={rowsPerPageOptions}
          onCellClick={handleOnCellClick}
          query={query}
          updateQuery={updateQuery}
          filterMode={'server'}
          hiddenColumns={[
            'orderId',
            'receiptId',
            'billType',
            'voucherId',
            'hasPaymentPlan',
            'estimateOptIn',
            'originalVoucherId',
            'disbursmentAmount',
          ]}
          columnsToExport={columnsToExport}
          rowCount={rowCount}
          loading={loading}
          exportData={exportData}
          exporting={exporting || false}
          exported={exported}
          filterVisible={filterVisible}
          exportVisible={exportVisible}
        />
      </div>
    </>
  ) : (
    <div>
      <CircularProgress />
    </div>
  );
};

export default ContractTable;
