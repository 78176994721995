import { AxiosError } from 'axios';
import { getAxios } from './espAxios';
import { CustomerDto, CustomerQueryRequest, CustomerQueryResponse } from '../models/Customer';
import { PaymentContractWithAddress } from '../models/PaymentContract';

const BASE_SERVICE_PATH = '';

export function getCustomers(
  request: CustomerQueryRequest
): Promise<CustomerQueryResponse | AxiosError> {
  return getAxios()
    .post<CustomerQueryResponse>(`${BASE_SERVICE_PATH}/customers/query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getCustomer(customerId: string): Promise<CustomerDto | AxiosError> {
  return getAxios()
    .get<CustomerDto>(`${BASE_SERVICE_PATH}/customers/${customerId}`)
    .then((resp) => resp.data);
}

export function getContractsWithAddressesByCustomerId(
  customerId: string
): Promise<PaymentContractWithAddress[] | AxiosError> {
  return getAxios()
    .get<PaymentContractWithAddress[]>(
      `${BASE_SERVICE_PATH}/customers/${customerId}/payment-contracts/addresses`
    )
    .then((resp) => resp.data);
}
