import { DateTime } from 'luxon';

import { BillDate } from '../models/PaymentContract';

type Styles = 'shortMonth' | 'expiration' | 'international' | 'default' | 'table' | 'dateTime';

/**
 * Take a date string like '2019-12-03' and format it into a human-readable date string
 */
export default function toPrettyDate(
  serverDate: string | BillDate | Date,
  style: Styles = 'default',
  forCSVFile: boolean = false,
  customFormat?: string
): string {
  let dateAsString: any = serverDate;
  let date: DateTime;

  if ((serverDate as BillDate).year) {
    const dateAsBillDate = { ...(serverDate as BillDate) };
    const day =
      dateAsBillDate.day.toString().length === 1 ? `0${dateAsBillDate.day}` : dateAsBillDate.day;
    const month =
      dateAsBillDate.month.toString().length === 1
        ? `0${dateAsBillDate.month}`
        : dateAsBillDate.month;
    dateAsString = `${month}/${day}/${dateAsBillDate.year}`;
    date = DateTime.fromFormat(dateAsString, 'M/d/yyyy');
  } else if (serverDate as Date) {
    dateAsString = (serverDate as Date).toISOString();
    date = DateTime.fromISO(dateAsString);
  } else {
    date = DateTime.fromFormat(dateAsString, 'M/d/yyyy');
  }

  if (customFormat) {
    return date.toFormat(customFormat);
  }

  switch (style) {
    case 'international':
      return date.toFormat('yyyy-MMM-dd');
    case 'shortMonth':
      // 'Dec 03, 2019'
      if (forCSVFile) {
        return `"${date.toFormat('MMM d, yyyy')}"`;
      }
      return date.toFormat('MMM d, yyyy');
    case 'expiration':
      return date.toFormat('MM/yyyy');
    case 'table':
      return date.toFormat('yyyy-MM-dd');
    case 'dateTime':
      return date.toFormat('yyyy-MM-dd hh:mm a');
    default:
      // 'December 03, 2019'
      if (forCSVFile) {
        return `${date.toFormat('MMM dd, yyyy')}`;
      }
      return date.toFormat('MMM dd, yyyy');
  }
}

export function parseDateWithoutTimezone(dateString: string) {
  // Split the date part from the time part
  const [year, month, day] = dateString.split('T')[0].split('-');

  // Create a Date object using year, month, and day (Note: month is 0-indexed in JavaScript Date)
  return new Date(Number(year), Number(month) - 1, Number(day));
}
