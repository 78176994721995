import React, { useEffect, useState } from 'react';
import { Query } from '../models/Query';
import { ImportJob, ImportJobQueryRequest, ImportJobQueryResponse } from '../models/ImportJob';
import { useAuth } from './authContext';
import { checkQuery } from '../helpers/queryHelper';
import { AxiosError } from 'axios';
import { getImportedJob, getImportedJobs } from '../services/importJobs';
import { DefaultContext } from '../models/Context';

interface ImportJobsContextType extends DefaultContext {
  queryJobs: (query: Query) => void;
  results?: ImportJobRowResult;
  query?: Query;
  getJobById: (id: string) => Promise<ImportJob | null | unknown>;
  jobLoading: boolean;
  jobLoaded: boolean;
}

let ImportJobsContext = React.createContext<ImportJobsContextType>(null!);

export interface ImportJobRowResult extends Omit<ImportJobQueryResponse, 'results'> {
  rows: ImportJob[];
  query: ImportJobQueryRequest;
  totalPages: number;
}

export function ImportJobsProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);
  let [jobLoading, setJobLoading] = useState<boolean>(false);
  let [jobLoaded, setJobLoaded] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [results, setResults] = useState<ImportJobRowResult | undefined>();
  let [query, setQuery] = useState<Query | undefined>();
  let [lastViewedPage, setLastViewedPage] = useState<number>(0);
  const auth = useAuth();

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    const request: ImportJobQueryRequest = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getImportedJobs(request)
      .then((result) => {
        const jobsResult = result as ImportJobQueryResponse;

        setResults({
          total: jobsResult.total || 0,
          query: request,
          rows: jobsResult.results,
          page: request.page,
          pageSize: request.pageSize,
          totalPages: Math.round(jobsResult.total / jobsResult.pageSize) || 0,
        });
        setLastViewedPage(request.page - 1);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  const queryJobs = (q: ImportJobQueryRequest) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query)
    ) {
      // prevent the table from showing the incorrect data page
      // after navigating to a different screen
      if (!q.page || q.page !== lastViewedPage || termChanged) {
        setQuery(q);
      }
    }
  };

  const getJobById = async (id: string): Promise<ImportJob | AxiosError | unknown> => {
    setJobLoading(true);
    setJobLoaded(false);
    return new Promise(async (resolve, reject) => {
      getImportedJob(id)
        .then((resp) => {
          setJobLoading(false);
          setJobLoaded(true);
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setLoading(false);
        });
    });
  };

  let value = {
    queryJobs,
    getJobById,
    loading,
    loaded,
    hasError,
    errorMessage,
    results,
    jobLoading,
    jobLoaded,
  };

  return <ImportJobsContext.Provider value={value}>{children}</ImportJobsContext.Provider>;
}

export function useImportJobs() {
  return React.useContext(ImportJobsContext);
}
