import React from 'react';
import { AxiosError } from 'axios';
import { generateCortacFile, getProducts, getProduct } from '../services/products';
import { Installment, Product } from '../models/Product';
import { useAuth } from './authContext';
import { Query } from '../models/Query';
import { QueryResults } from '../models/QueryResults';
import { filterCounties } from '../helpers/filterCounties';
import { County, GenerateCortacDataRequest } from '../models/County';
import { PageSize } from '../components/Tables/GenericTable';
import { downloadTextAsFile } from '../helpers/downloadFile';
import { DefaultContext } from '../models/Context';

interface ProductsContextType extends DefaultContext {
  products: Product[];
  getAllProducts: () => void;
  query?: Query;
  setQuery: React.Dispatch<React.SetStateAction<Query | undefined>>;
  queryProducts: (query: Query) => void;
  results?: QueryResults;
  getProductById: (id: string) => Promise<Product | AxiosError | unknown>;
  generateCountyCortacFile: (data: GenerateCortacDataRequest) => Promise<null | string>;
  productLoading: boolean;
  productLoaded: boolean;
}

function getPageTotal(counties: Product[] | undefined, pageSize: number) {
  const countiesSize = counties?.length || 0;

  if (countiesSize === 0) {
    return 0;
  }

  return Math.round(countiesSize / pageSize);
}

function formatProduct(product: Product): County {
  const state = product.settings.settings.state;
  const id = `${product.taxroll.parser}-${state.toLowerCase()}`;
  return {
    name: product.settings.settings.displayCounty,
    state,
    status: product.status,
    id: product.productId,
    installments: product.billing.config.estimateSettings.installments.map(
      (x: Installment) => x.installmentNumber
    ),
    taxroll: { ...product.taxroll, id },
  };
}

function formatProductList(products: Product[]): County[] {
  return products.map((product: Product) => formatProduct(product));
}

const ProductsContext = React.createContext<ProductsContextType>(null!);

export function ProductsProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [productLoading, setProductLoading] = React.useState<boolean>(false);
  const [productLoaded, setProductLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [products, setProducts] = React.useState<Product[]>([]);
  const [query, setQuery] = React.useState<Query | undefined>({
    page: 0,
    pageSize: PageSize.MEDIUM,
    term: '',
  });
  const [results, setResults] = React.useState<QueryResults | undefined>();

  const auth = useAuth();

  const queryProducts = (q: Query, p?: Product[]) => {
    setQuery(q);

    const countyList = products.length ? products : p?.length ? p : null;

    if (countyList?.length) {
      setResults({
        total: countyList?.length || 0,
        query: q,
        rows: filterCounties(formatProductList(countyList), q),
        page: q.page,
        pageSize: q.pageSize,
        totalPages: getPageTotal(countyList, q.pageSize),
      });
    }
  };

  const getAllProducts = () => {
    if (!products.length) {
      setLoading(true);
      getProducts()
        .then((res) => {
          const p = res as Product[];
          p.map((x) => {
            x.installments = x.billing.config.estimateSettings.installments.map(
              (installment: Installment) => installment.installmentNumber
            );
            x.taxroll.id = `${x.taxroll.parser}-${x.settings.settings.state.toLowerCase()}`;
            return x;
          });
          setProducts(p);
          if (!!query) {
            queryProducts(query, p);
          }
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setLoading(false);
          setErrorMessage(err.message);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    }
  };

  const getProductById = async (productId: string) => {
    setProductLoading(true);
    setProductLoaded(false);

    return new Promise(async (resolve, reject) => {
      getProduct(productId)
        .then((resp) => {
          setProductLoading(false);
          setProductLoaded(true);
          // @ts-ignore
          resolve(formatProduct(resp));
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setProductLoading(false);
        });
    });
  };

  const generateCountyCortacFile = async (
    data: GenerateCortacDataRequest
  ): Promise<null | string> => {
    return new Promise(async (resolve, reject) => {
      generateCortacFile(data)
        .then((response) => {
          // if there is no cortac file
          if (!Object.keys(response).length || !response) {
            reject('No CORTAC File Available');
          } else {
            downloadTextAsFile(response as string, `${data.taxRollId}Cortac-${data.taxYear}.txt`);
            resolve(null);
          }
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  };

  const value = {
    loading,
    loaded,
    productLoaded,
    productLoading,
    hasError,
    errorMessage,
    products,
    getAllProducts,
    query,
    setQuery,
    queryProducts,
    results,
    getProductById,
    generateCountyCortacFile,
  };

  return <ProductsContext.Provider value={value}>{children}</ProductsContext.Provider>;
}

export function useProducts() {
  return React.useContext(ProductsContext);
}
