import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchProps {
  term: string;
  onTermChange: (term: string) => void;
}

const styles = {
  wrapper: {
    width: 'fit-content',
    borderRadius: '4px 4px 0 0',
    backgroundColor: 'rgba(0,0,0,0.1)',
    padding: '0 19px',
  },
  input: {
    height: '56px',
    width: '328px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

const Search: FunctionComponent<SearchProps> = ({ term, onTermChange }: SearchProps) => {
  const debouncedSearch = useMemo(
    () =>
      debounce((val: string) => {
        onTermChange(val);
      }, 750),
    [onTermChange]
  );

  const handleInputChange = useCallback(
    (e) => {
      onTermChange(e.target.value);
    },
    [debouncedSearch]
  );

  return (
    <div style={styles.wrapper}>
      <InputBase
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        sx={styles.input}
        placeholder="Search..."
        value={term}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default Search;
