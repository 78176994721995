import React, { useEffect, useState } from 'react';
import { DefaultContext } from '../models/Context';
import { Query } from '../models/Query';
import { useAuth } from './authContext';
import {
  checkAgreementPresented,
  createUserAgreement,
  deleteUserAgreement,
  getAgreement,
  getUserAgreements,
  updateUserAgreement,
} from '../services/userAgreements';
import { AxiosError } from 'axios';
import { UserAgreementDto, UserAgreementQueryResponse } from '../models/UserAgreement';
import { checkQuery } from '../helpers/queryHelper';

interface UserAgreementContextType extends DefaultContext {
  query?: Query;
  results?: UserAgreementRowResult;
  singleAgreementLoading: boolean;
  singleAgreementLoaded: boolean;
  getAgreements: (query: Query) => void;
  resetQuery: () => void;
  getAgreementById: (id: string) => Promise<UserAgreementDto | AxiosError>;
  createAgreement: (agreement: UserAgreementDto) => Promise<UserAgreementDto | AxiosError>;
  updateAgreement: (agreement: UserAgreementDto) => Promise<UserAgreementDto | AxiosError>;
  deleteAgreement: (id: string) => Promise<boolean>;
}

const UserAgreementContext = React.createContext<UserAgreementContextType>(null!);

export interface UserAgreementRowResult extends Omit<UserAgreementQueryResponse, 'results'> {
  rows: UserAgreementDto[];
  query: Query;
  totalPages: number;
}

export function UserAgreementProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);

  const [singleAgreementLoading, setAgreementLoading] = React.useState<boolean>(false);
  const [singleAgreementLoaded, setAgreementLoaded] = React.useState<boolean>(false);

  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [query, setQuery] = useState<Query | undefined>();
  let [lastViewedPage, setLastViewedPage] = useState<number>(0);
  let [results, setResults] = useState<UserAgreementRowResult | undefined>();

  const auth = useAuth();

  const getAgreementById = async (agreementId: string) => {
    setAgreementLoading(true);
    setAgreementLoaded(false);

    try {
      let agreementPresented = await checkAgreementPresented(agreementId);

      const resp = await getAgreement(agreementId);
      setAgreementLoading(false);
      setAgreementLoaded(true);
      return { ...resp, presented: agreementPresented === true };
    } catch (error) {
      setAgreementLoading(false);
      setAgreementLoaded(false);
      throw error;
    }
  };

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    const request: Query = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query || {},
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getUserAgreements(request)
      .then((result) => {
        const customerResult = result as UserAgreementQueryResponse;
        const customers = customerResult.results;

        setResults({
          total: customerResult.total || 0,
          query: request,
          rows: customers,
          page: request.page,
          pageSize: request.pageSize,
          totalPages: Math.round(customerResult.total / customerResult.pageSize) || 0,
        });
        setLastViewedPage(request.page - 1);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  const getAgreements = (q: Query) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query)
    ) {
      // prevent the table from showing the incorrect data page
      // after navigating to a different screen
      if (!q.page || q.page !== lastViewedPage || termChanged) {
        setQuery(q);
      }
    }
  };

  const resetQuery = () => {
    setQuery(undefined);
  };

  const createAgreement = async (
    agreement: UserAgreementDto
  ): Promise<UserAgreementDto | AxiosError> => {
    setAgreementLoading(true);
    const { presented, ...newAgreement } = agreement;

    return createUserAgreement(newAgreement).then((resp) => {
      setAgreementLoading(false);
      return { ...resp, presented: false };
    });
  };

  const updateAgreement = async (
    agreement: UserAgreementDto
  ): Promise<UserAgreementDto | AxiosError> => {
    setAgreementLoading(true);
    const { presented, agreementId, id, ...agreementToUpdate } = agreement;

    return updateUserAgreement(agreementId as string, agreementToUpdate).then((resp) => {
      setAgreementLoading(false);
      return { ...resp, presented: false };
    });
  };

  const deleteAgreement = async (agreementId: string): Promise<boolean> => {
    setAgreementLoading(true);
    return deleteUserAgreement(agreementId).then((response) => {
      if (!response) {
        setAgreementLoading(false);
        return true;
      }
      return false;
    });
  };

  const value: UserAgreementContextType = {
    loading,
    loaded,
    singleAgreementLoaded,
    singleAgreementLoading,
    hasError,
    errorMessage,
    query,
    results,
    resetQuery,
    getAgreements,
    getAgreementById,
    createAgreement,
    updateAgreement,
    deleteAgreement,
  };
  return <UserAgreementContext.Provider value={value}>{children}</UserAgreementContext.Provider>;
}

export function useUserAgreements() {
  return React.useContext(UserAgreementContext);
}
