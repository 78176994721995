import { getAxios } from './espAxios';

const BASE_SERVICE_PATH = '/auth';

export interface AuthenticateParams {}
export function authenticate(params: AuthenticateParams): Promise<any> {
  return getAxios()
    .get<any>(`${BASE_SERVICE_PATH}/google/redirect`, { params })
    .then((resp) => resp.data);
}

export function logout(): Promise<any> {
  return getAxios()
    .post<any>(`${BASE_SERVICE_PATH}/logout`)
    .then((resp) => resp.data);
}
