export default class FormatLanguage {
  static capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

  static setPossessive = (word: string) =>
    word.toLowerCase().endsWith('s') ? `${word}'` : `${word}'s`;

  static pluralize = (word: string, count: number, useParentheses?: boolean) => {
    if (count !== 1) {
      if (useParentheses) {
        return `${word}(s)`;
      } else {
        return `${word}s`;
      }
    } else {
      return word;
    }
  };
}
