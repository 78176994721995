import { getAxios } from './espAxios';
import { OrderQueryRequest, OrderQueryResponse } from '../models/Order';
import { AxiosError } from 'axios';
import {
  VoucherContractQueryRequest,
  VoucherContractQueryResponse,
} from '../models/VoucherContract';

const BASE_SERVICE_PATH = '';

export function getOrders(request: OrderQueryRequest): Promise<OrderQueryResponse | AxiosError> {
  return getAxios()
    .post<OrderQueryResponse>(`${BASE_SERVICE_PATH}/orders/query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getVoucherContracts(
  request: VoucherContractQueryRequest
): Promise<VoucherContractQueryResponse | AxiosError> {
  return getAxios()
    .post<VoucherContractQueryResponse>(
      `${BASE_SERVICE_PATH}/orders/voucher-contracts/query`,
      request
    )
    .then((resp) => {
      return resp.data;
    });
}

export async function getAllVoucherContracts(
  request: VoucherContractQueryRequest
): Promise<VoucherContractQueryResponse | AxiosError> {
  let allQueryResponse: VoucherContractQueryResponse;
  return new Promise(async (resolve, reject) => {
    while (!allQueryResponse || allQueryResponse.results.length < allQueryResponse.total) {
      if (allQueryResponse) {
        request.page += 1;
      }

      const queryResponse = (await getVoucherContracts(request)) as VoucherContractQueryResponse;

      if (!allQueryResponse) {
        allQueryResponse = queryResponse;
      } else {
        allQueryResponse.results = allQueryResponse.results.concat(queryResponse.results);
      }
    }

    if (allQueryResponse.results.length === allQueryResponse.total) {
      resolve(allQueryResponse);
    } else {
      reject('Failed to get all voucher contracts');
    }
  });
}
