import { AxiosError, AxiosResponse } from 'axios';
import { ROUTES } from '../constants/routes';
import { getAxios } from './espAxios';
import { CountyItem, CountyItemCreate, CountyRequest, CountyResponse } from '../models/County';

const BASE_SERVICE_PATH = ROUTES.COUNTIES;

export function getCounties(
  request?: CountyRequest,
): Promise<CountyResponse | AxiosError> {
  return getAxios()
    .post<CountyResponse | AxiosError>(`${BASE_SERVICE_PATH}/query`, request)
    .then((resp: AxiosResponse<CountyResponse | AxiosError>) => resp.data);
}

export function getCounty(taxRollId: string): Promise<CountyItem | AxiosError> {
  return getAxios()
    .get<CountyItem | AxiosError>(`${BASE_SERVICE_PATH}/${taxRollId}`)
    .then((resp: AxiosResponse<CountyItem | AxiosError>) => resp.data);
}

export function createCounty(
  county: CountyItemCreate
): Promise<CountyItem | AxiosError> {
  return getAxios()
    .post<CountyItem | AxiosError>(`${BASE_SERVICE_PATH}/create`, county)
    .then((resp: AxiosResponse<CountyItem | AxiosError>) => resp.data);
}

export function updateCounty(
  taxRollId: string,
  county: CountyItem
): Promise<CountyItem | AxiosError> {
  return getAxios()
    .patch<CountyItem | AxiosError>(`${BASE_SERVICE_PATH}/${taxRollId}`, county)
    .then((resp: AxiosResponse<CountyItem | AxiosError>) => resp.data);
}
