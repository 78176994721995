import { ROUTES } from '../constants/routes';
import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';
import {
  NotificationLog,
  NotificationLogQueryRequest,
  NotificationLogQueryResponse,
} from '../models/NotificationLog';

const BASE_SERVICE_PATH = ROUTES.NOTIFICATION_LOGS;

export function getNotificationLogs(
  request?: NotificationLogQueryRequest
): Promise<NotificationLogQueryResponse | AxiosError> {
  return getAxios()
    .post<NotificationLogQueryResponse | AxiosError>(`${BASE_SERVICE_PATH}/query`, request)
    .then((resp: AxiosResponse<NotificationLogQueryResponse | AxiosError>) => resp.data);
}

export function getNotificationLog(id: string): Promise<NotificationLog | AxiosError> {
  return getAxios()
    .get<NotificationLog | AxiosError>(`${BASE_SERVICE_PATH}/${id}`)
    .then((resp: AxiosResponse<NotificationLog | AxiosError>) => resp.data);
}
