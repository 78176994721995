import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Search from 'src/global/components/Search/Search';
import { Query } from 'src/global/models/Query';
import { PageSize } from 'src/global/components/Tables/GenericTable';
import { ROUTES } from 'src/global/constants/routes';
import { TaxRollRowResult, useTaxRoll } from '../../../global/contexts/taxRollContext';
import { commonStyle } from '../../../global/constants/common.style';
import { getUrlQuery, updateUrlQuery } from '../../../global/helpers/queryHelper';
import { GridCellParams } from '@mui/x-data-grid-pro';
import TaxRollTable from '../../../global/components/Tables/TaxRollTable';

interface TaxRollListProps {
  queryParams?: Query | null;
  showSearch?: boolean;
}
const TaxRollList: FunctionComponent<TaxRollListProps> = ({
  queryParams = null,
  showSearch = true,
}) => {
  const taxRollContext = useTaxRoll();
  const [taxRollResults, setResults] = useState<TaxRollRowResult>();
  const navigate = useNavigate();
  const [query, setQuery] = useState<Query>(() => getUrlQuery(undefined, undefined, '', {}));

  useEffect(() => {
    if (queryParams) {
      setQuery(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    setResults(taxRollContext.results);
  }, [taxRollContext.results]);

  useEffect(() => {
    taxRollContext.queryTaxRolls(query);
    updateUrlQuery(query, navigate);
  }, [query, navigate]);

  const handleSearchTermChange = useCallback((term: string) => {
    setQuery({ ...query, ...{ page: 0 }, term });
  }, []);

  return (
    <div>
      {showSearch && (
        <div style={commonStyle.search}>
          <Search onTermChange={handleSearchTermChange} term={query.term || ''} />
        </div>
      )}

      <TaxRollTable
        rows={taxRollResults?.rows}
        rowsPerPageOptions={[PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE]}
        onCellClick={(cellParams: GridCellParams) =>
          navigate(`${ROUTES.TAX_ROLLS}/${cellParams.row.id}`)
        }
        query={query}
        updateQuery={setQuery}
        rowCount={taxRollContext.results?.total || 1}
        // we are loading if the state is loading results
        // or if the results have loaded, there are no results AND there is a search term
        // if there isn't a search term, that would mean there was an issue with the api
        loading={taxRollContext.loading}
      />
    </div>
  );
};

export default TaxRollList;
