const {
  REACT_APP_API_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_SERVER_ENVIRONMENT,
  REACT_APP_MUI_PRO_LICENCE_KEY,
  REACT_APP_PAYMENT_PROVIDER_URL,
} = process.env;
export {
  REACT_APP_API_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_SERVER_ENVIRONMENT,
  REACT_APP_MUI_PRO_LICENCE_KEY,
  REACT_APP_PAYMENT_PROVIDER_URL,
};
