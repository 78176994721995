import React, { useEffect, useState } from 'react';
import { Query } from '../models/Query';
import { useAuth } from './authContext';
import { checkQuery } from '../helpers/queryHelper';
import { AxiosError } from 'axios';
import { DefaultContext } from '../models/Context';
import {
  StagedTaxBillDTO,
  StagedTaxBillRequest,
  StagedTaxBillResponse,
} from '../models/StagedTaxBill';
import { getStagedTaxBill, getStagedTaxBills } from '../services/stagedTaxBills';

interface StagedTaxBillContextType extends DefaultContext {
  queryStagedTaxBills: (query: Query) => void;
  results?: StagedTaxBillRowResult;
  query?: Query;
  getStagedTaxBillById: (id: string) => Promise<StagedTaxBillDTO | null | unknown>;
  taxBillLoading: boolean;
  taxBillLoaded: boolean;
}

let StagedTaxBillsContext = React.createContext<StagedTaxBillContextType>(null!);

export interface StagedTaxBillRowResult extends Omit<StagedTaxBillResponse, 'results'> {
  rows: StagedTaxBillDTO[];
  query: StagedTaxBillRequest;
  totalPages: number;
}

export function StagedTaxBillProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);
  let [taxBillLoading, setTaxBillLoading] = useState<boolean>(false);
  let [taxBillLoaded, setTaxBillLoaded] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [results, setResults] = useState<StagedTaxBillRowResult | undefined>();
  let [query, setQuery] = useState<Query | undefined>();
  let [lastViewedPage, setLastViewedPage] = useState<number>(0);
  const auth = useAuth();

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    const request: StagedTaxBillRequest = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getStagedTaxBills(request)
      .then((result) => {
        const jobsResult = result as StagedTaxBillResponse;

        setResults({
          total: jobsResult.total || 0,
          query: request,
          rows: jobsResult.results,
          page: request.page,
          pageSize: request.pageSize,
          totalPages: Math.round(jobsResult.total / jobsResult.pageSize) || 0,
        });
        setLastViewedPage(request.page - 1);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  const queryStagedTaxBills = (q: StagedTaxBillRequest) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query)
    ) {
      // prevent the table from showing the incorrect data page
      // after navigating to a different screen
      if (!q.page || q.page !== lastViewedPage || termChanged) {
        setQuery(q);
      }
    }
  };

  const getStagedTaxBillById = async (
    id: string
  ): Promise<StagedTaxBillDTO | AxiosError | unknown> => {
    setTaxBillLoading(true);
    setTaxBillLoaded(false);
    return new Promise(async (resolve, reject) => {
      getStagedTaxBill(id)
        .then((resp) => {
          setTaxBillLoading(false);
          setTaxBillLoaded(true);
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setLoading(false);
        });
    });
  };

  let value = {
    queryStagedTaxBills,
    getStagedTaxBillById,
    loading,
    loaded,
    hasError,
    errorMessage,
    results,
    taxBillLoading,
    taxBillLoaded,
  };

  return <StagedTaxBillsContext.Provider value={value}>{children}</StagedTaxBillsContext.Provider>;
}

export function useStagedTaxBills() {
  return React.useContext(StagedTaxBillsContext);
}
