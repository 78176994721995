import { FunctionComponent, useEffect, useState } from 'react';
import { commonStyle } from '../../global/constants/common.style';
import Crumbs from '../../global/components/Crumbs/Crumbs';
import { mainTabs } from '../../global/helpers/routingHelper';
import { Route, Routes, useParams } from 'react-router-dom';
import UserAgreementList from './UserAgreementList/UserAgreementList';
import { UserAgreementDto } from '../../global/models/UserAgreement';
import UserAgreementDetails from './UserAgreementDetails/UserAgreementDetails';

const UserAgreements: FunctionComponent = () => {
  const [selectedAgreement, setSelectedAgreement] =
    useState<UserAgreementDto | null | undefined>(null);
  const params = useParams();

  useEffect(() => {
    // if there isn't a product id in the params
    // and a product has been selected in state
    // then clear the state
    if (params['*'] === '') {
      setSelectedAgreement(null);
    }
  }, [params]);

  return (
    <div style={commonStyle.container}>
      <Crumbs
        links={[
          { name: mainTabs[9].name, path: mainTabs[9].routes, icon: mainTabs[9].icon },
          ...(!!selectedAgreement
            ? [
                {
                  name: `${selectedAgreement.version}`,
                  path: `${mainTabs[9].routes}${selectedAgreement.agreementId}`,
                },
              ]
            : []),
        ]}
      />
      <Routes>
        <Route path="/" element={<UserAgreementList />} />
        <Route
          path="/:agreementId"
          element={
            <UserAgreementDetails
              selectedAgreement={selectedAgreement}
              setSelectedAgreement={setSelectedAgreement}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default UserAgreements;
