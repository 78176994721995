import { FunctionComponent, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { TaxRollDto } from '../../global/models/TaxRolls';
import { commonStyle } from '../../global/constants/common.style';
import Crumbs from '../../global/components/Crumbs/Crumbs';
import { mainTabs } from '../../global/helpers/routingHelper';
import TaxRollList from './TaxRollList/TaxRollList';
import TaxRollDetails from './TaxRollDetails/TaxRollDetails';

const TaxRolls: FunctionComponent = () => {
  const [selectedItem, setSelectedItem] = useState<TaxRollDto | null | undefined>(null);
  const params = useParams();

  useEffect(() => {
    // if there isn't a tax roll id in the params
    // and a tax roll has been selected in state
    // then clear the state
    if (!params['*'] && !!selectedItem) {
      setSelectedItem(null);
    }
  }, [params]);

  return (
    <div style={commonStyle.container}>
      <Crumbs
        links={[
          { name: mainTabs[9].name, path: mainTabs[9].routes, icon: mainTabs[9].icon },
          ...(!!selectedItem
            ? [
                {
                  name: `${selectedItem.id} ${selectedItem.taxRollId}`,
                  path: `${mainTabs[9].routes}/${selectedItem.id}`,
                },
              ]
            : []),
        ]}
      />
      <Routes>
        <Route path="/" element={<TaxRollList />} />
        <Route
          path="/:id"
          element={
            <TaxRollDetails selectedTaxRoll={selectedItem} setSelectedTaxRoll={setSelectedItem} />
          }
        />
      </Routes>
    </div>
  );
};

export default TaxRolls;
