import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Table, { PageSize, TableProps } from 'src/global/components/Tables/Table';
import {
  getNumberGridOperators,
  getSingleSelectOperators,
  getStringGridOperators,
} from '../../helpers/filterOperators';
import { AvailableExportColumn } from './ExportColumnChooser';
import { downloadTextAsFile } from '../../helpers/downloadFile';
import { prepareCSVData } from '../../helpers/csvFile';
import { GridColDefWithExport } from '../../models/GridColDefWithExport';
import { useTaxRoll } from '../../contexts/taxRollContext';
import { GridRowsProp, GridValueFormatterParams, ValueOptions } from '@mui/x-data-grid-pro';
import toPrettyUSD from '../../helpers/toPrettyUSD';
import toPrettyDate from '../../helpers/toPrettyDate';
import convertBillType from 'src/global/helpers/convertBillType';

const TaxRollTable: FunctionComponent<TableProps> = ({
  rows,
  rowsPerPageOptions = [PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE],
  onCellClick,
  query,
  updateQuery,
  rowCount,
  loading = false,
}: TableProps) => {
  const taxRollContext = useTaxRoll();
  const [total, setTotal] = useState(0);
  const [columnsToExport, setColumnsToExport] = useState<AvailableExportColumn[]>([]);
  const [exporting, setExporting] = useState(false);
  const [exported, setExported] = useState(false);
  const [formattedRows, setFormattedRows] = useState<GridRowsProp>();

  useEffect(() => {
    setTotal(taxRollContext.results?.total || 0);
  }, [taxRollContext.results?.total]);

  useEffect(() => {
    if (taxRollContext.exportResults?.length && columnsToExport.length) {
      downloadTextAsFile(
        prepareCSVData(columnsToExport, taxRollContext.exportResults),
        'Tax Roll List.csv'
      );
      setColumnsToExport([]);
      setExporting(false);
      setExported(true);
    }
  }, [taxRollContext.exportResults, columnsToExport]);

  useEffect(() => {
    if (columnsToExport.length) {
      setExported(false);
      setExporting(true);
      taxRollContext.setColumnsToExport(columnsToExport);
      taxRollContext.queryTaxRollsForExport({
        ...query,
        ...{ pageSize: total },
      });
    }
  }, [columnsToExport]);

  useEffect(() => {
    let gridRowsProps: GridRowsProp = [];
    if (rows) {
      gridRowsProps = rows?.map((row) => {
        return {
          ...row,
          ...{
            dueDate: toPrettyDate(
              { year: row.dueYear, month: row.dueMonth, day: row.dueDay },
              'table'
            ),
          },
        };
      });
      setFormattedRows(gridRowsProps);
    }
  }, [rows]);

  const handleColumnsToExportChange = useCallback((cols: AvailableExportColumn[]) => {
    setColumnsToExport(cols);
  }, []);

  const columns: GridColDefWithExport[] = [
    {
      field: 'apn',
      type: 'string',
      filterOperators: getStringGridOperators(),
      headerName: 'APN',
      align: 'left',
      exportable: true,
    },
    {
      field: 'billNumber',
      headerName: 'Bill Number',
      type: 'string',
      filterOperators: getStringGridOperators(),
      align: 'left',
      exportable: true,
    },
    {
      field: 'taxRollId',
      headerName: 'Tax Roll Id',
      type: 'string',
      filterOperators: getStringGridOperators(),
      align: 'left',
      exportable: true,
    },
    {
      field: 'taxYear',
      headerName: 'Tax Year',
      type: 'number',
      filterOperators: getNumberGridOperators(),
      align: 'center',
      exportable: true,
      valueFormatter: (params: GridValueFormatterParams): string | undefined => {
        const { value } = params;
        return value.toString();
      },
    },
    {
      field: 'installmentNumber',
      headerName: 'Installment Number',
      type: 'number',
      filterOperators: getNumberGridOperators(),
      align: 'left',
      exportable: true,
    },
    {
      field: 'amount',
      type: 'number',
      filterOperators: getNumberGridOperators(),
      headerName: 'Amount',
      align: 'center',
      exportable: true,
      valueFormatter: (params: GridValueFormatterParams): string | undefined => {
        const { value } = params;
        return toPrettyUSD(value);
      },
    },
    {
      field: 'billType',
      headerName: 'Bill Type',
      flex: 125,
      align: 'left',
      exportable: true,
      type: 'singleSelect',
      filterOperators: getSingleSelectOperators(),
      valueFormatter: (params: GridValueFormatterParams): string | undefined => {
        const { value } = params;
        return convertBillType(value);
      },
      getOptionLabel: (p: ValueOptions) => {
        return (
          p as {
            value: any;
            label: string;
          }
        ).label;
      },
      valueOptions: [
        {
          value: 0,
          label: 'REGULAR',
        },
        {
          value: 1,
          label: 'REVISED',
        },
        {
          value: 2,
          label: 'SUPPLEMENTAL',
        },
        {
          value: -1,
          label: 'UNKNOWN',
        },
      ],
      filterable: true,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      flex: 100,
      align: 'left' as 'left',
      hideSortIcons: true,
      sortable: false,
      filterable: false,
    },
    {
      field: 'isPaid',
      headerName: 'Is Paid',
      type: 'boolean',
      flex: 100,
      align: 'center',
      exportable: true,
    },
    {
      field: 'isPrimary',
      headerName: 'Primary',
      type: 'boolean',
      flex: 100,
      align: 'center',
      exportable: true,
    },
    {
      field: 'isActive',
      headerName: 'Is Active',
      type: 'boolean',
      flex: 100,
      align: 'center',
      exportable: true,
    },
  ];

  return (
    <Table
      rows={formattedRows}
      columns={columns}
      columnsToExport={columns as AvailableExportColumn[]}
      rowsPerPageOptions={rowsPerPageOptions}
      onCellClick={onCellClick}
      filterMode={'server'}
      hiddenColumns={['mailingAddress', 'consumerVersion']}
      query={query}
      updateQuery={updateQuery}
      rowCount={rowCount}
      loading={loading}
      exportData={handleColumnsToExportChange}
      exporting={exporting || false}
      exported={exported}
    />
  );
};

export default TaxRollTable;
