import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
  DataGridPro,
  GridRowsProp,
  GridColDef,
  GridSortModel,
  GridCellParams,
  GridValueFormatterParams,
  GridRowParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridFilterModel,
  GridFeatureMode,
  GridLogicOperator,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { Query } from 'src/global/models/Query';
import './TableStyles.css';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import ServerErrorIcon from '../icons/ServerErrorIcon';
import NoSearchResultsIcon from '../icons/NoSearchResultsIcon';
import { prepareFilter, prepareQuery } from '../../helpers/filterOperators';
import { ButtonProps } from '@mui/material/Button/Button';
import { Box, Button, createSvgIcon } from '@mui/material';
import ExportColumnChooser, { AvailableExportColumn } from './ExportColumnChooser';

export enum PageSize {
  SMALL = 10,
  MEDIUM = 25,
  LARGE = 50,
  XL = 100,
}
export interface TableProps {
  rows?: GridRowsProp;
  rowsPerPageOptions: PageSize[];
  query: Query;
  rowCount: number;
  updateQuery: (query: Query) => void;
  onCellClick?: (params: GridCellParams) => void;
  loading?: boolean;
  filterVisible?: boolean;
  filterMode?: GridFeatureMode;
  hiddenColumns?: string[];
  gridSortModel?: GridSortModel | undefined;
  updateSort?: (model: GridSortModel) => void;
  columnsToExport?: AvailableExportColumn[];
  exportVisible?: boolean;
  exportData?: (columns: AvailableExportColumn[]) => void;
  exporting?: boolean;
  exported?: boolean;
}
export interface FullTableProps extends TableProps {
  columns: GridColDef[];
}

export function formatDate(params: GridValueFormatterParams): string {
  const { value } = params;
  if (value) {
    if (typeof value === 'number') {
      return DateTime.fromMillis(value * 1000).toLocaleString({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    } else if (typeof value === 'string') {
      return DateTime.fromISO(value).toLocaleString({
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    }
    return DateTime.fromJSDate(value).toLocaleString({
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }
  return '-';
}

const styles = {
  icon: {
    margin: '0 auto',
    display: 'flex',
  },
  noResultsContainer: {
    margin: '8rem auto',
    display: 'flex',
    flexDirection: 'column' as 'column',
    maxWidth: '480px',
    height: '300px',
  },
  searchTerm: {
    color: '#6200EE',
    fontFamily: 'Roboto',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    textAlign: 'center' as 'center',
    overflowWrap: 'break-word' as 'break-word',
  },
  message: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '24px',
    textAlign: 'center' as 'center',
  },
  serverErrorContainer: {
    display: 'flex',
    justifyContent: 'center' as 'center',
    padding: '16px 0',
  },
};

const Table: FunctionComponent<FullTableProps> = (props: FullTableProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string | number>('');
  const [colsToExport, setColsToExport] = useState<string>('');
  const {
    rows,
    columns,
    rowsPerPageOptions,
    rowCount,
    query,
    updateQuery,
    onCellClick,
    loading = false,
    filterVisible = true,
    filterMode = 'client',
    hiddenColumns = [],
    gridSortModel = undefined,
    updateSort,
    columnsToExport = [],
    exportVisible = true,
    exportData,
    exporting,
    exported,
  } = props;
  // instead of ensuring that all rows from each request have a unique id at response time,
  // we will generate a unique id for each row using the row index.
  const rowsWithIds: GridRowsProp | undefined = rows?.map((row: any, index: any) => ({
    ...row,
    id: row.id || row.Id || index,
  }));

  const columnsToHide = useCallback((items: string[]) => {
    const cols = {};
    items.forEach((i) => {
      Object.assign(cols, { [i]: false });
    });
    return cols;
  }, []);

  const handlePopoverOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const field = event.currentTarget.dataset.field!;
      const id = parseInt(event.currentTarget.parentElement!.dataset.id! || '1');
      if (!rowsWithIds) {
        return;
      }
      const row = rowsWithIds.find((r: any) => r.id === id)!;
      let val = row && row[field];
      if (field === 'createdAt' || field === 'date' || field === 'orderDate') {
        if (typeof val === 'number') {
          val = DateTime.fromMillis(val * 1000).toLocaleString({
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
        } else if (typeof val === 'string') {
          val = DateTime.fromISO(val).toLocaleString({
            year: 'numeric',
            month: 'short',
            day: 'numeric',
          });
        }
      }
      if ((typeof val === 'string' || typeof val === 'number') && !!val) {
        setValue(val);
        setAnchorEl(event.currentTarget);
      }
    },
    [setValue, setAnchorEl, rowsWithIds]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const setColumnsToExport = (cols: AvailableExportColumn[]) => {
    const exportable = cols
      .filter((a) => a.exportable)
      .map((a) => a.field)
      .join(',');
    setColsToExport(exportable);
  };

  const [columnExportChooserOpen, setColumnChooserOpen] = useState(false);

  const handleColumnChooserOpen = useCallback(() => {
    setColumnsToExport(columnsToExport);
    setColumnChooserOpen(true);
  }, [columnExportChooserOpen]);

  const handleCloseColumnChooser = useCallback(() => {
    setColumnChooserOpen(false);
  }, [columnExportChooserOpen]);

  const handleExport = useCallback(
    (cols: AvailableExportColumn[]) => {
      if (exportData) {
        exportData(cols);
      }
    },
    [exportData]
  );

  const handleChangeColumnsToExport = useCallback(
    (cols: AvailableExportColumn[]) => {
      if (columnsToExport) {
        setColumnsToExport(cols);
      }
    },
    [columnsToExport]
  );

  useEffect(() => {
    if (exported) {
      setColumnChooserOpen(false);
    }
  }, [exported]);

  const [paginationModel, setPaginationModel] = useState({
    page: query.page || 0,
    pageSize: query.pageSize || PageSize.SMALL,
  });

  const handlePaginationModelChange = useCallback(
    (model) => {
      if (paginationModel.pageSize !== model.pageSize) {
        model.page = 0;
      }
      setPaginationModel(model);
    },
    [paginationModel]
  );

  const [filterModel, setFilterModel] = useState<GridFilterModel>(() => {
    if (query.query) {
      return { items: prepareFilter(query.query) };
    }
    return { items: [] };
  });

  const handleFilterModelChange = useCallback(
    (model: GridFilterModel) => {
      setFilterModel(model);
      setPaginationModel({
        page: 0,
        pageSize: paginationModel.pageSize,
      });
    },
    [filterModel, paginationModel]
  );

  const [sortModel, setSortModel] = useState(gridSortModel);

  const handleSortModelChange = useCallback(
    (model: GridSortModel) => {
      setSortModel(model);

      if (updateSort) {
        updateSort(model);
      }
    },
    [setSortModel, updateSort]
  );

  useEffect(() => {
    if (query.term) {
      if (filterModel && filterModel?.items.length) {
        setFilterModel({ items: [] });
      }
    }
  }, [query.term]);

  useEffect(() => {
    if (query.colsToExport) {
      setColsToExport(query.colsToExport);

      const selectedCols = query.colsToExport?.split(',');

      columnsToExport.map((col) => {
        col.exportable = selectedCols.includes(col.field);
        return col;
      });
    }
  }, [query.colsToExport]);

  useEffect(() => {
    const { sortColumn, sortDirection } = query;
    if (sortColumn && sortDirection) {
      const model: GridSortModel = [
        {
          field: sortColumn,
          sort: sortDirection === 'ASC' ? 'asc' : sortDirection === 'DESC' ? 'desc' : null,
        },
      ];

      setTimeout(() => setSortModel(model), 100);
    }
  }, [query.sortColumn, query.sortDirection]);

  useEffect(() => {
    // const pageSize = query.pageSize;
    let term = query.term || '';
    let newQuery: { [key: string]: any[] } = {};

    // && filterMode === 'server'
    if (filterModel) {
      const filter: { [key: string]: any[] } = {};
      filterModel.items.forEach((m: any) => {
        if (!filter[m.field]) {
          filter[m.field] = [];
        }
        filter[m.field].push(m);
      });
      if (columns.length) {
        newQuery = prepareQuery(filter, columns);
        if (Object.keys(newQuery).length) {
          term = '';
        }
      }
    }

    if (columns.length) {
      if (sortModel) {
        if (sortModel.length > 0) {
          updateQuery({
            query: newQuery,
            term,
            ...paginationModel,
            sortDirection: sortModel[0].sort?.toUpperCase() as 'ASC' | 'DESC',
            sortColumn: sortModel[0].field,
            colsToExport,
          } as Query);
        } else {
          updateQuery({
            ...query,
            term,
            ...paginationModel,
            sortDirection: '',
            sortColumn: '',
            colsToExport,
          });
        }
      } else {
        updateQuery({ ...query, term, ...paginationModel, ...{ query: newQuery }, colsToExport });
      }
    }
  }, [sortModel, filterModel, paginationModel, colsToExport]);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
    columnsToHide(hiddenColumns)
  );
  const handleColumnVisibilityModelChange = useCallback(
    (model: GridColumnVisibilityModel) => setColumnVisibilityModel(model),
    [setColumnVisibilityModel]
  );

  const open = Boolean(anchorEl);

  function CustomToolbar() {
    const ExportIcon = createSvgIcon(
      <path d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z" />,
      'SaveAlt'
    );

    const buttonBaseProps: ButtonProps = {
      color: 'primary',
      size: 'small',
      startIcon: <ExportIcon />,
    };

    return (
      <GridToolbarContainer
        sx={{
          justifyContent: 'flex-end',
        }}
      >
        {exportVisible && (
          <Button {...buttonBaseProps} onClick={handleColumnChooserOpen}>
            Export
          </Button>
        )}

        {filterVisible && (
          <GridToolbarFilterButton
            componentsProps={{
              button: {
                color: 'warning',
                children: {
                  label: '',
                },
              },
            }}
          />
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <Paper sx={{ width: '100%', maxWidth: '1110px', overflow: 'hidden' }}>
      <DataGridPro
        loading={loading}
        rows={rowsWithIds || []}
        columns={columns}
        paginationMode="server"
        sortingMode="server"
        filterMode={filterMode}
        autoHeight
        rowCount={rowCount || 100}
        pagination
        pageSizeOptions={rowsPerPageOptions}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        sortModel={sortModel}
        onSortModelChange={handleSortModelChange}
        filterModel={filterModel}
        onFilterModelChange={handleFilterModelChange}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
        onCellClick={onCellClick}
        getCellClassName={(params: GridCellParams): string => {
          if (
            params.field === 'firstname' ||
            params.field === 'lastname' ||
            params.field === 'apn'
          ) {
            return 'link';
          } else if (
            params.field === 'receiptId' &&
            (params.value === 'N/A' || params.value === 'Canceled')
          ) {
            return 'error-cell';
          } else {
            return 'default-cell';
          }
        }}
        getRowClassName={(params: GridRowParams): string => {
          if (params.row.status === 'FAILURE') {
            return 'error-row';
          } else if (params.row.status === 'CANCELED') {
            return 'cancel-row';
          }
          return '';
        }}
        sx={{
          maxWidth: '1110px',
          '& .MuiDataGrid-overlayWrapper': {
            background: 'red',
            minHeight: '324px',
          },
        }}
        componentsProps={{
          cell: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          },
        }}
        slots={{
          toolbar: filterVisible || exportVisible ? CustomToolbar : null,
          noResultsOverlay: (): React.ReactElement => (
            <div style={styles.noResultsContainer}>
              <NoSearchResultsIcon height={'144px'} style={styles.icon} />
              {query.term ? (
                <Box>
                  <p style={styles.searchTerm}>{`${query.term} is a legit search...`}</p>
                  <p style={styles.message}>
                    However, we don't have the info that you're looking for. Maybe give it another
                    go and alter your search a bit to see if you find what you're looking for?
                  </p>
                </Box>
              ) : (
                <p style={styles.message}>No results found...</p>
              )}
            </div>
          ),
          noRowsOverlay: () => (
            <div style={styles.noResultsContainer}>
              <ServerErrorIcon width={'480px'} />
              <p style={styles.searchTerm}>Something is up with the server.</p>
              <p style={styles.message}>
                If this is urgent, send Aaron or Clint a message on Slack and they'll look into what
                might be causing the issue.
              </p>
            </div>
          ),
        }}
        slotProps={{
          filterPanel: {
            logicOperators:
              filterMode === 'server'
                ? [GridLogicOperator.And]
                : [GridLogicOperator.And, GridLogicOperator.Or],
            filterFormProps: {
              logicOperatorInputProps: {
                sx: {
                  display: filterMode === 'server' ? 'none' : 'flex',
                },
              },
            },
          },
        }}
        // error={hasError || null}
        isRowSelectable={() => false}
      />
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{value}</Typography>
      </Popover>
      <ExportColumnChooser
        isOpen={columnExportChooserOpen}
        availableColumns={columnsToExport.map((a) => {
          a.exportable = a.exportable || false;
          return a;
        })}
        onClose={handleCloseColumnChooser}
        onExport={handleExport}
        onColumnsChange={handleChangeColumnsToExport}
        exporting={exporting || false}
      ></ExportColumnChooser>
    </Paper>
  );
};

export default Table;
