import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../models/Context';
import { Query } from '../models/Query';
import { PaymentContractQueryResults } from '../models/PaymentContractQueryResults';
import { PaymentContractsRequest, PaymentContractsResult } from '../models/PaymentContract';
import { getAllPaymentContracts } from '../services/paymentContracts';
import { AxiosError } from 'axios';
import { useAuth } from './authContext';
import { mapPaymentContracts } from '../helpers/mapPaymentContracts';
import getPageTotal from '../helpers/totalPage';
import { Product } from '../models/Product';
import { getProducts } from '../services/products';
import { useProfile } from './profileContext';

interface DashboardContextType extends DefaultContext {
  queryPaymentContracts: (q: Query) => void;
  results?: PaymentContractQueryResults;
  counties: Product[];
  loading: boolean;
}

let DashboardContext = createContext<DashboardContextType>(null!);

export function DashboardProvider({ children }: { children: ReactNode }) {
  const auth = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);

  const profileContxt = useProfile();

  const [countiesLoaded, setCountiesLoaded] = useState<boolean>(false);

  const [hasError, setHasError] = useState<boolean>(false);
  const [query, setQuery] = useState<Query | null>(null);
  const [results, setResults] = useState<PaymentContractQueryResults | undefined>();

  const [counties, setCounties] = useState<Product[]>([]);

  useEffect(() => {
    if (profileContxt.profile) {
      getProducts()
        .then((countyList) => {
          setCounties(countyList as Product[]);
          setCountiesLoaded(true);
        })
        .catch((err: AxiosError) => {
          setCountiesLoaded(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        });
    }
  }, [profileContxt.profile]);

  useEffect(() => {
    if (!query) {
      return;
    }

    setLoaded(false);

    const q = { ...query.query };

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: query.page + 1,
      pageSize: 1000, // query.pageSize,
      query: q,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getAllPaymentContracts(request)
      .then((result) => {
        const data: PaymentContractsResult = result as PaymentContractsResult;

        setResults({
          total: data.total,
          query: query,
          rows: mapPaymentContracts(data.results),
          // MUI's data grid starts with page 0
          // the server starts with page 1
          page: data.page - 1,
          pageSize: data.pageSize || 10,
          totalPages: getPageTotal(data.results?.length || 0, data.pageSize || 10),
        });

        setLoaded(true);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        // setErrorMessage(err.message);
        setLoading(false);
        setLoaded(false);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [query]);

  const checkQuery = (q: Query): boolean => {
    if (q.query) {
      if (Object.keys(q.query).length === 0) {
        return Object.keys(query?.query || {}).length !== 0;
      }
      const qVal = JSON.stringify(q.query);
      const queryVal = JSON.stringify(query?.query);
      return qVal !== queryVal;
    }
    return false;
  };

  const queryPaymentContracts = (q: Query) => {
    setLoading(true);
    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      checkQuery(q)
    ) {
      // prevent the table from showing the incorrect data page
      // after navigating to a different screen
      // if (Math.abs(q.page - lastViewedPage) <= 1) {
      setQuery(q);
      // }
    } else {
      setLoading(false);
    }
  };

  const value = {
    loading,
    loaded,
    hasError,
    queryPaymentContracts,
    results,

    counties,
    countiesLoaded,
  };

  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>;
}

export function useDashboard() {
  return useContext(DashboardContext);
}
