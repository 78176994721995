import { Query } from '../models/Query';
import { UserAgreementDto, UserAgreementQueryResponse } from '../models/UserAgreement';
import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';

const BASE_SERVICE_PATH = 'user-agreement/';

export function getUserAgreements(
  request: Query
): Promise<UserAgreementQueryResponse | AxiosError> {
  return getAxios()
    .post<UserAgreementQueryResponse>(`${BASE_SERVICE_PATH}query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getAgreement(agreementId: string): Promise<UserAgreementDto | AxiosError> {
  return getAxios()
    .get<UserAgreementDto | AxiosError>(`${BASE_SERVICE_PATH}${agreementId}`)
    .then((resp: AxiosResponse<UserAgreementDto | AxiosError>) => resp.data);
}

export function checkAgreementPresented(agreementId: string): Promise<boolean> {
  return getAxios()
    .get<boolean>(`${BASE_SERVICE_PATH}${agreementId}/presented`)
    .then((resp: AxiosResponse<boolean>) => {
      return resp.data;
    });
}

export function createUserAgreement(
  agreement: UserAgreementDto
): Promise<UserAgreementDto | AxiosError> {
  return getAxios()
    .post<UserAgreementDto | AxiosError>(`${BASE_SERVICE_PATH}`, agreement)
    .then((resp: AxiosResponse<UserAgreementDto | AxiosError>) => resp.data);
}

export function updateUserAgreement(
  agreementId: string,
  agreement: UserAgreementDto
): Promise<UserAgreementDto | AxiosError> {
  return getAxios()
    .patch<UserAgreementDto | AxiosError>(`${BASE_SERVICE_PATH}${agreementId}`, agreement)
    .then((resp: AxiosResponse<UserAgreementDto | AxiosError>) => resp.data);
}

export function deleteUserAgreement(agreementId: string): Promise<void | AxiosError> {
  return getAxios()
    .delete<void | AxiosError>(`${BASE_SERVICE_PATH}${agreementId}`)
    .then((resp: AxiosResponse<void | AxiosError>) => resp.data);
}
