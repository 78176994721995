import { TaxRollDto, TaxRollQueryRequest, TaxRollQueryResponse } from '../models/TaxRolls';
import { AxiosError } from 'axios';
import { getAxios } from './espAxios';
const BASE_SERVICE_PATH = '';

export function getTaxRolls(
  request: TaxRollQueryRequest
): Promise<TaxRollQueryResponse | AxiosError> {
  return getAxios()
    .post<TaxRollQueryResponse | AxiosError>(`${BASE_SERVICE_PATH}/tax-rolls/query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getTaxRoll(id: string): Promise<TaxRollDto | AxiosError> {
  return getAxios()
    .get<TaxRollDto | AxiosError>(`${BASE_SERVICE_PATH}/tax-rolls/${id}`)
    .then((resp) => {
      return resp.data;
    });
}
