import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { FunctionComponent } from 'react';

const LogoIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M13.828675,9.6 L13.2191167,11.2 L19.009921,8 L15.0477917,4.48 L14.4382334,6.4 L3.7213537,6.4 L4.61257564,3.80572125 C5.39705815,1.52208145 7.46102338,0 9.77319062,0 L18.5139749,0 C21.5438229,0 24,2.57883984 24,5.76 C24,6.42628397 23.8898971,7.08751621 23.6745899,7.71427875 L21.3778207,14.4 L10.171325,14.4 L10.7808833,12.8 L4.99007903,16 L8.95220827,19.52 L9.56176662,17.6 L20.2784822,17.6 L19.3874244,20.1942787 C18.6029418,22.4779186 16.5389766,24 14.2268094,24 L5.48602511,24 C2.4561771,24 0,21.4211602 0,18.24 C0,17.573716 0.110102853,16.9124838 0.325410124,16.2857213 L2.62201522,9.6 L13.828675,9.6 Z"
    />
  </SvgIcon>
);

export default LogoIcon;
