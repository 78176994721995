import { SvgIcon, SvgIconProps } from '@mui/material';
import { FunctionComponent } from 'react';

const PendingIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#222"
        fillRule="nonzero"
        d="M12,2 C6.48421053,2 2,6.48421053 2,12 C2,17.5157895 6.48421053,22 12,22 C17.5157895,22 22,17.5157895 22,12 C22,6.48421053 17.5157895,2 12,2 Z M3.81052632,12 C3.81052632,10.0631579 4.48421053,8.29473684 5.6,6.88421053 L6.88421053,5.62105263 C8.27368421,4.50526316 10.0631579,3.83157895 11.9789474,3.83157895 C16.4842105,3.83157895 20.1684211,7.49473684 20.1684211,12.0210526 C20.1684211,13.9578947 19.4947368,15.7263158 18.3789474,17.1157895 L17.0947368,18.4 C15.7052632,19.5157895 13.9157895,20.1894737 12,20.1894737 C7.49473684,20.1894737 3.81052632,16.5052632 3.81052632,12 Z"
      />
      <path
        fill="#222"
        d="M12,6 C12.5522847,6 13,6.44771525 13,7 L13,11.32 L17.072196,12.8021209 C17.5911739,12.9910134 17.858761,13.5648557 17.6698685,14.0838336 C17.480976,14.6028115 16.9071336,14.8703986 16.3881557,14.6815061 L11.6896926,12.9714054 C11.5392067,12.916633 11.4098578,12.8294939 11.306646,12.720711 C11.1178552,12.5391907 11,12.2833586 11,12 L11,7 C11,6.44771525 11.4477153,6 12,6 Z"
      />
    </g>
  </SvgIcon>
);

export default PendingIcon;
