export enum ROUTES {
  HOME = '/',
  PROFILE = '/profile',
  CUSTOMERS = '/customers',
  AUTH = '/auth',
  PAYMENT_CONTRACTS = '/payment-contracts',
  COUNTIES = '/counties',
  PRODUCTS = '/products',
  ACTIVITIES = '/activities',
  DASHBOARD = '/dashboard',
  CORTACS = '/cortacs',
  CORTAC_SUBMISSIONS = '/cortac-submissions',
  ORDERS = '/orders',
  NOTIFICATIONS = '/notifications',
  NOTIFICATION_TYPES = '/notification-types',
  NOTIFICATION_LOGS = '/notification-logs',
  TAX_ROLLS = '/tax-rolls',
  USER_AGREEMENTS = '/user-agreements',
  TAXROLL_JOBS = '/import-tax-roll-jobs',
  STAGED_TAXBILLS = '/staged-tax-bills',
  RETRY_PAYMENTS = '/retry-payments',
}
