import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './global/contexts/authContext';
import { ProfileProvider } from './global/contexts/profileContext';
import { BrowserRouter } from 'react-router-dom';
import { CustomersProvider } from './global/contexts/customersContext';
import { SnackbarMessageProvider } from './global/contexts/snackbarMessageContext';
import { PaymentContractsProvider } from './global/contexts/paymentContractsContext';
import { AddressProvider } from './global/contexts/addressesContext';
import { CountiesProvider } from './global/contexts/countiesContext';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { REACT_APP_MUI_PRO_LICENCE_KEY } from './global/constants/environment-variables';
import { ActivityLogProvider } from './global/contexts/activityLogContext';
import { ProductsProvider } from './global/contexts/productsContext';
import { DashboardProvider } from './global/contexts/dashboardContext';
import { ProductPaymentContractsProvider } from './global/contexts/productPaymentContractsContext';
import { OrderProvider } from './global/contexts/orderContext';
import { CortacsProvider } from './global/contexts/cortacsContext';
import { NotificationsProvider } from './global/contexts/notificationsContext';
import { NotificationTypesProvider } from './global/contexts/notificationTypesContext';
import { NotificationLogsProvider } from './global/contexts/notificationLogsContext';
import { TaxRollProvider } from './global/contexts/taxRollContext';
import { UserAgreementProvider } from './global/contexts/userAgreementContext';
import { ImportJobsProvider } from './global/contexts/importJobsContext';
import { StagedTaxBillProvider } from './global/contexts/stagedTaxBillsContext';

LicenseInfo.setLicenseKey(`${REACT_APP_MUI_PRO_LICENCE_KEY}`);

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <AuthProvider>
        <ProfileProvider>
          <BrowserRouter>
            <CustomersProvider>
              <PaymentContractsProvider>
                <ActivityLogProvider>
                  <AddressProvider>
                    <SnackbarMessageProvider>
                      <CountiesProvider>
                        <ProductsProvider>
                          <ProductPaymentContractsProvider>
                            <DashboardProvider>
                              <CortacsProvider>
                                <OrderProvider>
                                  <NotificationsProvider>
                                    <NotificationTypesProvider>
                                      <NotificationLogsProvider>
                                        <UserAgreementProvider>
                                          <TaxRollProvider>
                                            <ImportJobsProvider>
                                              <StagedTaxBillProvider>
                                                <App />
                                              </StagedTaxBillProvider>
                                            </ImportJobsProvider>
                                          </TaxRollProvider>
                                        </UserAgreementProvider>
                                      </NotificationLogsProvider>
                                    </NotificationTypesProvider>
                                  </NotificationsProvider>
                                </OrderProvider>
                              </CortacsProvider>
                            </DashboardProvider>
                          </ProductPaymentContractsProvider>
                        </ProductsProvider>
                      </CountiesProvider>
                    </SnackbarMessageProvider>
                  </AddressProvider>
                </ActivityLogProvider>
              </PaymentContractsProvider>
            </CustomersProvider>
          </BrowserRouter>
        </ProfileProvider>
      </AuthProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
