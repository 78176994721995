import { FunctionComponent, useEffect } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/global/contexts/authContext';

const Redirect: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code') || '';
  const scope = searchParams.get('scope') || '';
  const authuser = searchParams.get('authuser') || '';
  const hd = searchParams.get('hd') || '';
  const prompt = searchParams.get('prompt') || '';
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.loading) return;

    if (auth.isAuthenticated) {
      const authLastUrl = auth.getAuthLastUrl();
      auth.clearAuthLastUrl();
      navigate(authLastUrl);
    } else if (!auth.hasAuthenticated) {
      auth.authenticate(code, scope, authuser, hd, prompt);
    }
  }, [auth, authuser, code, hd, location.pathname, navigate, prompt, scope]);
  return <></>;
};

export default Redirect;
