import { Address } from './Address';
import { Query } from './Query';
import { BaseQueryResponse } from './QueryResults';

export enum PaymentContractStatus {
  'PENDING' = 'PENDING',
  'ACTIVE' = 'ACTIVE',
  'CANCELED' = 'CANCELED',
  'FAILURE' = 'FAILURE',
  'IN_PROCESS' = 'IN_PROCESS',
}

export interface PaymentContract {
  id: string;
  apn: string;
  billNumber: string;
  taxRollId: string;
  taxYear: string | number;
  status: PaymentContractStatus;
  orderId: string;
  originalOrderId?: string;
  customerId: string;
  voucherId?: string;
  hasPaymentPlan: boolean;
  estimateOptIn: boolean;
  originalVoucherId?: string | null;
  installments: Installment[];
  installmentContracts: InstallmentContract[];
  schedule: Payment[];
  receiptId: string;
  billType: 0 | 1 | 2 | 3;
  createdAt: string;
  amount: number;
  amountPaid: number;
  description: string;
  mailingAddress?: string | null;
  isEstimated?: boolean | string;
  amountFirstInstallment?: number;
  amountSecondInstallment?: number;
  amountPaidFirstInstallment?: number;
  amountPaidSecondInstallment?: number;
  installmentNumbers?: string;
  transactionRatePct: number;
  paymentMethodId: string;
  latestCanceledAt?: Date | null;
  disbursmentAmount: number;
  [key: string]: any;
}
export interface BillDate {
  day: number;
  month: number;
  year: number;
}
export interface InstallmentContract {
  voucherContractId?: string;
  contractId?: string;
  installmentNumber: number;
  isPaid: boolean;
  totalAmount?: number;
  amountPaid: number;
  status: 'ACTIVE' | 'CANCELED';
  estimateOptIn: boolean;
  dueDate: BillDate;
  batchPaymentTrackingId?: string | null;
  amount: number;
  canceledAt?: string;
}
export interface Payment {
  voucherInstallmentId?: string;
  dateKey: string;
  subTotal: number;
  total: number;
  transactionFee: number;
  transactionRatePct: number;
  isInitialPayment: boolean;
  orderId?: string | null;
  status: 'PENDING' | 'FAILURE' | 'SCHEDULED' | 'SUCCESS' | 'SETTLED' | 'VOIDED';
  message?: string | null;
  receiptId?: string | null;
  transactionId?: string | null;
  transactionStatus?: string | null;
  issue?: string | null;
  disbursmentAmount?: number | null;
  disbursmentDate?: string | null;
}

export interface Installment {
  apn: string;
  amount: number;
  billNumber: string;
  billType: number;
  isPaid: boolean;
  isUnderContract?: boolean;
  isMissedPrevPayDate?: boolean;
  isPastLastPayDate?: boolean;
  installmentNumber: number;
  hasPenalty: boolean;
  taxYear: string | number;
  dueDay: number;
  dueMonth: number;
  dueYear: number;
  dueDate: BillDate;
  lastPayDate: BillDate;
  status: string;
  payments?: number[];
  periods?: number;
  primary?: boolean;
}

export interface TablePaymentContracts extends PaymentContract {
  dueDate: string;
  taxesCollected: number;
  transactionFeeCollected: number;
  paymentType: string;
}

export interface PaymentContractsResult {
  page: number;
  pageSize: number;
  results: PaymentContract[];
  total: number;
}

export interface PaymentContractsRequest extends Query {}

export interface PaymentContractWithAddress extends PaymentContract {
  address: Address;
}

export interface CancelPaymentContractRequest {
  comment: string;
  actorId: string;
  orderId: string;
  installments?: number[];
}

export interface ProcessRecurringPaymentRequest {
  voucherInstallmentId: string;
  orderId: string;
  actorId: string;
}

export interface ProcessRecurringPaymentResponse {
  message: string;
  status: string;
  orderId?: string;
  customerId?: string;
}

export enum RetryPaymentStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
}

export enum AttemptStatus {
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export enum AttemptType {
  MANUAL = 'MANUAL',
  SCHEDULED = 'SCHEDULED',
}

export interface AttemptDto {
  voucherInstallmentId: string;
  status: RetryPaymentStatus;
  type: AttemptType;
  errorMessage: string;
  createdAt: Date;
}

export interface RetryPaymentDto {
  id: string;
  paymentContractOrderId: string;
  voucherInstallmentId: string;
  apn: string;
  billNumber: string;
  taxRollId: string;
  taxYear: number;
  paymentDate: string;
  nextRetryDate: string;
  nextPaymentDate: string;
  lastAttemptDate: string;
  status: RetryPaymentStatus;
  attempts: AttemptDto[];
  createdAt: string;
  updatedAt: string;
}

export interface RetryPaymentQueryRequest extends Query {
  pageSize: number;
}

export interface RetryPaymentQueryResponse extends BaseQueryResponse {
  results: RetryPaymentDto[];
}
