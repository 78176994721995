import { Query } from '../models/Query';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { PageSize } from '../components/Tables/GenericTable';

export function setSearch(query: Query): string {
  const basicData = {
    page: query.page.toString(),
    pageSize: query.pageSize.toString(),
    sortColumn: query.sortColumn || '',
    sortDirection: query.sortDirection || '',
  };

  if (query.term) {
    Object.assign(basicData, { term: query.term });
  }

  if (query.colsToExport) {
    Object.assign(basicData, { colsToExport: query.colsToExport });
  }

  let search = new URLSearchParams(basicData).toString();
  if (query.query && Object.keys(query.query).length) {
    search += `&query=${encodeURIComponent(JSON.stringify(query.query))}`;
  }

  return search;
}

export function updateUrlQuery(query: Query, navigate: NavigateFunction) {
  const search = setSearch(query);
  navigate({ pathname: location.pathname, search }, { replace: true });
}

export function getTermParam() {
  const searchQuery = new URLSearchParams(location.search);
  return searchQuery.get('term') || '';
}

export function getUrlQuery(
  defaultSortColumn?: string,
  defaultDirection?: string,
  defaultTerm?: string,
  query?: any
): Query {
  const searchQuery = new URLSearchParams(location.search);
  const page = searchQuery.get('page') || 0;
  const pageSize = searchQuery.get('pageSize') || PageSize.SMALL;
  const sortColumn = searchQuery.get('sortColumn') || defaultSortColumn;
  const sortDirection = searchQuery.get('sortDirection') || defaultDirection;
  const term = searchQuery.get('term') || defaultTerm;
  const colsToExport = searchQuery.get('colsToExport') || '';

  let search = searchQuery.get('query');
  if (search) {
    search = JSON.parse(decodeURIComponent(search));
  }

  const newQuery = {
    page: +page,
    pageSize: +pageSize,
  };

  if (sortColumn) {
    Object.assign(newQuery, { sortColumn });
  }

  if (sortDirection) {
    Object.assign(newQuery, { sortDirection });
  }

  if (term) {
    Object.assign(newQuery, { term });
  }

  if (query) {
    Object.assign(newQuery, { query });
  }

  if (search) {
    Object.assign(newQuery, { query: search });
  }

  if (colsToExport) {
    Object.assign(newQuery, { colsToExport });
  }
  // console.log('newQuery', newQuery);

  return newQuery;
}

export function checkQuery(q: Query, query?: Query) {
  if (q.query) {
    if (Object.keys(q.query).length === 0) {
      return Object.keys(query?.query || {}).length !== 0;
    }
    const qVal = JSON.stringify(q.query);
    const queryVal = JSON.stringify(query?.query);
    return qVal !== queryVal;
  }
  return false;
}
