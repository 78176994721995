import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { Query } from '../models/Query';
import { useAuth } from './authContext';
import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';
import { DefaultContext } from '../models/Context';
import { checkQuery } from '../helpers/queryHelper';
import { TaxRollDto, TaxRollQueryRequest, TaxRollQueryResponse } from '../models/TaxRolls';
import { getTaxRoll, getTaxRolls } from '../services/taxRolls';

interface TaxRollContextType extends DefaultContext {
  queryTaxRolls: (q: Query) => void;
  queryTaxRollsForExport: (q: Query) => void;
  results?: TaxRollRowResult;
  exportResults?: TaxRollDto[];
  query?: Query;
  getTaxRollById: (id: string) => Promise<TaxRollDto | null | unknown>;
  setColumnsToExport: (c: AvailableExportColumn[]) => void;
  columnsToExport: AvailableExportColumn[];
  taxLoading: boolean;
  taxLoaded: boolean;
  refreshList: (value: boolean) => void;
}

let TaxRollContext = React.createContext<TaxRollContextType>(null!);

export interface TaxRollRowResult extends Omit<TaxRollQueryResponse, 'results'> {
  rows: TaxRollDto[];
  query: TaxRollQueryRequest;
  totalPages: number;
}

export function TaxRollProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);
  let [taxLoading, setTaxLoading] = useState<boolean>(false);
  let [taxLoaded, setTaxLoaded] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [results, setResults] = useState<TaxRollRowResult | undefined>();
  let [exportResults, setExportResults] = useState<TaxRollDto[] | undefined>([]);
  let [query, setQuery] = useState<Query | undefined>();
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);
  const [columnsToExport, setColumns] = useState<AvailableExportColumn[]>([]);
  const [exportQuery, setExportQuery] = useState<Query | null>(null);
  const auth = useAuth();

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    const request: TaxRollQueryRequest = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query || {},
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getTaxRolls(request)
      .then((result) => {
        const taxRollResponse = result as TaxRollQueryResponse;
        const taxRolls = taxRollResponse.results;

        setResults({
          total: taxRollResponse.total || 0,
          query: request,
          rows: taxRolls,
          page: request.page,
          pageSize: request.pageSize,
          totalPages: Math.round(taxRollResponse.total / taxRollResponse.pageSize) || 0,
        });
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  useEffect(() => {
    if (!exportQuery) {
      return;
    }

    const request: TaxRollQueryRequest = {
      page: 1,
      pageSize: exportQuery.pageSize,
      query: exportQuery.query || {},
      ...(exportQuery.sortColumn && { sortColumn: exportQuery.sortColumn }),
      ...(exportQuery.sortDirection && { sortDirection: exportQuery.sortDirection }),
    };

    getTaxRolls(request)
      .then((result) => {
        const taxRollResult = result as TaxRollQueryResponse;
        const taxRolls = taxRollResult.results;

        setExportResults(taxRolls);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [exportQuery]);

  const queryTaxRolls = (q: TaxRollQueryRequest) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query) ||
      refreshQuery
    ) {
      setQuery(q);
    }
  };

  const queryTaxRollsForExport = (q: Query) => {
    setExportQuery(q);
  };
  const setColumnsToExport = (c: AvailableExportColumn[]) => {
    setColumns(c);
  };

  const getTaxRollById = async (id: string): Promise<TaxRollDto | AxiosError | unknown> => {
    setTaxLoading(true);
    setTaxLoaded(false);

    return new Promise(async (resolve, reject) => {
      getTaxRoll(id)
        .then((resp) => {
          setTaxLoading(false);
          setTaxLoaded(true);
          resolve(resp);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
          setLoading(false);
        });
    });
  };

  const refreshList = (value: boolean): void => {
    setRefreshQuery(value);

    if (value) {
      const newQuery = {
        ...query,
        ...{
          query: {},
        },
      } as Query;
      setQuery(newQuery);
    }
  };

  let value = {
    queryTaxRolls,
    queryTaxRollsForExport,
    getTaxRollById,
    loading,
    loaded,
    hasError,
    errorMessage,
    results,
    exportResults,
    query,
    setColumnsToExport,
    columnsToExport,
    taxLoading,
    taxLoaded,
    refreshList,
  };

  return <TaxRollContext.Provider value={value}>{children}</TaxRollContext.Provider>;
}

export function useTaxRoll() {
  return React.useContext(TaxRollContext);
}
