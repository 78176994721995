import '@fontsource/roboto';

import { FunctionComponent, useEffect, useState, lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Navigate, useLocation, useNavigate } from 'react-router';
import Header from 'src/global/components/Header/Header';
import { ROUTES } from './global/constants/routes';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// import AuthContext from './global/contexts/AuthContext';

import { Helmet } from 'react-helmet';
import Auth from './pages/auth/Auth';
import ProtectedRoute from './global/components/ProtectedRoute';
import { useAuth } from './global/contexts/authContext';
import { useProfile } from './global/contexts/profileContext';
import Loading from './global/components/Loading';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  Box,
  CSSObject,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Theme,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import { mainTabs } from './global/helpers/routingHelper';
import TaxRolls from './pages/taxRolls/TaxRolls';
import UserAgreements from './pages/userAgreements/UserAgreements';
import RetryPayments from './pages/retryPayments/RetryPayments';

// Pages
// const Profile = lazy(() => import('./pages/profile/Profile'));
const Products = lazy(() => import('./pages/products/Products'));
const Counties = lazy(() => import('./pages/counties/Counties'));
const Activities = lazy(() => import('./pages/activities/Activities'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Customers = lazy(() => import('./pages/customers/Customers'));
const PaymentContracts = lazy(() => import('./pages/paymentContracts/PaymentContracts'));
const Orders = lazy(() => import('./pages/orders/Orders'));
const Cortacs = lazy(() => import('./pages/cortacs/Cortacs'));
const Notifications = lazy(() => import('./pages/notifications/Notifications'));
const TaxRollJobs = lazy(() => import('./pages/taxRollJobs/TaxRollJobs'));
const StagedTaxBills = lazy(() => import('./pages/stagedTaxBills/StagedTaxBills'));
enum AppState {
  BOOTED = 'booted',
  INITIALIZING = 'initializing',
  READY = 'ready',
}

const drawerWidth = 240;

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '74px',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const App: FunctionComponent = () => {
  const [appState, setAppState] = useState<AppState>(AppState.BOOTED);
  const auth = useAuth();
  const profile = useProfile();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   console.log(1231313);
  //   if (!auth.initialized) {
  //     auth.init();
  //     setAppState(AppState.INITIALIZING);
  //   }
  //
  //   // if (!profile.profile && !profile.hasError && !profile.loading) {
  //   // }
  // }, [auth, profile]);

  useEffect(() => {
    if (auth.initialized) {
      if (
        !profile.profile &&
        !profile.hasError &&
        !profile.loading &&
        location.pathname.indexOf(ROUTES.AUTH) === -1
      ) {
        profile.loadProfile();
      }

      if (!profile.loading) {
        setAppState(AppState.READY);
      }
    } else {
      auth.init();
      setAppState(AppState.INITIALIZING);
    }
  }, [auth.initialized]); //, location.pathname, profile]);

  useEffect(() => {
    if (auth.requiresAuth) {
      navigate(ROUTES.AUTH);
    }
  }, [auth.requiresAuth, navigate]);

  return (
    <>
      <Helmet defaultTitle="Easy Smart Pay™" titleTemplate="Easy Smart Pay™ - %s" />

      {appState === AppState.READY && (
        <Box sx={{ disable: 'flex' }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Header open={open} onDrawerOpen={handleDrawerOpen} />
            <Drawer variant="permanent" open={open}>
              <DrawerHeader>
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon />
                </IconButton>
              </DrawerHeader>
              <Divider />
              <List>
                {mainTabs.map((item) => (
                  <ListItem
                    key={item.name}
                    onClick={() => navigate(item.routes)}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        <item.icon />
                      </ListItemIcon>
                      <ListItemText primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Drawer>
            <Box component="main">
              <main>
                <Routes>
                  {/* Public Routes */}
                  <Route path={ROUTES.HOME} element={<Navigate to={ROUTES.CUSTOMERS} />}></Route>
                  <Route path={`${ROUTES.AUTH}/*`} element={<Auth></Auth>}></Route>
                  {/* <Route
                path={ROUTES.PROFILE}
                element={
                  <ProtectedRoute>
                    <Suspense fallback={<Loading />}>
                      <Profile></Profile>
                    </Suspense>
                  </ProtectedRoute>
                }
              ></Route> */}
                  <Route
                    path={`${ROUTES.CUSTOMERS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.CUSTOMERS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Customers></Customers>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path={`${ROUTES.PAYMENT_CONTRACTS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.PAYMENT_CONTRACTS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <PaymentContracts></PaymentContracts>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path={`${ROUTES.ACTIVITIES}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.ACTIVITIES}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Activities></Activities>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.DASHBOARD}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.DASHBOARD}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Dashboard></Dashboard>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.COUNTIES}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.COUNTIES}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Counties></Counties>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.PRODUCTS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.PRODUCTS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Products></Products>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.CORTACS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.CORTACS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Cortacs></Cortacs>
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.ORDERS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.ORDERS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Orders />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.USER_AGREEMENTS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.USER_AGREEMENTS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <UserAgreements />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.NOTIFICATIONS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.NOTIFICATIONS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <Notifications />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>
                  <Route
                    path={`${ROUTES.TAX_ROLLS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.NOTIFICATIONS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <TaxRolls />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.TAXROLL_JOBS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.TAXROLL_JOBS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <TaxRollJobs />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.STAGED_TAXBILLS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.STAGED_TAXBILLS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <StagedTaxBills />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>

                  <Route
                    path={`${ROUTES.RETRY_PAYMENTS}/*`}
                    element={
                      <ProtectedRoute path={`${ROUTES.RETRY_PAYMENTS}/*`}>
                        <Suspense fallback={<Loading />}>
                          <RetryPayments />
                        </Suspense>
                      </ProtectedRoute>
                    }
                  ></Route>
                </Routes>
              </main>
            </Box>
          </LocalizationProvider>
        </Box>
      )}
    </>
  );
};

export default App;
