import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';
import { Address } from '../models/Address';

export function getAddressByApn(
  taxRollId: string,
  apn: string
): Promise<AxiosError<any, any> | Address> {
  return getAxios()
    .get<any>(`/addresses/${taxRollId}/${apn}`)
    .then((resp: AxiosResponse<AxiosError<any, any> | Address, any>) => resp.data);
}
