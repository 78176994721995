import React from 'react';
import { authenticate, logout } from '../services/auth';

interface AuthContextType {
  authenticate: (code: string, scope: string, authuser: string, hd: string, prompt: string) => void;
  logout: () => void;
  init: (jwtToken?: string) => void;
  setAuthLastUrl: (url: string) => void;
  getAuthLastUrl: () => string;
  clearAuthLastUrl: () => void;
  setRequiresAuth: () => void;
  isAuthenticated: boolean;
  loading: boolean;
  hasError: boolean;
  hasAuthenticated: boolean;
  initialized: boolean;
  errorMessage?: string;
  requiresAuth?: boolean;
}

let AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [initialized, setInitialized] = React.useState<boolean>(false);
  let [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  let [loading, setLoading] = React.useState<boolean>(false);
  let [hasError, setHasError] = React.useState<boolean>(false);
  let [errorMessage, setErrorMessage] = React.useState<string>('');
  let [hasAuthenticated, setHasAuthenticated] = React.useState<boolean>(false);
  let [requiresAuth, setRequiresAuth] = React.useState<boolean>(false);

  let authFunc = (code: string, scope: string, authuser: string, hd: string, prompt: string) => {
    setLoading(true);
    setRequiresAuth(false);

    authenticate({ code, scope, authuser, hd, prompt })
      .then(() => {
        setIsAuthenticated(true);
        window.localStorage.setItem('authenticated', 'true');
      })
      .catch((err) => {
        window.localStorage.removeItem('authenticated');
        setIsAuthenticated(false);
        setHasError(true);
        setErrorMessage(err.message);
      })
      .finally(() => {
        setHasAuthenticated(true);
        setLoading(false);
      });
  };

  let logoutFunc = () => {
    setLoading(true);
    logout()
      .catch((err) => {
        setHasError(true);
        setErrorMessage(err.message);
      })
      .finally(() => {
        setIsAuthenticated(false);
        setLoading(false);
      });
  };

  let setRequiresAuthFunc = () => {
    window.localStorage.removeItem('authenticated');
    setHasAuthenticated(false);
    setIsAuthenticated(false);
    setRequiresAuth(true);
  };

  let init = () => {
    setIsAuthenticated(window.localStorage.getItem('authenticated') === 'true');
    setInitialized(true);
  };

  let setAuthLastUrl = (url: string) => {
    window.localStorage.setItem('authLastUrl', url);
  };

  let getAuthLastUrl = () => {
    return window.localStorage.getItem('authLastUrl') || '/';
  };

  let clearAuthLastUrl = () => {
    window.localStorage.removeItem('authLastUrl');
  };

  let value = {
    loading,
    hasError,
    authenticate: authFunc,
    logout: logoutFunc,
    getAuthLastUrl,
    setAuthLastUrl,
    clearAuthLastUrl,
    init,
    isAuthenticated,
    errorMessage,
    hasAuthenticated,
    initialized,
    requiresAuth,
    setRequiresAuth: setRequiresAuthFunc,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
