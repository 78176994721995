export const commonStyle = {
  container: {
    width: '80vw',
    maxWidth: '1110px',
    margin: '100px auto 0 auto',
    position: 'relative' as 'relative',
  },

  search: {
    right: 0,
    display: 'flex',
    justifyContent: 'right',
    marginBottom: 33,
    marginTop: 33,
  },
};
