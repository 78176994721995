import React, { FunctionComponent, useEffect } from 'react';
import { PathRouteProps, useLocation } from 'react-router';

import { REACT_APP_AUTH_URL } from '../constants/environment-variables';
import { useAuth } from '../contexts/authContext';

interface Props extends PathRouteProps {
  children: React.ReactNode;
  path: string;
}

/**
 * An enhanced Route that ensures the user is logged in. Logged-out users will be redirected away.
 */
const ProtectedRoute: FunctionComponent<Props> = ({ children }: Props) => {
  let [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    setIsAuthenticated(auth.isAuthenticated);
    if (!auth.isAuthenticated && !auth.loading) {
      auth.setAuthLastUrl(location.pathname);
      window.location.href = REACT_APP_AUTH_URL || '';
    }
  }, [auth, location.pathname]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return <></>;
};

export default ProtectedRoute;
