import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RetryPaymentDto } from '../../../global/models/PaymentContract';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { usePaymentContracts } from '../../../global/contexts/paymentContractsContext';
import { ROUTES } from '../../../global/constants/routes';
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { useProfile } from '../../../global/contexts/profileContext';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { formatDate } from '../../../global/components/Tables/Table';
import { getDataGridOperators } from '../../../global/helpers/filterOperators';
import toPrettyDate, { parseDateWithoutTimezone } from 'src/global/helpers/toPrettyDate';

const styles = {
  row: {
    marginBottom: '8px',
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  h1: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '34px',
    letterSpacing: 0,
    lineHeight: '36px',
  },
  editCustomer: {
    width: '100%',
    maxWidth: '768px',
  },
  wrapper: {
    width: '100%',
    marginBottom: '24px',
  },

  paperContainer: {
    padding: '12px 14px 18px 14px',
  },

  header: {
    color: '#BB86FC',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    minHeight: '16px',
    margin: '0px',
    fontFamily: 'Roboto',
  },
  secondaryHeader: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    minHeight: '24px',
    margin: '0px',
    fontFamily: 'Roboto',
  },
  detailText: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    minHeight: '20px',
    margin: '0px',
    fontFamily: 'Roboto',
  },

  rerunButton: {
    backgroundColor: 'rgba(0,0,0,0.87) !important',
    color: '#fff !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    letterSpacing: '1.25px !important',
    minHeight: '16px !important',
    margin: '1rem 0',
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important',
    fontFamily: 'Roboto',
    width: '100%',

    '&:disabled ': {
      backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    },
  },

  grid: {
    marginTop: '24px',
  },
};

interface RetryDetailsProps {
  selectedRetry: RetryPaymentDto | null | undefined;
  setSelectedRetry: (retry: RetryPaymentDto | null | undefined) => void;
}

const RetryDetails: FunctionComponent<RetryDetailsProps> = ({
  selectedRetry,
  setSelectedRetry,
}: RetryDetailsProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const context = usePaymentContracts();
  const navigate = useNavigate();
  const params = useParams();
  const { profile } = useProfile();

  async function getRetry(id: string) {
    const retry = await context.getRetryPaymentById(id);
    setSelectedRetry(retry);
  }

  useEffect(() => {
    if (selectedRetry === undefined) {
      navigate(ROUTES.RETRY_PAYMENTS);
      setSelectedRetry(undefined);
    }
  }, [selectedRetry]);

  useEffect(() => {
    if (params['*'] && !selectedRetry) {
      getRetry(params['*']);
    }
    return () => {
      setSelectedRetry(null);
    };
  }, []);

  const onRerun = useCallback(() => {
    if (selectedRetry && profile) {
      setLoading(true);
      context
        .rerunPayment({
          voucherInstallmentId: selectedRetry.voucherInstallmentId,
          orderId: selectedRetry.paymentContractOrderId,
          actorId: profile.id,
        })
        .then(() => {
          setLoading(false);
          if (params['*']) {
            getRetry(params['*']);
          }
        });
    }
  }, [selectedRetry]);

  const columns: GridColDef[] = [
    {
      field: 'status',
      headerName: 'Status',
      flex: 100,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 100,
    },
    // {
    //   field: 'voucherInstallmentId',
    //   headerName: 'Voucher Installment Id',
    //   flex: 200,
    // },
    {
      field: 'createdAt',
      type: 'date',
      headerName: 'Rerun Date',
      flex: 125,
      valueFormatter: formatDate,
      filterOperators: getDataGridOperators(),
    },

    {
      field: 'updatedAt',
      type: 'date',
      headerName: 'Failure Date',
      flex: 125,
      valueFormatter: formatDate,
      filterOperators: getDataGridOperators(),
    },

    {
      field: 'errorMessage',
      type: 'string',
      headerName: 'Message',
      flex: 125,
    },
  ];

  return (
    <div>
      <h1 style={styles.h1}>Retry Payment Details</h1>

      {selectedRetry ? (
        <>
          <Paper elevation={1}>
            <div style={styles.paperContainer}>
              <div style={styles.row}>
                <p style={styles.header}>APN</p>
                <Link
                  style={{ color: 'inherit' }}
                  to={`/payment-contracts/${selectedRetry?.paymentContractOrderId}`}
                >
                  {selectedRetry?.apn}
                </Link>
              </div>

              <div style={styles.row}>
                <p style={styles.header}>Bill Number</p>
                <Link
                  style={{ color: 'inherit' }}
                  to={`/payment-contracts/${selectedRetry?.paymentContractOrderId}`}
                >
                  {selectedRetry?.billNumber}
                </Link>
              </div>

              <div style={styles.row}>
                <p style={styles.header}>Scheduled Payment Date</p>
                <p style={styles.secondaryHeader}>
                  {toPrettyDate(parseDateWithoutTimezone(selectedRetry?.paymentDate))}
                </p>
              </div>

              <div style={styles.row}>
                <p style={styles.header}>Next Scheduled Payment Date</p>
                <p style={styles.secondaryHeader}>
                  {toPrettyDate(parseDateWithoutTimezone(selectedRetry?.nextPaymentDate))}
                </p>
              </div>

              <div style={styles.row}>
                <p style={styles.header}>Next Retry Date</p>
                <p style={styles.secondaryHeader}>
                  {toPrettyDate(parseDateWithoutTimezone(selectedRetry?.nextRetryDate))}
                </p>
              </div>

              <div style={styles.row}>
                <p style={styles.header}>Last Attempt Date</p>
                <p style={styles.secondaryHeader}>
                  {toPrettyDate(parseDateWithoutTimezone(selectedRetry?.lastAttemptDate))}
                </p>
              </div>
              <div style={styles.row}>
                <p style={styles.header}>Status</p>
                <p style={styles.secondaryHeader}>{selectedRetry?.status}</p>

                {['FAILED', 'EXPIRED', 'ACTIVE'].includes(selectedRetry?.status) ? (
                  <Button sx={styles.rerunButton} onClick={onRerun} disabled={loading}>
                    <p>RERUN PAYMENT</p>
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Paper>

          {selectedRetry.attempts ? (
            <DataGridPro
              style={styles.grid}
              rowSelection={false}
              getRowId={(row) => row.createdAt}
              rows={selectedRetry.attempts}
              columns={columns}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <div style={styles.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default RetryDetails;
