import { Query } from '../models/Query';
import { isNumber, orderBy } from 'lodash';
import { CortacUpcoming } from '../models/Cortac';

function sortCortacs(items: CortacUpcoming[], query: Query): CortacUpcoming[] {
  if (!items || items.length === 0) {
    return [];
  }
  const column = query.sortColumn as string;
  const direction = query.sortDirection || 'ASC';
  const order = direction === 'ASC' ? 'asc' : 'desc';

  return orderBy(items, [column], [order]);
}

export function trimCortacs(items: CortacUpcoming[], query: Query): CortacUpcoming[] {
  const start = query.page * query.pageSize;
  const end = start + query.pageSize;
  return items.slice(start, end);
}

export function filterCortacs(items: CortacUpcoming[], query: Query): CortacUpcoming[] {
  const props = [
    'taxRollId',
    'taxYear',
    'installmentNumber',
    'dueDate',
    'amountTotal',
    'amountPaid',
    'readyToSubmit',
    'apn',
    'billNumber',
    'batchPaymentTrackingId',
    'isPaid',
    'voucherId',
    'billType',
    'status',
  ];
  const filtered = items.filter((item) => {
    const term = (query.term || '').toLowerCase();

    const index = props.findIndex((prop) => {
      const value = item[prop as keyof CortacUpcoming]
        ? (item[prop as keyof CortacUpcoming] as string).toLowerCase()
        : '';
      return value.toLowerCase().indexOf(term) >= 0;
    });
    return index >= 0;
  });

  const queried = filtered.filter((item) => {
    const q = query.query || {};
    const queryCols = Object.keys(q);
    if (queryCols.length) {
      const index = queryCols.findIndex((prop) => {
        // @ts-ignore
        let value = item[prop] && typeof item[prop] === 'string' ? item[prop].toLowerCase() : '';

        if (Array.isArray(q[prop])) {
          return q[prop].some((x: string) => value.indexOf(x) >= 0);
        }

        if (typeof q[prop] === 'object') {
          // @ts-ignore
          value = item[prop];
          const min = q[prop]?.min;
          const max = q[prop]?.max;

          if (isNumber(min) && isNumber(max)) {
            return value >= min && value <= max;
          } else if (isNumber(min)) {
            return value >= min;
          } else if (isNumber(max)) {
            return value <= max;
          }
        }
      });

      return index >= 0;
    }
    return true;
  });

  return sortCortacs(queried, query);
}

export function filterAndTrimContracts(items: CortacUpcoming[], query: Query): CortacUpcoming[] {
  return trimCortacs(filterCortacs(items, query), query);
}
