import { LinearProgress } from '@mui/material';
import { FunctionComponent } from 'react';
// import { makeStyles } from '@material-ui/core/styles';

/**
 * A simple loading animation adapted from https://github.com/tobiasahlin/SpinKit
 *
 * Expands outwards while disappearing
 */
// const useStyles = makeStyles({
//   spinner: {
//     width: '60px',
//     height: '60px',
//     margin: '100px auto',
//     backgroundColor: '#222',
//     borderRadius: '100%',
//     '-webkit-animation': '$sk-scaleout 1.0s infinite ease-in-out',
//     animation: '$sk-scaleout 1.0s infinite ease-in-out',
//   },
//   '@keyframes sk-scaleout': {
//     '0%': {
//       '-webkit-transform': 'scale(0)',
//       transform: 'scale(0)',
//     },
//     '100%': {
//       '-webkit-transform': 'scale(1.0)',
//       transform: 'scale(1.0)',
//       opacity: 0,
//     },
//   },
// });

const Loading: FunctionComponent = () => <LinearProgress />;

export default Loading;
