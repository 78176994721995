import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { getPaymentContracts } from '../services/paymentContracts';
import { useAuth } from './authContext';
import {
  PaymentContract,
  PaymentContractsRequest,
  PaymentContractsResult,
  PaymentContractStatus,
  TablePaymentContracts,
} from '../models/PaymentContract';
import { Query } from '../models/Query';
import { PaymentContractQueryResults } from '../models/PaymentContractQueryResults';
import { mapPaymentContracts } from '../helpers/mapPaymentContracts';
import { DefaultContext } from '../models/Context';
import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';
import {
  filterAndTrimContracts,
  filterContracts,
  trimContracts,
} from '../helpers/filterProductPaymentContracts';
import { clone } from 'lodash';

interface ProductPaymentContractsContextType extends DefaultContext {
  queryPaymentContracts: (q: Query) => void;
  queryPaymentContractsForExport: (q: Query) => void;
  results?: PaymentContractQueryResults;
  exportResults?: TablePaymentContracts[];
  query: Query | null;
  setColumnsToExport: (c: AvailableExportColumn[]) => void;
  columnsToExport: AvailableExportColumn[];
  clearAllContracts: () => void;
  summary: {
    totalNumber: number | undefined | null;
    needsToSettle: number | undefined | null;
    totalAmount: number | undefined | null;
    totalAmountRemainingToSettle: number | undefined | null;
  };
}

function getPageTotal(paymentContracts: PaymentContract[] | undefined, pageSize: number) {
  const paymentContractsSize = paymentContracts?.length || 0;

  if (paymentContractsSize === 0) {
    return 0;
  }

  return Math.round(paymentContractsSize / pageSize);
}

function isPendingContract(row: TablePaymentContracts): boolean {
  return (
    row.status === PaymentContractStatus.PENDING ||
    (row.selectedInstallmentContract &&
      row.selectedInstallmentContract.status !== 'CANCELED' &&
      row.selectedInstallmentContract.amountPaid < row.selectedInstallmentContract.amount)
  );
}

let ProductPaymentContractsContext = createContext<ProductPaymentContractsContextType>(null!);

export function ProductPaymentContractsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [results, setResults] = useState<PaymentContractQueryResults | undefined>();
  const [query, setQuery] = useState<Query | null>(null);
  const [exportQuery, setExportQuery] = useState<Query | null>(null);
  const [exportResults, setExportResults] = useState<TablePaymentContracts[] | undefined>([]);
  const [allContracts, setAllContracts] = useState<TablePaymentContracts[] | undefined>();
  const [summary, setSummary] = useState({
    totalNumber: 0,
    needsToSettle: 0,
    totalAmount: 0,
    totalAmountRemainingToSettle: 0,
  });

  const auth = useAuth();

  const [columnsToExport, setColumns] = useState<AvailableExportColumn[]>([]);

  const calculateTotalNumber = (rows: TablePaymentContracts[]): number => {
    return (
      rows.filter(
        (row) =>
          row.selectedInstallmentContract && row.selectedInstallmentContract.status !== 'CANCELED'
      ).length || 0
    );
  };

  const calculateNeedsToSettle = (rows: TablePaymentContracts[]): number => {
    return (
      rows.filter((row) => {
        return isPendingContract(row);
      }).length || 0
    );
  };

  const calculateTotalAmount = (rows: TablePaymentContracts[]): number => {
    return rows
      .filter((row) => row.selectedInstallmentContract)
      .reduce((sum, current) => sum + current.selectedInstallmentContract.amount, 0);
  };

  const calculateTotalAmountRemainingToSettle = (rows: TablePaymentContracts[]): number => {
    return (
      rows
        // .filter((row) => row.selectedInstallmentContract && !row.selectedInstallmentContract.isPaid)
        .reduce(
          (sum, current) =>
            sum +
            (current.selectedInstallmentContract.amount -
              current.selectedInstallmentContract.amountPaid),
          0
        )
    );
  };

  const filterCanceledRows = (rows: TablePaymentContracts[]) => {
    return rows.filter((row) => {
      return !(
        !row.selectedInstallmentContract ||
        row.selectedInstallmentContract.status === 'CANCELED' ||
        (row.status === 'CANCELED' && !row.voucherId) ||
        (row.status === 'CANCELED' && row.billType === 3)
      );
    });
  };

  const calculateSummary = (allRows: TablePaymentContracts[]) => {
    const rows = filterCanceledRows(allRows);
    const totalNumber = calculateTotalNumber(rows);
    const needsToSettle = calculateNeedsToSettle(rows);
    const totalAmount = calculateTotalAmount(rows);
    const totalAmountRemainingToSettle = calculateTotalAmountRemainingToSettle(rows);

    setSummary({
      totalNumber,
      needsToSettle,
      totalAmount,
      totalAmountRemainingToSettle,
    });
  };

  useEffect(() => {
    if (!query) {
      return;
    }

    if (results) {
      // force a loading state between pages
      // otherwise the table will show the previous page
      // after the user clicked the next or previous button
      setResults({
        ...results,
        rows: undefined,
      });
    }

    const q = { ...query.query, ...(query.term && { term: query.term }) };
    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      // page: query.page + 1,
      page: query.page,
      pageSize: 99999,
      query: q,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    const selectedInstallment = clone(request?.query?.installment);

    delete request?.query?.installment;

    if (!allContracts) {
      setLoading(true);
      setLoaded(false);
      getPaymentContracts(request)
        .then((result) => {
          const data: PaymentContractsResult = result as PaymentContractsResult;
          let rows = mapPaymentContracts(data.results, selectedInstallment);
          setAllContracts(rows as TablePaymentContracts[]);
          setResults({
            total: data.total,
            rows: filterAndTrimContracts(rows, query),
            // MUI's data grid starts with page 0
            // the server starts with page 1
            page: query.page,
            pageSize: query.pageSize,
            totalPages: getPageTotal(data.results, query.pageSize),
          });

          calculateSummary(rows);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoading(false);
          setLoaded(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
        });
    } else if (!loading) {
      const rows = mapPaymentContracts(allContracts, selectedInstallment);
      const filtered = filterContracts(rows, query);
      setResults({
        total: filtered?.length || 0,
        query: query,
        rows: trimContracts(filtered, query),
        page: query.page,
        pageSize: query.pageSize,
        totalPages: getPageTotal(filtered, query.pageSize),
      });

      calculateSummary(rows);
    }
  }, [query]);

  // separated use effect not to mess with original page size and results
  useEffect(() => {
    if (!exportQuery) {
      return;
    }

    const request: PaymentContractsRequest = {
      // MUI's data grid starts with page 0
      // the server starts with page 1
      page: 1,
      pageSize: exportQuery.pageSize,
      query: exportQuery.query || {},
      ...(exportQuery.sortColumn && { sortColumn: exportQuery.sortColumn }),
      ...(exportQuery.sortDirection && { sortDirection: exportQuery.sortDirection }),
    };

    getPaymentContracts(request)
      .then((result) => {
        const data: PaymentContractsResult = result as PaymentContractsResult;
        setExportResults(mapPaymentContracts(data.results));
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [exportQuery]);

  const queryPaymentContracts = (q: Query) => {
    setQuery(q);
  };

  const queryPaymentContractsForExport = (q: Query) => {
    setExportQuery(q);
  };

  const setColumnsToExport = (c: AvailableExportColumn[]) => {
    setColumns(c);
  };

  const clearAllContracts = () => {
    setAllContracts(undefined);
    setSummary({
      totalNumber: 0,
      totalAmount: 0,
      needsToSettle: 0,
      totalAmountRemainingToSettle: 0,
    });
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    queryPaymentContracts,
    queryPaymentContractsForExport,
    results,
    exportResults,
    query,
    setColumnsToExport,
    columnsToExport,
    clearAllContracts,
    summary,
  };

  return (
    <ProductPaymentContractsContext.Provider value={value}>
      {children}
    </ProductPaymentContractsContext.Provider>
  );
}

export function useProductPaymentContracts() {
  return useContext(ProductPaymentContractsContext);
}
