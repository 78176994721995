import { AxiosError } from 'axios';
import { getAxios } from './espAxios';
import { ImportJob, ImportJobQueryRequest, ImportJobQueryResponse } from '../models/ImportJob';

const ROOT_PATH_JOBS = 'import-tax-roll/jobs';
export function getImportedJobs(
  request: ImportJobQueryRequest
): Promise<ImportJobQueryResponse | AxiosError> {
  return getAxios()
    .post<ImportJobQueryResponse>(`${ROOT_PATH_JOBS}/query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getImportedJob(jobId: string): Promise<ImportJob | AxiosError> {
  return getAxios()
    .get<ImportJob>(`${ROOT_PATH_JOBS}/${jobId}`)
    .then((resp) => {
      return resp.data;
    });
}
