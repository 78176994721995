import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { commonStyle } from '../../../global/constants/common.style';
import Search from '../../../global/components/Search/Search';
import { Query } from '../../../global/models/Query';
import { PageSize } from '../../../global/components/Tables/GenericTable';
import {
  UserAgreementRowResult,
  useUserAgreements,
} from '../../../global/contexts/userAgreementContext';
import { updateUrlQuery } from '../../../global/helpers/queryHelper';
import { useNavigate } from 'react-router-dom';
import UserAgreementTable from '../../../global/components/Tables/UserAgreementTable';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { ROUTES } from '../../../global/constants/routes';
import Button from '@mui/material/Button';

const styles = {
  newItem: {
    width: '100%',
  },
};

const UserAgreementList: FunctionComponent = () => {
  const context = useUserAgreements();
  const navigate = useNavigate();

  const [userAgreements, setResults] = useState<UserAgreementRowResult>();

  const [query, setQuery] = useState<Query>({ page: 0, pageSize: PageSize.MEDIUM, term: '' });

  useEffect(() => {
    setResults(context.results);
  }, [context.results]);

  useEffect(() => {
    context.getAgreements(query);
    updateUrlQuery(query, navigate);
  }, [query, navigate]);

  const handleSearchTermChange = useCallback((term: string) => {
    setQuery({ ...query, term });
  }, []);

  return (
    <div>
      <div style={commonStyle.search}>
        <Search onTermChange={handleSearchTermChange} term={query.term || ''} />
      </div>
      <div style={styles.newItem}>
        <Button
          style={{ float: 'right' }}
          onClick={() => navigate(`${ROUTES.USER_AGREEMENTS}/new`)}
        >
          Add new
        </Button>
      </div>

      <UserAgreementTable
        rows={userAgreements?.rows}
        onCellClick={(cellParams: GridCellParams) =>
          navigate(`${ROUTES.USER_AGREEMENTS}/${cellParams.row.agreementId}`)
        }
        rowsPerPageOptions={[PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE]}
        query={query}
        rowCount={context.results?.total || 1}
        updateQuery={setQuery}
        loading={context.loading}
      />
    </div>
  );
};

export default UserAgreementList;
