import { BillType } from '../enums/bill-type.enum';

export default function convertBillType(type: number) {
  switch (type) {
    case BillType.REGULAR:
      return 'REGULAR';
    case BillType.REVISED:
      return 'REVISED';
    case BillType.SUPPLEMENTAL:
      return 'SUPPLEMENTAL';
    default:
      return 'UNKNOWN';
  }
}
