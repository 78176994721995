import { DefaultContext } from '../models/Context';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useAuth } from './authContext';
import { getActivityLogs } from '../services/activityLog';
import { ActivityLogRequest, ActivityLogResponse } from '../models/ActivityLog';
import { AxiosError } from 'axios';

interface ActivityLogContextType extends DefaultContext {
  getList: (query: ActivityLogRequest) => Promise<void>;
  results?: ActivityLogResponse;
  lastQuery?: ActivityLogRequest;
}

let ActivityLogContext = createContext<ActivityLogContextType>(null!);

export function ActivityLogProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(true);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [results, setResults] = useState<ActivityLogResponse | undefined>();
  const [lastQuery, setLastQuery] = useState<ActivityLogRequest | undefined>();

  const auth = useAuth();

  const checkQuery = (q: ActivityLogRequest): boolean => {
    if (q.query) {
      delete q.query.term;
      if (Object.keys(q.query).length === 0) {
        return Object.keys(lastQuery?.query || {}).length !== 0;
      }
      const qVal = JSON.stringify(q.query);
      const queryVal = JSON.stringify(lastQuery?.query);
      return qVal !== queryVal;
    }
    return false;
  };

  const checkTerm = (term?: string): boolean => {
    if (!term && !lastQuery?.term) {
      return false;
    }
    return term !== lastQuery?.term;
  };

  const checkRunAction = (query: ActivityLogRequest): boolean => {
    return (
      lastQuery?.page !== query?.page ||
      lastQuery?.pageSize !== query?.pageSize ||
      lastQuery?.sortColumn !== query?.sortColumn ||
      lastQuery?.sortDirection !== query?.sortDirection ||
      checkTerm(query?.term) ||
      checkQuery(query)
    );
  };

  const getList = async (q: ActivityLogRequest) => {
    if (q && checkRunAction(q)) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);
      const query = { ...q.query, ...(q.term && { term: q.term }) };
      const request: ActivityLogRequest = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
      };

      getActivityLogs(request)
        .then((result) => {
          const data: ActivityLogResponse = result as ActivityLogResponse;
          setLoaded(true);
          setLoading(false);
          setResults(data);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoading(false);
          setLoaded(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        });
    }
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    getList,
    lastQuery,
    results,
  };

  return <ActivityLogContext.Provider value={value}>{children}</ActivityLogContext.Provider>;
}

export function useActivityLog() {
  return useContext(ActivityLogContext);
}
