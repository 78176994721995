import React, { FunctionComponent, useEffect } from 'react';
import { ContentItemDto, UserAgreementDto } from '../../../global/models/UserAgreement';
import { useUserAgreements } from '../../../global/contexts/userAgreementContext';
import { useParams } from 'react-router';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import { DatePicker } from '@mui/x-date-pickers';
import { Button, FormControl, TextField } from '@mui/material';
import { ROUTES } from '../../../global/constants/routes';
import { useNavigate } from 'react-router-dom';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  h1: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '34px',
    letterSpacing: 0,
    lineHeight: '36px',
  },

  idContainer: {
    padding: '16px',
    fontFamily: 'Roboto',
  },

  editArea: {
    padding: '16px 24px',
  },

  h2: {
    display: 'flex',
    color: 'rgba(0,0,0,0.87)',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    margin: '0px',
    justifyContent: 'space-between',
  },

  confirmButton: {
    color: '#2BB1A8',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },

  p: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    margin: '0px',
  },

  divider: {
    marginBottom: '24px',
  },

  inputArea: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    columnGap: '32px',
    rowGap: '40px',
    marginBottom: '24px',
  },

  contentInputArea: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '40px',
    marginBottom: '24px',
  },

  editCustomer: {
    width: '100%',
    maxWidth: '768px',
  },
  wrapper: {
    width: '100%',
    marginBottom: '24px',
  },
};

export interface UserAgreementDetailsProps {
  selectedAgreement: UserAgreementDto | null | undefined;
  setSelectedAgreement: (agreement: UserAgreementDto | null) => void;
}

const UserAgreementDetails: FunctionComponent<UserAgreementDetailsProps> = ({
  selectedAgreement,
  setSelectedAgreement,
}: UserAgreementDetailsProps) => {
  const params = useParams();
  const context = useUserAgreements();
  const navigate = useNavigate();

  async function getAgreement(id: string) {
    const agreement = await context.getAgreementById(id);
    if (agreement && 'agreementId' in agreement) {
      setSelectedAgreement(agreement as UserAgreementDto);
    }
  }

  function removeSection(index: number) {
    if (selectedAgreement) {
      selectedAgreement.contentItems.splice(index, 1);
      setSelectedAgreement({ ...selectedAgreement });
    }
  }

  function addSection() {
    const contentItem: ContentItemDto = {
      content: '',
    };

    if (selectedAgreement) {
      if (!selectedAgreement.contentItems) {
        selectedAgreement.contentItems = [];
      }

      selectedAgreement.contentItems.push(contentItem);

      const ag: UserAgreementDto = { ...selectedAgreement };
      setSelectedAgreement(ag);
    }
  }

  function setVersion(value: string) {
    if (selectedAgreement) {
      selectedAgreement.version = parseFloat(value);

      const ag: UserAgreementDto = { ...selectedAgreement };
      setSelectedAgreement(ag);
    }
  }

  function setPublishDate(value: Date | null) {
    if (selectedAgreement && value) {
      selectedAgreement.publishedDate = value;

      const ag: UserAgreementDto = { ...selectedAgreement };
      setSelectedAgreement(ag);
    }
  }

  function setProp(value: string, index: number) {
    if (selectedAgreement) {
      let contentItems = selectedAgreement.contentItems;
      (contentItems[index] as ContentItemDto).content = value;

      const ag: UserAgreementDto = { ...selectedAgreement, contentItems };
      setSelectedAgreement(ag);
    }
  }

  async function saveAgreement() {
    if (selectedAgreement) {
      const agreement = selectedAgreement?.agreementId
        ? await context.updateAgreement(selectedAgreement)
        : await context.createAgreement(selectedAgreement);
      setSelectedAgreement(agreement as UserAgreementDto);
    }
  }

  async function deleteAgreement() {
    if (selectedAgreement && selectedAgreement.agreementId) {
      await context.deleteAgreement(selectedAgreement.agreementId);
      context.resetQuery();
      setSelectedAgreement(null);
      navigate(`${ROUTES.USER_AGREEMENTS}`);
    }
  }

  useEffect(() => {
    if (!selectedAgreement) {
      if (params.agreementId === 'new') {
        const ag: UserAgreementDto = {
          version: 1.0,
          publishedDate: new Date(),
          contentItems: [],
          presented: false,
        };
        setSelectedAgreement(ag);
      } else if (params.agreementId && !context.singleAgreementLoading) {
        getAgreement(params.agreementId);
      }
    }
  }, [params]);

  return (
    <>
      {!!selectedAgreement && (
        <div>
          <h1 style={styles.h1}>User Agreement Details</h1>
          <Paper>
            <div style={styles.editArea}>
              <div style={styles.idContainer}>
                <h2 style={styles.h2}>
                  Agreement ID
                  {!selectedAgreement.presented && selectedAgreement.agreementId && (
                    <Button style={styles.confirmButton} onClick={() => deleteAgreement()}>
                      Delete
                    </Button>
                  )}
                </h2>
                <p style={styles.p}>{selectedAgreement.agreementId}</p>
              </div>

              <Divider sx={styles.divider} />

              <div style={styles.inputArea}>
                <FormControl required={true}>
                  <TextField
                    disabled={!!selectedAgreement.presented}
                    type="number"
                    label="Version"
                    inputProps={{
                      step: '0.01',
                    }}
                    value={selectedAgreement.version}
                    onChange={(e) => setVersion(e.target.value)}
                  />
                </FormControl>

                <FormControl required={true}>
                  <DatePicker
                    disabled={!!selectedAgreement.presented}
                    label="Publish date"
                    value={selectedAgreement.publishedDate}
                    onChange={(value) => setPublishDate(value)}
                    inputFormat={'MM/dd/yyyy'}
                    renderInput={(p) => <TextField required={false} {...p} />}
                  />
                </FormControl>
              </div>

              <Divider sx={styles.divider} />

              {selectedAgreement.contentItems.map((item: ContentItemDto, index: number) => (
                <div key={index} style={styles.contentInputArea}>
                  <FormControl fullWidth required={true}>
                    <TextField
                      disabled={!!selectedAgreement.presented}
                      multiline={true}
                      label="Content"
                      value={item.content}
                      onChange={(e) => setProp(e.target.value, index)}
                    />
                  </FormControl>

                  {!selectedAgreement.presented && (
                    <Button onClick={() => removeSection(index)}>Remove section</Button>
                  )}

                  <Divider sx={styles.divider} />
                </div>
              ))}

              {!selectedAgreement.presented && (
                <Button onClick={() => addSection()}>Add section</Button>
              )}
            </div>

            {!selectedAgreement.presented && (
              <>
                <Button onClick={() => saveAgreement()}>Save agreement</Button>
              </>
            )}
          </Paper>
        </div>
      )}
    </>
  );
};

export default UserAgreementDetails;
