import { County } from '../models/County';
import { Query } from '../models/Query';

function sortCounties(counties: County[], query: Query): County[] {
  const column = query.sortColumn as string;
  const direction = query.sortDirection || 'ASC';

  if (!counties || counties.length === 0) {
    return [];
  }

  // if (!counties[0][column]) {
  //   return counties;
  // }

  if (column) {
    return counties.sort((a: any, b: any) => {
      let first;
      let second;
      if (direction === 'ASC') {
        if (typeof a[column] === 'string') {
          first = a[column]?.toLowerCase();
          second = b[column]?.toLowerCase();
        } else {
          first = a[column];
          second = b[column];
        }
      } else {
        if (typeof a[column] === 'string') {
          first = b[column]?.toLowerCase();
          second = a[column]?.toLowerCase();
        } else {
          first = b[column];
          second = a[column];
        }
      }

      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }

      // names must be equal
      return 0;
    });
  }

  return counties;
}

function trimCounties(counties: County[], query: Query): County[] {
  let start = query.page * query.pageSize;

  const end = start + query.pageSize + 1;
  return counties.slice(start, end - 1);
}

export function filterCounties(counties: County[], query: Query): County[] {
  const props = ['name', 'state', 'status'];

  const filtered = counties.filter((county) => {
    const term = (query.term || '').toLowerCase();
    const index = props.findIndex((prop) => {
      const value = (county as any)[prop].toLowerCase();
      return value.toLowerCase().indexOf(term) >= 0;
    });

    return index >= 0;
  });

  const sorted = sortCounties(filtered, query);
  const trimmed = trimCounties(sorted, query);

  return trimmed;
}
