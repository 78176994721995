import { AxiosError, AxiosResponse } from 'axios';
import { ROUTES } from '../constants/routes';
import { GenerateCortacDataRequest } from '../models/County';
import { Product } from '../models/Product';
import { getAxios } from './espAxios';

const BASE_SERVICE_PATH = ROUTES.PRODUCTS;

export function getProducts(): Promise<Product[] | AxiosError> {
  return getAxios()
    .get<Product[]>(BASE_SERVICE_PATH)
    .then((resp) => resp.data);
}

export function generateCortacFile(data: GenerateCortacDataRequest): Promise<string | AxiosError> {
  const body = {
    taxYear: data.taxYear.toString(),
    installmentNumber: data.installmentNumber,
    batchPaymentTrackingId: data.batchPaymentTrackingId,
    certified: data.certified,
  };
  const { taxRollId } = data;
  return getAxios()
    .post<string>(`${BASE_SERVICE_PATH}/${taxRollId}/generate-cortac`, body)
    .then((resp) => resp.data);
}

export function getProduct(productId?: string, taxRollId?: string): Promise<Product | AxiosError> {
  if (productId) {
    return getAxios()
      .get<Product | AxiosError>(`${BASE_SERVICE_PATH}/find?productId=${productId}`)
      .then((resp: AxiosResponse<Product | AxiosError>) => resp.data);
  }

  if (taxRollId) {
    return getAxios()
      .get<Product | AxiosError>(`${BASE_SERVICE_PATH}/find?taxRollId=${taxRollId}`)
      .then((resp: AxiosResponse<Product | AxiosError>) => resp.data);
  }

  return Promise.reject('Product not found');
}
