import { ROUTES } from '../constants/routes';
import {
  NotificationItem,
  NotificationRequest,
  NotificationResponse,
} from '../models/NotificationItem';
import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';

const BASE_SERVICE_PATH = ROUTES.NOTIFICATIONS;

export function getNotifications(
  request?: NotificationRequest
): Promise<NotificationResponse | AxiosError> {
  return getAxios()
    .post<NotificationResponse | AxiosError>(`${BASE_SERVICE_PATH}/query`, request)
    .then((resp: AxiosResponse<NotificationResponse | AxiosError>) => resp.data);
}

export function getNotification(id: string): Promise<NotificationItem | AxiosError> {
  return getAxios()
    .get<NotificationItem | AxiosError>(`${BASE_SERVICE_PATH}/${id}`)
    .then((resp: AxiosResponse<NotificationItem | AxiosError>) => resp.data);
}
