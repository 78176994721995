import { AxiosError } from 'axios';
import React from 'react';
import { Profile } from '../models/Profile';
import { getProfile } from '../services/profile';
import { useAuth } from './authContext';

interface ProfileContextType {
  loading: boolean;
  hasError: boolean;
  errorMessage?: string;
  loadProfile: () => void;
  profile?: Profile;
}

let ProfileContext = React.createContext<ProfileContextType>(null!);

export function ProfileProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = React.useState<boolean>(false);
  let [hasError, setHasError] = React.useState<boolean>(false);
  let [errorMessage, setErrorMessage] = React.useState<string>('');
  let [profile, setProfile] = React.useState<Profile | undefined>();

  const auth = useAuth();

  let loadProfile = () => {
    setLoading(true);
    getProfile()
      .then((result) => {
        setProfile(result as Profile);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let value = {
    loadProfile,
    loading,
    hasError,
    errorMessage,
    profile,
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
}

export function useProfile() {
  return React.useContext(ProfileContext);
}
