import { FunctionComponent, useEffect, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { commonStyle } from '../../global/constants/common.style';
import Crumbs from '../../global/components/Crumbs/Crumbs';
import { mainTabs } from '../../global/helpers/routingHelper';
import { RetryPaymentDto } from '../../global/models/PaymentContract';
import RetryList from './RetryList/RetryList';
import RetryDetails from './RetryDetails/RetryDetails';

const RetryPayments: FunctionComponent = () => {
  const [selectedRetryPayment, setSelectedRetryPayment] =
    useState<RetryPaymentDto | null | undefined>(null);
  const params = useParams();

  useEffect(() => {
    // if there isn't a customer id in the params
    // and a customer has been selected in state
    // then clear the state
    if (!params['*'] && !!selectedRetryPayment) {
      setSelectedRetryPayment(null);
    }
  }, [params]);

  return (
    <div style={commonStyle.container}>
      <Crumbs
        links={[
          { name: mainTabs[13].name, path: mainTabs[13].routes, icon: mainTabs[13].icon },
          ...(!!selectedRetryPayment
            ? [
                {
                  name: `${selectedRetryPayment.voucherInstallmentId}`,
                  path: `${mainTabs[13].routes}${selectedRetryPayment.voucherInstallmentId}`,
                },
              ]
            : []),
        ]}
      />
      <Routes>
        <Route path="/" element={<RetryList />} />
        <Route
          path="/:id"
          element={
            <RetryDetails
              selectedRetry={selectedRetryPayment}
              setSelectedRetry={setSelectedRetryPayment}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default RetryPayments;
