import { AxiosError, AxiosResponse } from 'axios';
import { getAxios } from './espAxios';
import {
  PaymentContract,
  PaymentContractsRequest,
  PaymentContractsResult,
  CancelPaymentContractRequest,
  ProcessRecurringPaymentRequest,
  RetryPaymentQueryRequest,
  RetryPaymentQueryResponse,
  RetryPaymentDto,
} from '../models/PaymentContract';

export function getPaymentContracts(
  request: PaymentContractsRequest
): Promise<PaymentContractsResult | AxiosError> {
  return getAxios()
    .post<PaymentContractsResult | AxiosError>('/payment-contracts/query', request)
    .then((resp: AxiosResponse<AxiosError<any, any> | PaymentContractsResult, any>) => resp.data);
}

export function getAllPaymentContracts(
  request: PaymentContractsRequest
): Promise<PaymentContractsResult | AxiosError> {
  let allQueryResponse: PaymentContractsResult;
  return new Promise(async (resolve, reject) => {
    while (!allQueryResponse || allQueryResponse.results.length < allQueryResponse.total) {
      if (allQueryResponse) {
        request.page += 1;
      }

      const queryResponse = (await getPaymentContracts(request)) as PaymentContractsResult;
      if (!allQueryResponse) {
        allQueryResponse = queryResponse;
      } else {
        allQueryResponse.results = allQueryResponse.results.concat(queryResponse.results);
      }
    }

    if (allQueryResponse.results.length === allQueryResponse.total) {
      resolve(allQueryResponse);
    } else {
      reject('Failed to get all payment contracts');
    }
  });
}

export function getPaymentContractByOrderId(
  orderId: string
): Promise<PaymentContract | AxiosError> {
  return getAxios()
    .get<PaymentContract | AxiosError>(`/payment-contracts/${orderId}`)
    .then((resp: AxiosResponse<AxiosError<any, any> | PaymentContract, any>) => resp.data);
}

export function cancelContract(request: CancelPaymentContractRequest) {
  return getAxios()
    .post<any | AxiosError>(`/payment-contracts/${request.orderId}/cancel`, request)
    .then((resp: AxiosResponse<AxiosError<any, any> | PaymentContract, any>) => resp.data);
}

export function doRerunPayment(
  request: ProcessRecurringPaymentRequest
): Promise<{ success: boolean } | AxiosError<any, any>> {
  return getAxios()
    .post<any | AxiosError>('/payments/process-recurring-payment', request)
    .then((resp: AxiosResponse<AxiosError<any, any> | { success: boolean }>) => resp.data);
}

export function reindex(orderId: string): Promise<string | AxiosError> {
  return getAxios()
    .get(`/payment-contracts/${orderId}/reindex`)
    .then((resp: AxiosResponse<AxiosError<any, any> | string>) => resp.data);
}

export function queryRetryPayments(
  request: RetryPaymentQueryRequest
): Promise<RetryPaymentQueryResponse | AxiosError> {
  return getAxios()
    .post('/payments/query-retry-payments', request)
    .then((resp) => resp.data);
}

export function getSingleRetryPayment(id: string): Promise<RetryPaymentDto | AxiosError> {
  return getAxios()
    .post('/payments/query-retry-payments', { query: { voucherInstallmentId: [id] } })
    .then((resp) => resp.data.results[0]);
}
