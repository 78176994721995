import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { createCounty, getCounties, getCounty, updateCounty } from '../services/counties';
import { useAuth } from './authContext';
import { Query } from '../models/Query';
import {
  CountyError,
  CountyItem,
  CountyItemCreate,
  CountyRequest,
  CountyResponse,
} from '../models/County';
import { PageSize } from '../components/Tables/GenericTable';
import { DefaultContext } from '../models/Context';

interface CountiesContextType extends DefaultContext {
  counties?: CountyResponse;
  getList: (query?: Query) => void;
  getCountyById: (id: string) => Promise<CountyItem | AxiosError | unknown>;
  addCounty: (county: CountyItemCreate) => Promise<CountyItem | AxiosError | unknown>;
  editCounty: (taxRollId: string, county: any) => Promise<CountyItem | AxiosError | unknown>;
  submitting?: boolean;
  refreshList: () => void;
  validate?: (name: string, value: any) => void;
  error: CountyError;
  resetErrors: () => void;
  countyInvalid: (county: CountyItem) => boolean;
}

const CountiesContext = React.createContext<CountiesContextType>(null!);

export function CountiesProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [counties, setCounties] = React.useState<CountyResponse | undefined>();
  const [lastQuery, setLastQuery] = React.useState<Query | undefined>();
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);

  const [error, setError] = useState<CountyError>({
    name: false,
    state: false,
    taxRollId: false,
    address: false,
    countyWebsite: false,
  });

  const auth = useAuth();
  const defaultQuery = { page: 0, pageSize: PageSize.MEDIUM, term: '' };

  // TODO - remove and use checkQuery from helpers/QueryHelper
  const checkQuery = (q: Query): boolean => {
    if (q.query) {
      if (Object.keys(q.query).length === 0) {
        return Object.keys(lastQuery?.query || {}).length !== 0;
      }
      const qVal = JSON.stringify(q.query);
      const queryVal = JSON.stringify(lastQuery?.query);
      return qVal !== queryVal;
    }
    return false;
  };

  const checkRunAction = (query: Query): boolean => {
    return (
      lastQuery?.page !== query?.page ||
      lastQuery?.pageSize !== query?.pageSize ||
      lastQuery?.sortColumn !== query?.sortColumn ||
      lastQuery?.sortDirection !== query?.sortDirection ||
      lastQuery?.term !== query?.term ||
      checkQuery(query)
    );
  };

  const refreshList = () => {
    setRefreshQuery(true);
  };

  const getList = (q: Query = { ...defaultQuery }) => {
    if ((q && checkRunAction(q)) || refreshQuery) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);
      const query = { ...q.query, ...(q.term && { term: q.term }) };
      const request: CountyRequest = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
      };

      getCounties(request)
        .then((result: CountyResponse | AxiosError) => {
          const data: CountyResponse = result as CountyResponse;
          setHasError(false);
          setLoaded(true);
          setLoading(false);
          setCounties(data);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoaded(false);
          setLoading(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
          setRefreshQuery(false);
        });
    }
  };

  const getCountyById = async (id: string) => {
    try {
      return await getCounty(id);
    } catch (e) {
      setHasError(true);
    }
  };

  const addCounty = async (county: CountyItemCreate) => {
    if (submitting) {
      return;
    }

    const created = await createCounty(county);
    setSubmitting(false);

    return created;
  };

  const editCounty = async (taxRollId: string, county: any) => {
    if (submitting) {
      return;
    }

    const updated = await updateCounty(taxRollId, county);
    setSubmitting(false);

    return updated;
  };

  const validate = (name: string, value: any) => {
    if (setError && error?.hasOwnProperty(name)) {
      error[name] = !value?.length;
      setError({ ...error });
    }
  };

  const countyInvalid = (county: CountyItem): boolean => {
    let invalidCounter = 0;

    Object.keys(error).forEach((name) => {
      if (county.hasOwnProperty(name)) {
        // @ts-ignore
        validate(name, county[name]);

        if (error[name]) {
          invalidCounter++;
        }
      }
    });

    return invalidCounter > 0;
  };

  const resetErrors = () => {
    Object.keys(error).forEach((key) => {
      error[key] = false;
      setError({ ...error });
    });
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    getList,
    getCountyById,
    addCounty,
    editCounty,
    lastQuery,
    counties,
    submitting,
    refreshList,
    validate,
    error,
    resetErrors,
    countyInvalid,
  };

  return <CountiesContext.Provider value={value}>{children}</CountiesContext.Provider>;
}

export function useCounties() {
  return React.useContext(CountiesContext);
}
