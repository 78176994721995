import { FunctionComponent, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { REACT_APP_AUTH_URL } from 'src/global/constants/environment-variables';
import Redirect from './google/Redirect';
import { useLocation } from 'react-router-dom';
import { ROUTES } from 'src/global/constants/routes';
import Loading from 'src/global/components/Loading';
const Auth: FunctionComponent = () => {
  const location = useLocation();

  // TODO: we have to use an effect because react router doesn't have a way to navigate to a external url.
  useEffect(() => {
    if (location.pathname === ROUTES.AUTH) {
      window.location.href = REACT_APP_AUTH_URL!;
    }
  }, [location.pathname]);

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Loading />} />
        <Route path="/google-callback" element={<Redirect />} />
      </Routes>
    </>
  );
};

export default Auth;
