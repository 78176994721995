import { DefaultContext } from '../models/Context';
import React from 'react';
import { NotificationType } from '../models/NotificationType';
import { getNotificationType } from '../services/notificationTypes';
import { AxiosError } from 'axios';

interface NotificationTypesContextType extends DefaultContext {
  getNotificationTypeById: (id: string) => Promise<NotificationType | AxiosError | unknown>;
}

const NotificationTypesContext = React.createContext<NotificationTypesContextType>(null!);

export function NotificationTypesProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);

  const getNotificationTypeById = async (
    id: string
  ): Promise<NotificationType | AxiosError | unknown> => {
    setLoading(true);
    setLoaded(false);
    try {
      return await getNotificationType(id);
    } catch (e) {
      setHasError(true);
      setLoading(false);
      setLoaded(false);
    }
  };

  const value = {
    loading,
    loaded,
    hasError,
    getNotificationTypeById,
  };

  return (
    <NotificationTypesContext.Provider value={value}>{children}</NotificationTypesContext.Provider>
  );
}

export function useNotificationTypes(): NotificationTypesContextType {
  return React.useContext(NotificationTypesContext);
}
