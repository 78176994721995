import { OrderDto } from '../models/Order';
import toPrettyUSD from './toPrettyUSD';

interface TotalString extends Omit<OrderDto, 'total'> {
  total: string;
  status: string;
  transactionId: string;
  disbursementDate: string;
  disbursementAmount: string;
}

export interface OrderTableDto extends Omit<TotalString, 'subtotal'> {
  total: string;
  subtotal: string;
  status: string;
  transactionId: string;
  disbursementDate: string;
  disbursementAmount: string;
  apn: string;
}

export function mapOrders(orders: OrderDto[]): OrderTableDto[] {
  return orders.map((order: OrderDto) => {
    return {
      ...order,
      createdAt: order.createdAt,
      total: toPrettyUSD(order.total),
      subtotal: toPrettyUSD(order.subtotal),
      status: order.orderTransaction?.status,
      transactionId: order.orderTransaction?.transactionId,
      scheduledDate: order.voucherInstallmentPayment?.voucherInstallmentPayment?.dateKey,
      disbursementDate: order.voucherInstallmentPayment?.disbursementDate,
      disbursementAmount: toPrettyUSD(order.voucherInstallmentPayment?.disbursementAmount),
      apn: order.voucherInstallmentPayment?.voucherInstallment?.voucher?.voucherContracts[0].apn,
    };
  });
}
