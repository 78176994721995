import { FunctionComponent } from 'react';
import Table, { formatDate, PageSize, TableProps } from './Table';
import { GridColDefWithExport } from '../../models/GridColDefWithExport';

const UserAgreementTable: FunctionComponent<TableProps> = ({
  rows,
  rowsPerPageOptions = [PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE],
  onCellClick,
  query,
  updateQuery,
  rowCount,
  loading = false,
}: TableProps) => {
  const columns: GridColDefWithExport[] = [
    {
      field: 'version',
      headerName: 'Version',
      type: 'number',
      align: 'center',
    },

    {
      field: 'publishedDate',
      headerName: 'Publish date',
      flex: 150,
      align: 'left',
      type: 'date',
      filterable: false,
      exportable: true,
      valueFormatter: formatDate,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 150,
      align: 'left',
      type: 'date',
      filterable: false,
      exportable: true,
      valueFormatter: formatDate,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated',
      flex: 150,
      align: 'left',
      type: 'date',
      filterable: false,
      exportable: true,
      valueFormatter: formatDate,
    },
  ];

  return (
    <Table
      exportVisible={false}
      rows={rows}
      columns={columns}
      rowsPerPageOptions={rowsPerPageOptions}
      query={query}
      loading={loading}
      onCellClick={onCellClick}
      rowCount={rowCount}
      updateQuery={updateQuery}
    />
  );
};

export default UserAgreementTable;
