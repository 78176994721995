import React, { createContext, useContext, useEffect, useState } from 'react';
import { DefaultContext } from '../models/Context';
import { Query } from '../models/Query';
import { useAuth } from './authContext';
import { checkQuery } from '../helpers/queryHelper';
import { AxiosError } from 'axios';
import { getOrders } from '../services/orders';
import { OrderQueryRequest, OrderQueryResponse } from '../models/Order';
import { mapOrders, OrderTableDto } from '../helpers/mapOrders';
import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';

export interface OrdersContextType extends DefaultContext {
  queryOrders: (query: Query) => void;
  queryOrdersForExport: (query: Query) => void;
  setColumnsToExport: (c: AvailableExportColumn[]) => void;
  results?: OrderRowResult;
  exportResults?: OrderTableDto[];
  query?: Query;
  columnsToExport: AvailableExportColumn[];
}

let OrdersContext = createContext<OrdersContextType>(null!);
export interface OrderRowResult extends Omit<OrderQueryResponse, 'results'> {
  rows: OrderTableDto[];
  query: OrderQueryRequest;
  totalPages: number;
}
export function OrderProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [results, setResults] = useState<OrderRowResult | undefined>();

  const [columnsToExport, setColumns] = useState<AvailableExportColumn[]>([]);

  let [exportResults, setExportResults] = useState<OrderTableDto[] | undefined>([]);

  let [query, setQuery] = useState<Query | undefined>();

  const [exportQuery, setExportQuery] = useState<Query | null>(null);

  const auth = useAuth();

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    if (query?.query?.total) {
      for (let key of Object.keys(query.query.total)) {
        // total is in cents that's why we need to convert query in dollars
        query.query.total[key] = query.query.total[key] * 100;
      }
    }

    if (query?.query?.subtotal) {
      for (let key of Object.keys(query.query.subtotal)) {
        // subtotal is in cents that's why we need to convert query in dollars
        query.query.subtotal[key] = query.query.subtotal[key] * 100;
      }
    }

    const request: OrderQueryRequest = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
      includes: [
        'orderTransaction',
        'voucherInstallmentPayment.voucherInstallment.voucher.voucherContracts',
      ],
    };

    getOrders(request)
      .then((result) => {
        const orderResult = result as OrderQueryResponse;
        const orders = mapOrders(orderResult.results);

        setResults({
          total: orderResult.total || 0,
          query: request,
          rows: orders,
          page: request.page,
          pageSize: orderResult.pageSize,
          totalPages: Math.round(orderResult.total / orderResult.pageSize) || 0,
        });
        // setLastViewedPage(request.page - 1);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  useEffect(() => {
    if (!exportQuery) {
      return;
    }

    const request: OrderQueryRequest = {
      page: 1,
      pageSize: 'ALL',
      query: exportQuery.query,
      ...(exportQuery.sortColumn && { sortColumn: exportQuery.sortColumn }),
      ...(exportQuery.sortDirection && { sortDirection: exportQuery.sortDirection }),
      includes: ['orderTransaction', 'voucherInstallmentPayment.voucherInstallment'],
    };

    getOrders(request)
      .then((result) => {
        const orders = mapOrders((result as OrderQueryResponse).results);

        setExportResults(orders);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [exportQuery]);

  const queryOrders = (q: Query) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query)
    ) {
      setQuery(q);
    }
  };

  const queryOrdersForExport = (q: Query) => {
    setExportQuery(q);
  };

  const setColumnsToExport = (c: AvailableExportColumn[]) => {
    setColumns(c);
  };

  let value = {
    queryOrders,
    queryOrdersForExport,
    setColumnsToExport,
    exportResults,
    loading,
    loaded,
    hasError,
    errorMessage,
    results,
    query,
    columnsToExport,
  };

  return <OrdersContext.Provider value={value}>{children}</OrdersContext.Provider>;
}

export function useOrders() {
  return useContext(OrdersContext);
}
