import { AxiosError } from 'axios';
import { getAxios } from './espAxios';
import {
  StagedTaxBillDTO,
  StagedTaxBillRequest,
  StagedTaxBillResponse,
} from '../models/StagedTaxBill';

const ROOT_PATH_JOBS = 'import-tax-roll/staged-tax-bills';
export function getStagedTaxBills(
  request: StagedTaxBillRequest
): Promise<StagedTaxBillResponse | AxiosError> {
  return getAxios()
    .post<StagedTaxBillResponse>(`${ROOT_PATH_JOBS}/query`, request)
    .then((resp) => {
      return resp.data;
    });
}

export function getStagedTaxBill(id: string): Promise<StagedTaxBillDTO | AxiosError> {
  return getAxios()
    .get<StagedTaxBillDTO>(`${ROOT_PATH_JOBS}/${id}`)
    .then((resp) => {
      return resp.data;
    });
}
