import { DefaultContext } from '../models/Context';
import {
  NotificationItem,
  NotificationRequest,
  NotificationResponse,
} from '../models/NotificationItem';
import { Query } from '../models/Query';
import { AxiosError } from 'axios';
import React from 'react';
import { useAuth } from './authContext';
import { PageSize } from '../components/Tables/GenericTable';
import { getNotification, getNotifications } from '../services/notifications';

interface NotificationsContextType extends DefaultContext {
  notifications?: NotificationResponse;
  getList: (query?: Query) => void;
  getNotificationById: (id: string) => Promise<NotificationItem | AxiosError | unknown>;
  refreshList: () => void;
  clearList: () => void;
}

const NotificationsContext = React.createContext<NotificationsContextType>(null!);

export function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [notifications, setNotifications] = React.useState<NotificationResponse>();
  const [lastQuery, setLastQuery] = React.useState<Query | undefined>();
  const [refreshQuery, setRefreshQuery] = React.useState<boolean>(false);
  const auth = useAuth();
  const defaultQuery: Query = {
    page: 0,
    pageSize: PageSize.MEDIUM,
    term: '',
    sortColumn: 'createdAt',
    sortDirection: 'DESC',
  };

  const checkRunAction = (query: Query): boolean => {
    const queryVal = JSON.stringify(query.query || {});
    const lastQueryVal = JSON.stringify(lastQuery?.query || {});
    const queryChanged = queryVal !== lastQueryVal;
    const termChanged = !!(query.term || (lastQuery?.term && query.term !== lastQuery?.term));
    return (
      lastQuery?.page !== query?.page ||
      lastQuery?.pageSize !== query?.pageSize ||
      lastQuery?.sortColumn !== query?.sortColumn ||
      lastQuery?.sortDirection !== query?.sortDirection ||
      termChanged ||
      queryChanged
    );
  };

  const refreshList = () => {
    setRefreshQuery(true);
  };

  const getList = (q: Query = { ...defaultQuery }) => {
    if ((q && checkRunAction(q)) || refreshQuery) {
      setLoading(true);
      setLoaded(false);
      setLastQuery(q);
      const query = { ...q.query, ...(q.term && { term: q.term }) };
      const request: NotificationRequest = {
        page: q.page + 1,
        pageSize: q.pageSize,
        query,
        ...(q.sortColumn && { sortColumn: q.sortColumn }),
        ...(q.sortDirection && { sortDirection: q.sortDirection }),
      };

      getNotifications(request)
        .then((result: NotificationResponse | AxiosError) => {
          const data: NotificationResponse = result as NotificationResponse;
          setHasError(false);
          setLoaded(true);
          setLoading(false);
          setNotifications(data);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoaded(false);
          setLoading(false);

          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        })
        .finally(() => {
          setLoading(false);
          setLoaded(true);
          setRefreshQuery(false);
        });
    }
  };

  const getNotificationById = async (id: string) => {
    try {
      return await getNotification(id);
    } catch (e) {
      setHasError(true);
    }
  };

  const clearList = () => {
    setNotifications(undefined);
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    getList,
    getNotificationById,
    lastQuery,
    notifications,
    refreshList,
    clearList,
  };

  return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
}

export function useNotifications(): NotificationsContextType {
  return React.useContext(NotificationsContext);
}
