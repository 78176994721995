import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import './Crumbs.css';

interface BreadcrumbLink {
  name: string;
  path: string;
  icon?: any;
}

const Crumbs: FunctionComponent<{ links: BreadcrumbLink[] }> = ({
  links,
}: {
  links: BreadcrumbLink[];
}) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" color="disabled" />}
      aria-label="breadcrumb"
    >
      {links.map((link: BreadcrumbLink, index: number) => (
        // while mui has a Link component, it causes the page to reload
        // however, in order to accomplish the styling we want, we need custom CSS
        <Link
          key={index}
          className={`crumb-links ${
            links.length === 1 ? 'current-page ' : index === links.length - 1 ? 'current' : ''
          }`}
          to={link.path}
        >
          {link.icon ? <link.icon /> : ''} {link.name}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Crumbs;
