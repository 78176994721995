/**
 * Takes an unformatted phone number and returns it formatted.  If what is passed in is not a 10-digit phone number, the original value is returned.
 *
 * Examples:
 *
 * IN: 1234567890
 *
 * OUT: "(123)-456-7890"
 *
 * -----------------------------
 *
 * IN: '1234567890'
 *
 * OUT: "(123)-456-7890"
 *
 * -----------------------------
 *
 * IN: 'abcabcabcd'
 *
 * OUT: "abcabcabcd"
 *
 * -----------------------------
 *
 * IN: 123456789
 *
 * OUT: "123456789"
 *
 */
export default function formatPhoneNumber(phoneNumber: number | string) {
  // Convert the input to a string
  const number = String(phoneNumber);

  // Check if the phone number is 10 digits
  if (!/^\d{10}$/.test(number)) {
    // If not, return the original string
    return number;
  }

  // If it is 10 digits, format it as (XXX)-XXX-XXXX
  return number.replace(/(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3');
}
