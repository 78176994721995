import React, { FunctionComponent } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Customer } from 'src/global/models/Customer';
import { Query } from 'src/global/models/Query';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export interface Column {
  key: string;
  label?: string;
  align?: 'center' | 'left';
  format?: (value: any) => string;
}

export interface Order {
  status: string;
  receiptId?: string;
  date: string;
  amount: number;
  apn: string;
  address: string;
  city: string;
  county: string;
  taxYear: string;
  installmentNumber?: 1 | 2;
  [key: string]: any;
}

export enum PageSize {
  SMALL = 10,
  MEDIUM = 25,
  LARGE = 50,
  XL = 100,
}

export interface GenericTableProps {
  data: Order[] | Partial<Customer>[];
  count: number;
  columns: Column[];
  getCellValue: (col: Column, value: string | number, showIcon?: boolean) => any;
  cellClicked: (order: Order | Partial<Customer>, colName: string) => void;
  query: Query;
  updateQuery: (query: Query) => void;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { page, onPageChange } = props;

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleBackButtonClick} aria-label="previous page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}

const GenericTable: FunctionComponent<GenericTableProps> = ({
  data,
  count,
  columns,
  getCellValue,
  cellClicked,
  query,
  updateQuery,
}: GenericTableProps) => {
  function handleTableSort(
    event: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
    property: string
  ): void {
    if (event) {
      event.preventDefault();
    }
    updateQuery({ ...query, sortColumn: property });
  }
  const handleChangePage = (_: unknown, newPage: number) => {
    let page = newPage + 1;
    if (page > Math.ceil(count / query.pageSize)) {
      page = Math.ceil(count / query.pageSize);
    } else if (page < 1) {
      page = 1;
    }
    updateQuery({ ...query, page });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateQuery({ ...query, pageSize: +event.target.value });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} align={column.align}>
                  <div
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                      handleTableSort(event, column.key)
                    }
                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                      handleTableSort(event, column.key)
                    }
                    role="button"
                    tabIndex={0}
                  >
                    {column.label}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Order | Partial<Customer>, rowIndex: number) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                  {columns.map((column) => {
                    const value = row[column.key];
                    return (
                      <TableCell
                        key={column.key}
                        align={column.align}
                        onClick={() => cellClicked(row, column.key)}
                      >
                        {getCellValue(column, value, row.status === 'failed')}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[PageSize.SMALL, PageSize.MEDIUM, PageSize.LARGE, PageSize.XL]}
        component="div"
        count={count}
        rowsPerPage={query.pageSize}
        page={query.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
  );
};

export default GenericTable;
