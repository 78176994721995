import { getAxios } from './espAxios';
import { AxiosError, AxiosResponse } from 'axios';
import { Activity, ActivityLogRequest, ActivityLogResponse } from '../models/ActivityLog';

export function getActivityLogs(
  request: ActivityLogRequest
): Promise<ActivityLogResponse | AxiosError> {
  return getAxios()
    .post<ActivityLogResponse | AxiosError>('/activity-logs/query', request)
    .then((resp: AxiosResponse<AxiosError<any, any> | ActivityLogResponse, any>) => resp.data);
}

export function getActivityById(id: string): Promise<Activity | AxiosError> {
  return getAxios()
    .get<Activity | AxiosError>(`/activity-logs/${id}`)
    .then((resp: AxiosResponse<AxiosError | Activity>) => resp.data);
}
