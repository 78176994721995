import { SvgIcon, SvgIconProps } from '@mui/material';
import { FunctionComponent } from 'react';

const RescheduleIcon: FunctionComponent<SvgIconProps> = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#09A098"
        fillRule="nonzero"
        d="M19.0658892,4.94143445 C22.9661413,8.84168659 22.9661413,15.1833179 19.0658892,19.0835701 C15.1656371,22.9838222 8.82400572,22.9838222 4.92375358,19.0835701 C1.76215729,15.9219738 1.16339303,11.156162 3.12746081,7.38693158 L1.38821968,5.64854123 L6.33796715,4.94143445 L5.63086036,9.89118192 L4.48735879,8.7469883 C3.19377484,11.7415882 3.76598492,15.3653305 6.20398902,17.8033346 C7.47897041,19.078316 9.09680394,19.8522058 10.7596271,20.1001822 L11.071861,20.1405086 L12.8880089,20.1405086 C14.6594975,19.9469847 16.3863267,19.1728888 17.7558809,17.8033346 C20.9564694,14.602746 20.941583,9.40737201 17.7558809,6.22166988 C16.4808995,4.94668849 14.8630659,4.17279869 13.2002428,3.92482235 L12.8880089,3.88449589 L11.0867474,3.86960943 C9.93755075,4.00368238 8.81299715,4.37186449 7.79235255,4.98208238 L6.47987516,3.66917986 C10.3609374,1.10129248 15.6498321,1.52537734 19.0658892,4.94143445 Z"
      />
      <path
        fill="#09A098"
        d="M12,6 C12.5522847,6 13,6.44771525 13,7 L13,11.32 L17.072196,12.8021209 C17.5911739,12.9910134 17.858761,13.5648557 17.6698685,14.0838336 C17.480976,14.6028115 16.9071336,14.8703986 16.3881557,14.6815061 L11.6896926,12.9714054 C11.5392067,12.916633 11.4098578,12.8294939 11.306646,12.720711 C11.1178552,12.5391907 11,12.2833586 11,12 L11,7 C11,6.44771525 11.4477153,6 12,6 Z"
      />
    </g>
  </SvgIcon>
);

export default RescheduleIcon;
