import { createContext, ReactNode, useContext, useState } from 'react';
import { AxiosError } from 'axios';
import { getAddressByApn } from '../services/addresses';
import { Address } from '../models/Address';
import { useAuth } from './authContext';
import { DefaultContext } from '../models/Context';

interface AddressContextType extends DefaultContext {
  queryAddressByApn: (taxRollId: string, apn: string) => Promise<void>;
  address?: Address;
}

let AddressContext = createContext<AddressContextType>(null!);

export function AddressProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [address, setAddress] = useState<Address | undefined>();

  const auth = useAuth();

  const queryAddressByApn = async (taxRollId: string, apn: string) => {
    if (address?.apn !== apn) {
      setLoading(true);
      getAddressByApn(taxRollId, apn)
        .then((result) => {
          const data: Address = result as Address;
          setAddress(data);
          setLoaded(true);
          setLoading(false);
        })
        .catch((err: AxiosError) => {
          setHasError(true);
          setErrorMessage(err.message);
          setLoading(false);
          setAddress(undefined);
          if (err.response?.status === 401) {
            auth.setRequiresAuth();
          }
        });
    }
  };

  const value = {
    loading,
    loaded,
    hasError,
    errorMessage,
    queryAddressByApn,
    address,
  };

  return <AddressContext.Provider value={value}>{children}</AddressContext.Provider>;
}

export function useAddress() {
  return useContext(AddressContext);
}
