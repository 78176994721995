import React, { FunctionComponent, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { TaxRollDto } from '../../../global/models/TaxRolls';
import { useTaxRoll } from '../../../global/contexts/taxRollContext';
import toPrettyUSD from '../../../global/helpers/toPrettyUSD';
import toPrettyDate from '../../../global/helpers/toPrettyDate';
import formatBoolean from '../../../global/helpers/formatBoolean';
import Tabs from '../../../global/components/Tabs/Tabs';
import PaymentContractList from '../../paymentContracts/PaymentContractList/PaymentContractList';
import { Query } from '../../../global/models/Query';
import { getUrlQuery } from '../../../global/helpers/queryHelper';
import TaxRollList from '../TaxRollList/TaxRollList';
import { usePaymentContracts } from '../../../global/contexts/paymentContractsContext';
import convertBillType from 'src/global/helpers/convertBillType';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  h1: {
    color: 'rgba(0,0,0,0.87)',
    fontFamily: 'Roboto',
    fontSize: '34px',
    letterSpacing: 0,
    lineHeight: '36px',
  },
  h2: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '24px',
    letterSpacing: '0.18px',
    lineHeight: '24px',
    fontFamily: 'Roboto',
  },
  h3: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '18px',
    letterSpacing: '0.18px',
    lineHeight: '18px',
    fontFamily: 'Roboto',
  },
  paper: {
    padding: '1rem',
    marginBottom: '1rem',
  },
  wrapper: {
    width: '100%',
    marginBottom: '24px',
  },
};

interface TaxRollDetailsProps {
  selectedTaxRoll: TaxRollDto | null | undefined;
  setSelectedTaxRoll: (selectedTaxRoll: TaxRollDto | null | undefined) => void;
}

const TaxRollDetails: FunctionComponent<TaxRollDetailsProps> = ({
  selectedTaxRoll,
  setSelectedTaxRoll,
}: TaxRollDetailsProps) => {
  const taxRollContext = useTaxRoll();
  const paymentContractsContext = usePaymentContracts();
  const params = useParams();
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [query, setQuery] = useState<Query>(() => getUrlQuery(undefined, undefined, '', {}));
  const handleTabChange = useCallback((_: SyntheticEvent, tabIndex: number) => {
    setSelectedTabIndex(tabIndex);
  }, []);

  async function getTaxRoll(id: string) {
    const tax = (await taxRollContext.getTaxRollById(id)) as TaxRollDto;
    const newQuery = {
      ...query,
      ...{
        query: {
          apn: [tax.apn],
          billNumber: [tax.billNumber],
          taxRollId: [tax.taxRollId],
          taxYear: {
            eq: tax.taxYear,
          },
        },
      },
    };

    setQuery(newQuery);
    setTimeout(() => {
      setSelectedTaxRoll(tax as TaxRollDto);
    }, 200);
  }

  useEffect(() => {
    if (params.id && !selectedTaxRoll && !taxRollContext.taxLoading) {
      getTaxRoll(params.id);
    }
  }, [params]);

  useEffect(() => {
    taxRollContext.refreshList(false);
    paymentContractsContext.refreshList(false);
    return () => {
      taxRollContext.refreshList(true);
      paymentContractsContext.refreshList(true);
    };
  }, []);

  return (
    <div>
      <h1 style={styles.h1}>Tax Roll Details</h1>
      {selectedTaxRoll && taxRollContext.taxLoaded && (
        <>
          <h3 style={styles.h3}>Parcel details</h3>
          <Paper className="wrapper" style={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  APN
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.apn}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Bill Number
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.billNumber}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Tax Roll Id
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.taxRollId}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <h3 style={styles.h3}>Bill details</h3>
          <Paper className="wrapper" style={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Amount
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {toPrettyUSD(selectedTaxRoll.amount || 0)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Amount Paid
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {toPrettyUSD(selectedTaxRoll.amountPaid || 0)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Tax Year
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.taxYear}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Due Date
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {toPrettyDate(
                    {
                      year: selectedTaxRoll.dueYear,
                      month: selectedTaxRoll.dueMonth,
                      day: selectedTaxRoll.dueDay,
                    },
                    'table'
                  )}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Bill Type
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {convertBillType(selectedTaxRoll.billType)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Installment Number
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.installmentNumber}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <h3 style={styles.h3}>Bill Status</h3>
          <Paper className="wrapper" style={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Paid
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {formatBoolean(selectedTaxRoll.isPaid)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Has Penalty
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {formatBoolean(selectedTaxRoll.hasPenalty)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Primary
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {formatBoolean(selectedTaxRoll.isPrimary)}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Active
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {formatBoolean(selectedTaxRoll.isActive)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <h3 style={styles.h3}>Other details</h3>
          <Paper className="wrapper" style={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Mailing Address
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.mailingAddress || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Net Taxable Amount
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {toPrettyUSD(selectedTaxRoll.netTaxableValue || 0)}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <h3 style={styles.h3}>Meta Data</h3>
          <Paper className="wrapper" style={styles.paper}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  JobId
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.jobId || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Created At
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.createdAt || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Updated At
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.updatedAt || '-'}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography gutterBottom variant="subtitle1" component="div">
                  Consumer Version
                </Typography>

                <Typography variant="body2" gutterBottom>
                  {selectedTaxRoll.consumerVersion || '-'}
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <div style={styles.wrapper}>
            <div style={{ ...styles.row, marginTop: '24px' }}>
              <Tabs
                tabs={['Payment Contracts', 'Tax Bill Installments']}
                onChange={handleTabChange}
                selectedTabIndex={selectedTabIndex}
              />
            </div>
            <div>
              {selectedTabIndex === 0 && (
                <PaymentContractList queryParams={query} showSearch={false} />
              )}
              {selectedTabIndex === 1 && <TaxRollList queryParams={query} showSearch={false} />}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TaxRollDetails;
