import React, { FunctionComponent } from 'react';
import { Tabs as TabsBox } from '@mui/material';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabsProps {
  /**
   * Array of strings for the tabs
   */
  tabs: string[];
  /**
   * Index of the currently selected tab
   */
  selectedTabIndex: number;
  /**
   * Function to update the currently selected tab
   */
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  children?: React.ReactNode;
}

const Tabs: FunctionComponent<TabsProps> = ({
  children,
  tabs,
  selectedTabIndex,
  onChange,
}: TabsProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '24px' }}>
        <TabsBox
          value={selectedTabIndex}
          onChange={onChange}
          textColor="secondary"
          indicatorColor="secondary"
        >
          {tabs.map((tab: string, index: number) => (
            <Tab
              label={tab}
              value={index}
              key={index}
              sx={{ color: selectedTabIndex === index ? 'inherit' : '#BB86FC' }}
            />
          ))}
        </TabsBox>
      </Box>
      {children}
    </Box>
  );
};

export default Tabs;
