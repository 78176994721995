import { AxiosError } from 'axios';
import { Profile } from '../models/Profile';
import { getAxios } from './espAxios';

const BASE_SERVICE_PATH = '';

export function getProfile(): Promise<Profile | AxiosError> {
  return getAxios()
    .get<Profile>(`${BASE_SERVICE_PATH}/profile`)
    .then((resp) => resp.data);
}
