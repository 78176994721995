import { useState, useEffect, FunctionComponent } from 'react';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import RescheduleIcon from '../../../../../global/components/icons/RescheduleIcon';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import Dialog from '@mui/material/Dialog';
import { CircularProgress } from '@mui/material';
import {
  ContractDetailField,
  ContractDetailFieldProps,
  ContractDetailsProps,
} from '../../../../customers/customer/components/models/ContractDetailProps';
import formatPhoneNumber from 'src/global/helpers/formatPhoneNumber';
import { Link } from 'react-router-dom';

const styles = {
  wrapper: {
    width: 'calc(100% - 32px)',
    height: 'auto',
    padding: '10px 16px 17px 16px',
    fontFamily: 'Roboto',
    minHeight: '75px',
  },
  header: {
    color: '#BB86FC',
    fontSize: '10px',
    lineHeight: '16px',
    letterSpacing: '1.5px',
    minHeight: '16px',
    margin: '0px',
    fontFamily: 'Roboto',
  },
  secondaryHeader: {
    color: 'rgba(0,0,0,0.87)',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    minHeight: '24px',
    margin: '0px',
    fontFamily: 'Roboto',
  },
  detailText: {
    color: 'rgba(0,0,0,0.6)',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    minHeight: '20px',
    margin: '0px',
    fontFamily: 'Roboto',
  },
  rescheduleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  rescheduleText: {
    color: '#09A098',
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '1.25px',
    minHeight: '16px',
    margin: '0px',
  },
  paymentContractWrapper: {
    padding: '20px 56px 0px 24px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    columnGap: '32px',
  },
  cancelContractButton: {
    backgroundColor: 'rgba(0,0,0,0.87) !important',
    color: '#fff !important',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    letterSpacing: '1.25px !important',
    minHeight: '16px !important',
    margin: '0px !important',
    borderTopLeftRadius: '0px !important',
    borderTopRightRadius: '0px !important',
    fontFamily: 'Roboto',
    width: '100%',

    '&:disabled ': {
      backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    },
  },
  dialogContainer: {
    padding: '12px 14px 18px 14px',
    minWidth: '375px',
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  selectMessage: {
    marginTop: '32px',
    color: 'rgba(0,0,0,0.6)',
    fontFamily: 'Roboto',
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
  },
  select: {
    width: '100%',
  },
  confirmRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '40px',
  },
  saveButton: {
    color: '#2BB1A8',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
  cancelButton: {
    marginLeft: '16px',
    color: '#676667',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '1.25px',
    lineHeight: '16px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

function convertNumToOrdinal(day: number): string {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return day + (s[(v - 20) % 10] || s[v] || s[0]);
}

const PaymentContractField: FunctionComponent<ContractDetailFieldProps> = (
  props: ContractDetailFieldProps
) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<number>(1);

  useEffect((): void => {
    if (props.secondaryHeader.includes('of the month')) {
      setSelectedDate(parseInt(props.secondaryHeader.split(' ')[1]));
    }
  }, [props.secondaryHeader]);

  function handleChange(event: SelectChangeEvent) {
    setSelectedDate(parseInt(event.target.value));
  }

  return (
    <div>
      <div style={styles.wrapper}>
        {props.isLoading ? (
          <div style={styles.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <p style={styles.header}>{props.header}</p>
            <p style={styles.secondaryHeader}>
              {props.customerId ? (
                <Link style={{ color: 'inherit' }} to={`/customers/${props.customerId}`}>
                  {props.secondaryHeader}
                </Link>
              ) : (
                props.secondaryHeader
              )}
            </p>
            {!!props.apn && (
              <p style={styles.detailText}>
                <strong>APN: </strong>
                {props.apn}
              </p>
            )}
            {!!props.localeEmailOrInstallment && (
              <p style={styles.detailText}>
                {props.customerId ? (
                  <a
                    style={{ color: 'inherit' }}
                    href={`https://app.hubspot.com/contacts/6835363/objects/0-1/views/all/list?query=${props.localeEmailOrInstallment}`}
                    target="_blank"
                  >
                    {props.localeEmailOrInstallment}
                  </a>
                ) : (
                  props.localeEmailOrInstallment
                )}
              </p>
            )}
            {!!props.countyOrPhoneNumber && (
              <p style={styles.detailText}>{formatPhoneNumber(props.countyOrPhoneNumber)}</p>
            )}
            {!!props.taxYear && (
              <p style={styles.detailText}>
                <strong>Tax Year: </strong>
                {props.taxYear}
              </p>
            )}
            {!!props.billType && (
              <p style={styles.detailText}>
                <strong>Bill Type: </strong>
                {props.billType}
              </p>
            )}
            {!!(props.header === 'Scheduled Payment Date') && (
              <Button sx={styles.rescheduleWrapper} onClick={() => setOpen(true)}>
                <RescheduleIcon style={{ marginRight: '4px' }} color="success" />
                <p style={styles.rescheduleText}>RESCHEDULE</p>
              </Button>
            )}
          </>
        )}
      </div>
      {!!(props.index || props.index === 0) && props.index < props.showDividerBeforeIndex && (
        <Divider />
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Paper elevation={1}>
          <div style={styles.dialogContainer}>
            <div style={styles.row}>
              <MoreTimeIcon />
            </div>
            <div style={styles.row}>Reschedule Payment Date</div>
            <p style={styles.selectMessage}>Select the recurring day of the month</p>
            <Select
              value={selectedDate.toString()}
              onChange={handleChange}
              variant="standard"
              sx={styles.select}
            >
              {[...Array(28)].map((_, index: number) => (
                <MenuItem key={index} value={index + 1}>
                  {convertNumToOrdinal(index + 1)}
                </MenuItem>
              ))}
            </Select>
            <div style={styles.confirmRow}>
              <Button
                sx={styles.saveButton}
                // TODO: remove once backend is ready
                disabled={true}
                onClick={() => {
                  if (
                    selectedDate !== parseInt(props.secondaryHeader.split(' ')[1]) &&
                    props.updatePaymentDate
                  ) {
                    props.updatePaymentDate(selectedDate);
                    setOpen(false);
                  }
                }}
              >
                Save
              </Button>
              <Button sx={styles.cancelButton} onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Paper>
      </Dialog>
    </div>
  );
};

const ContractDetails: FunctionComponent<ContractDetailsProps> = ({
  contractData,
  onCancel,
  updatePaymentDate,
}: ContractDetailsProps) => {
  if (!contractData?.length) {
    return (
      <Paper elevation={1}>
        <div style={{ ...styles.loading, height: '300px' }}>
          <CircularProgress />
        </div>
      </Paper>
    );
  }
  return (
    <Paper elevation={1}>
      <div style={styles.paymentContractWrapper}>
        {contractData?.map((contractField: ContractDetailField, index: number) => {
          const dayOfMonth = contractField.dayOfMonth
            ? `The ${convertNumToOrdinal(contractField.dayOfMonth)} of the month`
            : '';
          return (
            <PaymentContractField
              key={contractField.type}
              index={index}
              header={contractField.type}
              secondaryHeader={
                contractField.street ||
                contractField.name ||
                contractField.billNumber ||
                dayOfMonth ||
                ''
              }
              customerId={contractField.customerId}
              apn={contractField.apn}
              localeEmailOrInstallment={
                contractField.locale || contractField.email || contractField.installment || ''
              }
              countyOrPhoneNumber={contractField.county || contractField.phoneNumber || ''}
              taxYear={contractField.taxYear}
              billType={contractField.billType}
              updatePaymentDate={updatePaymentDate}
              showDividerBeforeIndex={2}
              isLoading={contractField.isLoading}
            />
          );
        })}
      </div>
      <Button
        sx={styles.cancelContractButton}
        onClick={onCancel ? onCancel : undefined}
        disabled={!onCancel}
      >
        <p>{onCancel ? 'CANCEL THIS CONTRACT' : 'CONTRACT CANCELED'}</p>
      </Button>
    </Paper>
  );
};

export { ContractDetails, PaymentContractField };
