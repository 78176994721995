import axios from 'axios';
import axiosRetry from 'axios-retry';

import {
  REACT_APP_API_URL,
  REACT_APP_SERVER_ENVIRONMENT,
} from '../constants/environment-variables';

const retryConfig = {
  retries: 3,
  retryCondition: (e: any) => e.response?.status === 404,
  retryDelay: () => 1000,
};

/**
 * Axios instance set up with a root request URL, and support for setting and sending cookies
 */
const espAxios = axios.create({
  baseURL: REACT_APP_API_URL,
  withCredentials: true,
});

axiosRetry(espAxios, retryConfig);

export function getAxios() {
  if (REACT_APP_SERVER_ENVIRONMENT === 'local') {
    const client = axios.create({
      baseURL: REACT_APP_API_URL,
      withCredentials: true,
    });
    axiosRetry(client, retryConfig);

    return client;
  }

  return espAxios;
}

export default espAxios;
