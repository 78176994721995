import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { ROUTES } from '../constants/routes';
import GroupIcon from '@mui/icons-material/Group';
import PaymentsIcon from '@mui/icons-material/Payments';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InventoryIcon from '@mui/icons-material/Inventory';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import GradingIcon from '@mui/icons-material/Grading';
import HandshakeIcon from '@mui/icons-material/Handshake';
import EmailIcon from '@mui/icons-material/Email';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

export const mainTabs = [
  {
    name: 'Customers',
    icon: GroupIcon,
    routes: `${ROUTES.CUSTOMERS}/`,
  },
  {
    name: 'Payment Contracts',
    icon: PaymentsIcon,
    routes: `${ROUTES.PAYMENT_CONTRACTS}/`,
  },
  {
    name: 'Activities',
    icon: NotificationsIcon,
    routes: `${ROUTES.ACTIVITIES}/`,
  },
  {
    name: 'Dashboard',
    icon: DashboardIcon,
    routes: `${ROUTES.DASHBOARD}/`,
  },
  {
    name: 'Counties',
    icon: LocationOnIcon,
    routes: `${ROUTES.COUNTIES}/`,
  },
  {
    name: 'Products',
    icon: InventoryIcon,
    routes: `${ROUTES.PRODUCTS}/`,
  },
  {
    name: 'Cortacs',
    icon: FileDownloadIcon,
    routes: `${ROUTES.CORTACS}/`,
  },
  {
    name: 'Orders',
    icon: GradingIcon,
    routes: `${ROUTES.ORDERS}/`,
  },
  {
    name: 'Notifications',
    icon: EmailIcon,
    routes: `${ROUTES.NOTIFICATIONS}/`,
  },
  {
    name: 'Tax Rolls',
    icon: RequestQuoteIcon,
    routes: `${ROUTES.TAX_ROLLS}/`,
  },
  {
    name: 'User agreements',
    icon: HandshakeIcon,
    routes: `${ROUTES.USER_AGREEMENTS}/`,
  },
  {
    name: 'Tax Roll Jobs',
    icon: AccountBalanceIcon,
    routes: `${ROUTES.TAXROLL_JOBS}/`,
  },
  {
    name: 'Staged Tax Bills',
    icon: ReceiptIcon,
    routes: `${ROUTES.STAGED_TAXBILLS}/`,
  },
  {
    name: 'Failed Payments',
    icon: ProductionQuantityLimitsIcon,
    routes: `${ROUTES.RETRY_PAYMENTS}/`,
  },
];
export function selectTab(selectedTabIndex: number, navigate: NavigateFunction) {
  switch (selectedTabIndex) {
    case 0:
      navigate(`${ROUTES.CUSTOMERS}/`);
      break;
    case 1:
      navigate(`${ROUTES.PAYMENT_CONTRACTS}/`);
      break;
    case 2:
      navigate(`${ROUTES.ACTIVITIES}/`);
      break;
    case 3:
      navigate(`${ROUTES.DASHBOARD}/`);
      break;
    case 4:
      navigate(`${ROUTES.COUNTIES}/`);
      break;
    case 5:
      navigate(`${ROUTES.PRODUCTS}/`);
      break;
    case 6:
      navigate(`${ROUTES.CORTACS}/`);
      break;
    case 7:
      navigate(`${ROUTES.ORDERS}/`);
      break;
    case 8:
      navigate(`${ROUTES.NOTIFICATIONS}/`);
      break;
    case 9:
      navigate(`${ROUTES.TAX_ROLLS}/`);
      break;
    case 10:
      navigate(`${ROUTES.USER_AGREEMENTS}`);
      break;
    case 11:
      navigate(`${ROUTES.TAXROLL_JOBS}`);
      break;
    case 12:
      navigate(`${ROUTES.STAGED_TAXBILLS}`);
      break;
    case 13:
      navigate(`${ROUTES.RETRY_PAYMENTS}`);
      break;
  }
}
