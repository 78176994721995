import React, { useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import { CustomerDto, CustomerQueryRequest, CustomerQueryResponse } from '../models/Customer';
import { Query } from '../models/Query';
import {
  getCustomers,
  getCustomer,
  getContractsWithAddressesByCustomerId,
} from '../services/customers';
import { useAuth } from './authContext';
import { PaymentContractWithAddress } from '../models/PaymentContract';
import { AvailableExportColumn } from '../components/Tables/ExportColumnChooser';
import { DefaultContext } from '../models/Context';
import { checkQuery } from '../helpers/queryHelper';

interface CustomersContextType extends DefaultContext {
  queryCustomers: (query: Query) => void;
  queryCustomersForExport: (query: Query) => void;
  results?: CustomerRowResult;
  exportResults?: CustomerDto[];
  query?: Query;
  getCustomerById: (id: string) => Promise<CustomerDto | undefined>;
  customerContracts?: PaymentContractWithAddress[];
  getCustomerContracts: (id: string) => Promise<void>;
  clearCustomerContracts: () => void;
  setColumnsToExport: (c: AvailableExportColumn[]) => void;
  columnsToExport: AvailableExportColumn[];
}

let CustomersContext = React.createContext<CustomersContextType>(null!);

export interface CustomerRowResult extends Omit<CustomerQueryResponse, 'results'> {
  rows: CustomerDto[];
  query: CustomerQueryRequest;
  totalPages: number;
}

export function CustomersProvider({ children }: { children: React.ReactNode }) {
  let [loading, setLoading] = useState<boolean>(false);
  let [loaded, setLoaded] = useState<boolean>(false);
  let [hasError, setHasError] = useState<boolean>(false);
  let [errorMessage, setErrorMessage] = useState<string>('');
  let [results, setResults] = useState<CustomerRowResult | undefined>();
  let [exportResults, setExportResults] = useState<CustomerDto[] | undefined>([]);

  let [query, setQuery] = useState<Query | undefined>();
  let [lastViewedPage, setLastViewedPage] = useState<number>(0);
  let [customerContracts, setCustomerContracts] =
    useState<PaymentContractWithAddress[] | undefined>();
  const [columnsToExport, setColumns] = useState<AvailableExportColumn[]>([]);
  const [exportQuery, setExportQuery] = useState<Query | null>(null);

  const auth = useAuth();

  useEffect(() => {
    if (!query) {
      return;
    }
    setLoading(true);
    setLoaded(false);

    if (results) {
      setResults({
        ...results,
        rows: [],
      });
    }

    if (query.term) {
      if (!query.query) {
        query.query = {};
      }
      Object.assign(query.query, { term: query.term });
    }

    const request: CustomerQueryRequest = {
      page: query.page + 1,
      pageSize: query.pageSize,
      query: query.query,
      ...(query.sortColumn && { sortColumn: query.sortColumn }),
      ...(query.sortDirection && { sortDirection: query.sortDirection }),
    };

    getCustomers(request)
      .then((result) => {
        const customerResult = result as CustomerQueryResponse;
        const customers = customerResult.results;

        setResults({
          total: customerResult.total || 0,
          query: request,
          rows: customers,
          page: request.page,
          pageSize: request.pageSize,
          totalPages: Math.round(customerResult.total / customerResult.pageSize) || 0,
        });
        setLastViewedPage(request.page - 1);
      })
      .catch((err: AxiosError) => {
        setHasError(true);
        setLoading(false);
        setErrorMessage(err.message);

        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }, [query]);

  useEffect(() => {
    if (!exportQuery) {
      return;
    }

    const request: CustomerQueryRequest = {
      page: 1,
      pageSize: exportQuery.pageSize,
      query: exportQuery.query,
      ...(exportQuery.sortColumn && { sortColumn: exportQuery.sortColumn }),
      ...(exportQuery.sortDirection && { sortDirection: exportQuery.sortDirection }),
    };

    getCustomers(request)
      .then((result) => {
        const customerResult = result as CustomerQueryResponse;
        const customers = customerResult.results;

        setExportResults(customers);
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          auth.setRequiresAuth();
        }
      });
  }, [exportQuery]);

  const queryCustomers = (q: CustomerQueryRequest) => {
    const termChanged = q.term || (query?.term && q.term !== query?.term);

    if (q.term) {
      if (!q.query) {
        q.query = {
          term: q.term,
        };
      }
    } else {
      if (q.query) {
        delete q.query.term;
      }
    }

    if (
      q.page !== query?.page ||
      q.pageSize !== query?.pageSize ||
      q.sortColumn !== query?.sortColumn ||
      q.sortDirection !== query?.sortDirection ||
      termChanged ||
      checkQuery(q, query)
    ) {
      // prevent the table from showing the incorrect data page
      // after navigating to a different screen
      if (!q.page || q.page !== lastViewedPage || termChanged) {
        setQuery(q);
      }
    }
  };

  const getCustomerById = async (id: string): Promise<CustomerDto | undefined> => {
    try {
      return (await getCustomer(id)) as CustomerDto;
    } catch (e) {
      setHasError(true);
    }
  };

  const getCustomerContracts = async (id: string): Promise<void> => {
    if (
      customerContracts &&
      customerContracts.length > 0 &&
      customerContracts[0].customerId === id
    ) {
      return;
    } else {
      try {
        const data = await getContractsWithAddressesByCustomerId(id);

        (data as PaymentContractWithAddress[]).forEach((row: PaymentContractWithAddress) => {
          row.amount = row.amount / 100;
          row.amountPaid = row.amountPaid / 100;
          row.disbursmentAmount = row.disbursmentAmount ? row.disbursmentAmount / 100 : 0;
        });

        setCustomerContracts(data as PaymentContractWithAddress[]);
      } catch (e) {
        setHasError(true);
      }
    }
  };

  const clearCustomerContracts = () => {
    setCustomerContracts(undefined);
  };

  const queryCustomersForExport = (q: Query) => {
    setExportQuery(q);
  };
  const setColumnsToExport = (c: AvailableExportColumn[]) => {
    setColumns(c);
  };

  let value = {
    queryCustomers,
    queryCustomersForExport,
    loading,
    loaded,
    hasError,
    errorMessage,
    results,
    exportResults,
    query,
    getCustomerById,
    customerContracts,
    getCustomerContracts,
    clearCustomerContracts,
    setColumnsToExport,
    columnsToExport,
  };

  return <CustomersContext.Provider value={value}>{children}</CustomersContext.Provider>;
}

export function useCustomers() {
  return React.useContext(CustomersContext);
}
